import styled from "styled-components";

export const BarChartContainer = styled.div`
  width: 100%;
  height: 100%;
  background:rgba(254, 254, 254, 0.60);
  border: none;
  box-sizing: border-box;
  border-radius: 12px;
  padding:16px;
  ${'' /* width:50%; */}

  .heading{
    color:  #184A61;
font-family: 'Lato';
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
  }

  .total-row{

  }

  .total{
    color:  #184A61;
font-family: 'Lato';
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 68px; 
  }

  .total-text{
    color:  #DCDCDC;
font-family: 'Rubik-Regular';
font-size: 40px;
font-style: normal;
${'' /* font-weight: 700; */}
line-height: 68px;
margin-left: 10px;
  }
`;

export const Border = styled.div`
 width: 100%;
 margin: 20px 0px;
 border-bottom: 1px solid #EFEFEF;
`;