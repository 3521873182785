import styled, { css } from 'styled-components';
//const BoldFont = 'Rubik-Medium';
// const MediumFont = 'Rubik-Regular';
//const RegularFont = 'Rubik-Light';

const SidebarGrid = styled.div`
  width: 200px;
  float: left;
  ${'' /* position: absolute; */}
  position: ${({manager}) => manager ? "fixed" : 'absolute'};
  top: ${({manager}) => manager ? "70px" : '0'};
  ${'' /* top: 0; */}
  bottom: 0;
  left: 0;
  display: inline-block;
  // background-color: ${(props) => props.showCompany ? '#3d4d67': 'white'};
  text-align: center;
  
  @media(max-width: 950px) {
    display: block;
    width: 100%;
    position: relative;
  }
  @media(max-width: 700px){
    background: #fff;
    width: auto;
  }
  ${({hideContent}) => hideContent === 1 && css`
    width: 80px;
  `}
`;

const HamBurgerContainer = styled.div`
  padding: 3px 10px;
  float: left;
  font-size: 23px;
  display: inline-block;
  position: relative;
  margin: 10px;
  margin-left: 20px;
  @media(max-width: 700px){
    background: #fff;
  }
  @media (min-width: 951px) {
    display: none;
  }
  
  > i {
    cursor: pointer;
    color: ${(props) => props.showCompany ? 'rgb(62, 127, 159)': '#3e7f9f'};
  }
`;
const MainSideBarLinks = styled.div`
  float: left;
  width: 100%;
  
  @media (max-width: 950px) {
    display: none;
  }
  
  ${({ smallDevices }) => smallDevices && css`
    display: none;
    
    @media (max-width: 950px) {
      display: block;
    }
    @media(max-width: 700px){
      background: #fff;
    }
  `}
`;
const Navigation = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  // background-color: white;
  // border-top: ${(commonDashboard) => commonDashboard ? '1px solid #d2d2d2' : '0px'};
  > li:first-child {
    margin-top: ${(props) => props.showCompany ? '0px' : '25px'};
    border-top: ${(props) => props.showCompany ? '2px solid #d2d2d2' : '0px'};
  }
  @media(max-width: 700px){
    background: #fff;
  }
  > li {
    color: white;
    height: 50px;
    display: flex;
    justify-content: center; 
    width: 100%;
    float: left;
    position: relative;
    margin: 5px 0;
  }
  
  @media(max-width: 950px) {
    background-color: white;
    padding: 0;
  }
`;
const StyledLink = styled.a`

.styledmain{
height:0px;
display: flex;
font-family: "Rubik-Medium";
font-size: 16px;
line-height: 22px;
align-items: center;
justify-content: center;
background: ${({commonDashboard, color}) => commonDashboard ? color : '#0D4270'};
position: relative;
z-index:100;
display: flex;
width: ${({width}) => width ? width : '140px'};
left: ${({left}) => left ? left : '80px'};
bottom: ${({bottom}) => bottom ? bottom : '37px'};
border-radius: 6px;
.arrow{
  display: flex;
  width: 20px;
  height: 20px;
  background: ${({commonDashboard, color}) => commonDashboard ? color : '#0D4270'};
  transform: rotate(45deg);
  margin-right: -15px;
  font-size: 25px;
  color: transparent;
}
.name{
  display: flex;
  min-width: 140px;
  font-family: "Rubik-Medium";
  font-size: 16px;
  line-height: 22px;
  color:#FFFFFF;
  padding: 9px 18px;
  align-items: center;
  justify-content: center;
  background: ${({commonDashboard, color}) => commonDashboard ? color : '#0D4270'};
  border-radius: 6px;
  z-index:1000;
}
}


  font-size: 12px;
  display: table-cell;
  vertical-align: middle;
  width: 50%;
  height: 80%;
  cursor: pointer;
  position: relative;
  padding-left: ${({hideContent}) => hideContent ? '' : '15px'};
  // padding-top: 10px;
  // margin: 0 9px;
  margin: ${({hideContent}) => hideContent ? '0 9px' : ''};
  color: rgb(153, 153, 153);
  display: flex;
  align-items: center;
  border-radius: 3px;
  justify-content: ${({hideContent}) => hideContent ? 'center' : 'space-between'};
  font-family: Open-Sans-Bold;
  background-color: white;
  .iconLinkContainer {
    display: flex;
    align-items: center;
    > img {
      width: ${({marginLeft}) => marginLeft ? '' : '20px'};
      height: ${({marginLeft}) => marginLeft ? '14px' : '20px'};
      margin-left: ${({marginLeft}) => marginLeft ? '-5px' : ''};
    }
    >span {
      padding-left: 10px;
      padding-top: 5px;
      font-family: ${({active}) => active ? 'Rubik-Medium' : 'Rubik-Regular' };
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
    }
  }
  
  // > div {
  //   height: 35px;
  //   width: 5px;
  //   float: right;
  // }


  // >span svg {
  //   stroke: rgb(153, 153, 153);
  // }
  
  &:hover, &:active, &:focus {
    background-color: ${({bgColor}) => bgColor ? bgColor : 'rgba(11, 65, 112, 0.1)'};
    color: ${({commonDashboard, colour}) => commonDashboard ? colour : 'rgb(153, 153, 153)'};
    font-family: Open-Sans-Bold;
    outline: none;
    text-decoration: none;

    > span svg path{
      fill: ${({commonDashboard, colour}) => commonDashboard ? colour : '#0b4170'};
      stroke: none;
    }
    > span {
      >span{
        color:  ${({commonDashboard, colour}) => commonDashboard ? colour : '#0b4170'};
        font-family: 'Rubik-Medium';
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
      }
    }
  }

  ${({ active }) => active && css`
  background-color: ${({bgColor}) => bgColor ? bgColor : 'rgba(11, 65, 112, 0.1)'};
  color: ${({commonDashboard, colour}) => commonDashboard ? colour : 'rgb(153, 153, 153)'};
    font-family: 'Rubik-Medium';
    outline: none;
    text-decoration: none;
    
    > span svg path {
      fill: ${({commonDashboard, colour}) => commonDashboard ? colour : 'rgb(153, 153, 153)'};
      stroke: none;
    }
    >span{
      >span{
        font-family: 'Rubik-Medium';
      }
    }
  `}

  > span {
    float: left;
  }
  
  @media (max-width: 950px) {
    padding-left: 30px;
  }
`;
const StyledSvg = styled.svg`
  height: ${({height}) => height ? height : '30px'};
  width: ${({width}) => width ? width : '30px'};
  float: left;
  // fill: rgb(153, 153, 153);
  padding: ${({ padding }) => padding ? padding : '2px 0px 0px'};
  margin: ${({marginTop}) => marginTop && marginTop };
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'};
  pointer-events: none;
  // stroke: rgb(153, 153, 153);
    &:hover {
      ${({hover}) => hover && css`
        > path {
          fill: ${({hover}) => hover};
        }
      `}
    }
`;

const SidebarCard = styled.div`
  height:0px;
  display: flex;
  font-family: "Rubik-Medium";
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  justify-content: center;
  background: ${({commonDashboard, colour}) => commonDashboard ? colour : '#0D4270'};
  position: relative;
  z-index:100;
  display: flex;
  width: ${({width}) => width ? width : '140px'};
  left: ${({left}) => left ? left : '80px'};
  bottom: ${({bottom}) => bottom ? bottom : '37px'};
  border-radius: 6px;
  .arrow{
    display: flex;
    width: 20px;
    height: 20px;
    background: ${({commonDashboard, colour}) => commonDashboard ? colour : '#0D4270'};
    transform: rotate(45deg);
    margin-right: -15px;
    font-size: 25px;
    color: transparent;
  }
  .name{
    display: flex;
    min-width: 140px;
    font-family: "Rubik-Medium";
    font-size: 16px;
    line-height: 22px;
    color:#FFFFFF;
    padding: 9px 18px;
    align-items: center;
    justify-content: center;
    background: ${({commonDashboard, colour}) => commonDashboard ? colour : '#0D4270'};
    border-radius: 6px;
    z-index:1000;
  }
`;

export { SidebarGrid, HamBurgerContainer, MainSideBarLinks, Navigation, StyledLink, StyledSvg, SidebarCard };
