import React from  'react';
import PropTypes from 'prop-types';
import { CategoryCardV2, PointsCard, CardContainer, RealCard, ImageRender} from './newRewardCategoryStyle';
import {  ImageUrl } from '../../utils/constants';
import { commaSeperator } from '../../utils/methods';
// import ImageThumbnail from "../../components/ImageThumbnail";
import {withTranslation} from 'react-i18next';
import LazyImage from '../../components/common/LazyImage/LazyImage';
import { connect } from 'react-redux';

class NewRewardsCatagory extends React.Component {

  redirection = (data) =>{
    const { history } = this.props;
    history.push(data.redirection === 'event' ? "/events" : data.redirection === 'challenge' ? "/challenges" : null)
  }

  render() {
    const { category ,t,getIndex} = this.props;
    const lang = localStorage.getItem("lang");
    return (
      <CategoryCardV2>
        <CardContainer>
          <RealCard lang={lang}>
            <div className='title'>
              {t(category.title)}
            </div>
            <div className='main_Class'>
              <ImageRender width={getIndex=== 0 ? '123px' : getIndex === 1 ? '113px' :  getIndex === 2 ? '98px' : getIndex === 3 ? '104px' : '100px'}>
                <LazyImage src={`${ImageUrl}/images/NewRewardSection/${category.src}.png`} alt={"slide"}/>
              </ImageRender>
              <div className='content_class'>
                <div className='point_data'>
                  {commaSeperator(category.title === 'Fitness Points' ? (category.maxAvailableFitnessPoint) : 
                    (category.earnPoint && category.earnPoint || 0))}
                  <span>&nbsp;{this.props.t('pts')}</span>
                </div>
                {this.props?.user?.companyDetails?.is_cap_point ? <div className='rest_data'>{`${commaSeperator(category.availalePoint && category.availalePoint || 0)} ${t('points available')} `}</div>:null}
              </div>
            </div>
          </RealCard>
        </CardContainer>
        {category.details.length > 0 ? <div className={'pointsCalculator'}>
          <div className={'pointsEarned'}>
            <div className={'earnedText'}>{t("")}</div>
            {category.details.map((data, index) => (<PointsCard key={index} imageWidth={data.name === 'NEW AMIGOS ADDED' ? '28px' : (data.name === 'WORKOUT MINUTES LOGGED' || data.name === 'FITNESS VIDEOS WATCHED') ? '30px' : 'auto'}>
              <div>
                <div>
                  {commaSeperator(data.total || 0)}
                </div>
                <LazyImage div={"image_class"} src={`${ImageUrl}/${data.logo}`} />
              </div>
              <div>
                <div>
                  <div>
                    {t(data.name)}
                  </div>
                  {data.description === "" ? null:
                    <div>
                      {t(data.description)}
                    </div>
                  }
                </div>
                <div>
                  {commaSeperator(data.points || 0)}
                  <span>{t("pts")}</span>
                </div>
              </div>
            </PointsCard>
            ))
            }
          </div>
        </div>
          : null}
        {category?.bonus?.length > 0 ?
          <div className={'bonusPoints'}>
            <div className={'bonusHeader'}>{t("Bonus Registration Points")}</div>
            {category?.bonus?.map((data, index) => (<PointsCard key={index}>
              <div>
                <div>
                  {commaSeperator(data.total || 0)}
                </div>
                <LazyImage div={"image_class"} src={`${ImageUrl}/${data.logo}`} />
              </div>
              <div>
                <div>
                  <div>
                    {t(data.name)}
                  </div>
                  <div>
                    {t(data.description)}
                  </div>
                </div>
                <div>
                  {commaSeperator(data.points || 0)}
                  <span>{t("pts")}</span>
                </div>
              </div>
            </PointsCard>
            ))
            }
          </div> 
          : null}
      </CategoryCardV2>
    );
  }
} 

NewRewardsCatagory.propTypes = {
  category: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.func,
  monthlyRewardPoint: PropTypes.number,
  getIndex: PropTypes.number,
  user: PropTypes.object
}

const mapStateToProps = (state) => ({
  user: state.profileData,
});

export default connect(mapStateToProps, null)(withTranslation()(NewRewardsCatagory));