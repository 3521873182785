/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Layout, Header, BuddyTab, NoData, AddUserCard, HeaderTitle, ButtonHris, Wrapper, StyledModal, PopupLine, DownAngle} from './style';
import { ImageUrl } from '../../utils/constants';
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/config";
import { fetchApi } from "../../utils/methods";
import {AdminEventsAddCreateEventButton} from '../../utils/icons'
import { GetApiDeck, GetCurrentIntegrations, UpdateAutoSyncDays, Createorganization, Getorganization, Updateorganization, Deleteorganization, Createconnection, Deleteconnection  } from "../../redux/constants/apiConstants";
import { useVault } from "@apideck/vault-react";
import HrisCardComponent from './hrisCard';
import HoverButton from "../common/HoverButton";
import { Dropdown } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton';
import UpdateOrganization from './UpdateOrganization';
import DeletePostPopup from '../DeletePostPopup/deletePostConfirmation'
class HrisMain extends Component {
  constructor(props) {
    super();
    this.state = {
      showAdd: true,
      apiDeckConfig: null,
      integrations: [],
      integrationComponentRendered: false,
      companyId: props.companyInfo?.id,
      showSyncPopup: false,
      syncDay:7,
      postSyncResponse: null,
      organizationData: null,
      getOrganizationData: null,
      showOrganization: false,
      domainName: null,
      showOrganizationDelete: false,
      showOrganizationDomain: false,
      organizationDomainName: null,
      getRedirectionUrl: null,
      showConnectionDelete: false
    };
  }

  componentDidMount() {
    this.getCurrentIntegrationsFromVault();
    this.getOrganization()
  }

  syncDays = [
    {
      id: 1,
      name: 7
    },
    {
      id: 2,
      name: 14
    },
    {
      id: 3,
      name: 28
    }
  ]

  hideAddCard = () =>{
    this.setState({
      showAdd: false
    })
  }

  getApiDeck = async () => {
    const companyId = this.state.companyId; 
    try {
      const res = await fetchApi(`${GetApiDeck}/${companyId}/${'new_session'}`, "GET");
      if (res.message) {
        toast.error(res.message);
      } else {
        this.setState({
          apiDeckConfig: res.data,
          
        },()=>
        {
          this.manageIntegrations();
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  
  manageIntegrations = () => {
    const { open } = useVault();
    this.state.apiDeckConfig &&
      this.state.apiDeckConfig.session_token &&
      open({ token: this.state.apiDeckConfig.session_token,onConnectionDelete:()=>{    this.getCurrentIntegrationsFromVault()}});
  };

  getCurrentIntegrationsFromVault = async () => {
    const companyId = this.state.companyId; 
    try {
      const response = await fetchApi(`${GetCurrentIntegrations}/${companyId}/${'new_vault'}`, "GET");
      if (response.message) {
        toast.error(res.message);
      }
      else {
        const LogoData = response?.data
        const enabledData = LogoData?.length>0 && LogoData?.filter((item) => item.enabled);
        const connectedPlatform = enabledData?.length>0 && enabledData?.slice(0, 2);
        this.setState({ integrations: connectedPlatform,integrationComponentRendered:true, });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  sendReminderApiCall = async () => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let payload = {
      company_id: this.state.companyId,
      days: this.state.syncDay
    };
    let url = BASE_URL + UpdateAutoSyncDays;
    await fetch(url, {
      method: "POST",
      headers: {
        AUTHTOKEN: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (data.data.success_count) {
              this.setState({ showSyncPopup: false} );
            } else {
              this.setState({ showSyncPopup: false });
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  onCheckboxChange = (id) => {
    const { syncDay } = this.state;
    if(syncDay==id){
      this.setState({
        syncDay: null
      })
    }
    else{
      this.setState({
        syncDay: id
      })}
  };

  syncPopup = () => {
    const { showSyncPopup, syncDay } = this.state;
    return(
      <StyledModal show={showSyncPopup} width={"400px"}>
        <div className='header'>
          <div className='text'>Auto Sync</div>
          <div className='iconCross' onClick={()=>this.showSyncPopup()}>
            <img src={ImageUrl+"/event-section/cross.png"}/>
          </div>
        </div>
        <div className='body'>
          {this.syncDays.map((data,index)=>(
            <PopupLine key={index}>
              <div className='checkmark' onClick={()=>this.onCheckboxChange(data.name)}>
                {syncDay===data.name&&<img src={ImageUrl+"/ChallengeDetailsScreen/updatedCheckmark.svg"}/>}
              </div>
              <div className='text'>{data.name} Days</div>
            </PopupLine>
          ))}
          <div className='buttonWrapper'>
            <ButtonHris disable={!syncDay} onClick={!syncDay?null: ()=>this.sendReminderApiCall()}>
              Save Changes
            </ButtonHris>
          </div>
        </div>
      </StyledModal>
    )
  }

  showSyncPopup = () =>{
    this.setState({
      showSyncPopup: !this.state.showSyncPopup,
    })
  }

  createOrganization  = async(payload)=>{
    try{
      const res = await fetchApi(Createorganization, "POST",payload);
      if(res.data.message){
        toast.error(res.data.message);
        this.hideShowOrganizationDomainPopup()
      }else{
        toast.success('Organization created successfully');
        this.getOrganization();
        this.hideShowOrganizationDomainPopup()
      }
    }catch(error){
      // toast.error(error)
    }
  }

  getOrganization = async () => {
    const companyId = this.state.companyId; 
    try {
      const res = await fetchApi(`${Getorganization}/${companyId}`, "GET");
      if (res.message) {
        // toast.error(res?.message);
        this.setState({
          getOrganizationData: null,
          domainName:null
        })
      } else {
        this.setState({
          getOrganizationData: res?.data,
          domainName: res?.data && res?.data?.domains?.[0]?.domain
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  updateOrganizationData = () =>{
    const { domainName } = this.state;
    const organization = {
      company_id: this.state.companyId,
      domain_data: [
        { domain: domainName, state: "verified" }
      ]
    }
    this.updateOrganization(organization);
  }

  createOrganizationData = () =>{
    const { organizationDomainName } = this.state;
    const organization = {
      company_id: this.state.companyId,
      domain_data: [
        { domain: organizationDomainName, state: "verified" }
      ]
    }
    this.createOrganization(organization);
  }

  updateOrganization  = async(payload)=>{
    try{
      const res = await fetchApi(Updateorganization, "PUT",payload);
      if(res.data.message){
        toast.error(res.data.message);
      }else{
        toast.success('Organization created successfully');
        this.getOrganization();
        this.hideShowOrganizationPopup();
      }
    }catch(error){
      // toast.error(error)
    }
  }

  deleteOrganization = async () => {
    const companyId = this.state.companyId; 
    try {
      const res = await fetchApi(`${Deleteorganization}/${companyId}`, "DELETE");
      if (res.message) {
        toast.error(res.message);
      } else {
        this.setState({
          getOrganizationData: null,
          domainName:null
        })
        toast.success(res?.data[0]);
        this.showDeleteOrganization();
        this.getOrganization();
      }
    } catch (error) {
      toast.error(error.message);
      this.getOrganization()
    }
  };

  deleteConnection = async () => {
    const companyId = this.state.companyId; 
    try {
      const res = await fetchApi(`${Deleteconnection}/${companyId}`, "DELETE");
      if (res.message) {
        toast.error(res.message);
      } else {
        toast.success(res?.data[0]);
        this.showDeleteConnection();
        this.getOrganization();
      }
    } catch (error) {
      toast.error(error.message);
      this.getOrganization()
    }
  };

  createConnection  = async()=>{
    const payload = { company_id: this.state.companyId };
    try{
      const res = await fetchApi(Createconnection, "POST",payload);
      if(res.data.message){
        toast.error(res.data.message);

      }else{
        toast.success('Organization created successfully');
        this.getOrganization();
        window.location.href=res.data[0]
      }
    }catch(error){
      // toast.error(error)
    }
  }

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  hideShowOrganizationPopup = () =>{
    this.setState({
      showOrganization: !this.state.showOrganization
    })
  }

  hideShowOrganizationDomainPopup = () =>{
    this.setState({
      showOrganizationDomain: !this.state.showOrganizationDomain
    })
  }

  showDeleteOrganization = () =>{
    this.setState({
      showOrganizationDelete: !this.state.showOrganizationDelete
    })
  }

  showDeleteConnection = () =>{
    this.setState({
      showConnectionDelete: !this.state.showConnectionDelete
    })
  }

  isSSOProviderPresent=(inputString)=> {
    const ssoProviders = [
      { name: "okta",image:"/integration/okta.png" },
      { name: "cas", image:"/integration/cas.png" },
      { name: "entra", image:"/integration/microsoft.png" },
      { name: "azure", image:"/integration/microsoft.png" },
      { name: "google",  image:"/integration/google.png" },
      { name: "workspace", image:"/integration/google.png" },
      { name: "adp", image:"/integration/adp.png" },
      { name: "Auth", image:"/integration/auth.png" },
      { name: "classlink",  image:"/integration/classlink.png" },
      { name: "cloudflare", image:"/integration/classflare.png"  },
      { name: "cyberark", image:"/integration/cyberark.png" },
      { name: "duo",  image:"/integration/duo.png" },
      { name: "jumpcloud", image:"/integration/jumpcloud.png"  },
      { name: "keycloak", image:"/integration/keycloak.png" },
      { name: "lastpass", image:"/integration/lastpas.png"  },
      { name: "login.gov" , image:"/integration/login.png" },
      { name: ".gov" , image:"/integration/login.png" },
      { name: "ad fs", image:"/integration/microsoft.png" },
      { name: "miniorange", image:"/integration/miniorange.png" },
      { name: "NetIQ", image:"/integration/netlq.png" },
      { name: "IQ", image:"/integration/netlq.png" },
      { name: "onelogin", image:"/integration/onelog.png" },
      { name: "oracle", image:"/integration/oracle.png" },
      { name: "PingFederate", image:"/integration/ping.png" },
      { name: "Federate", image:"/integration/ping.png" },
      { name: "Pingone", image:"/integration/pone.png" },
      { name: "rippling", image:"/integration/rippling.png" },
      { name: "salesforce", image:"/integration/salesforce.png" },
      { name: "Shibboleth", image:"/integration/shib.png" },
      { name: "Unsolicited", image:"/integration/shib.png" },
      { name: "SAML" ,image:"/integration/simple.png" },
      { name: "VMware",image:"/integration/vmware.png" }
    ];
  
    const foundProvider = ssoProviders.find((provider) => 
      inputString.toLowerCase().includes(provider.name.toLowerCase())
    );
  
    return foundProvider ? <img src={ImageUrl+foundProvider.image}/> : <img src={ImageUrl+"/integration/sso.png"}/>;
  }

  render() {
    const { showAdd, integrations, getOrganizationData, showOrganization, domainName, showOrganizationDelete, showOrganizationDomain, organizationDomainName, showConnectionDelete } = this.state;
    return (
      <Layout>
        <div className='main'>
          <Header>
            <BuddyTab active>Integrations</BuddyTab>
            <div className='add'>
              <div>
                <Dropdown>
                  <Dropdown.Toggle>
                    <HoverButton svgPath={AdminEventsAddCreateEventButton()} activeColor={"#007AB1"} fillOpacity={1} title={"Integrate"} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={()=>this.getApiDeck()} >
                      HRIS
                    </Dropdown.Item>
                    {getOrganizationData?.id? null : <><hr/>
                      <Dropdown.Item onClick={()=>this.hideShowOrganizationDomainPopup()}>
                      Add SSO
                      </Dropdown.Item>
                    </>}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Header>
          {this.props.companyBranding?.company_branding?.show_hris ? <>
            {showAdd&&!integrations.length>0&&<AddUserCard>
              <div className='hand'>
                <div className='image'>
                  < img src={ImageUrl+"/Hris-image/hand.png"}/>
                </div>
              </div>
              <div className='adduser'>
                <div className='subtext'>
                  Click here to integrate HRIS software
                  of your company for quick access to
                  Invite all your users in one click.
                </div>
                <div className='skip'onClick={()=>this.hideAddCard()}>Skip</div>
              </div>
            </AddUserCard>}
            {
              integrations.length===0?
                <NoData margin={showAdd?"-145px":"40px"}>
                  <div className='image'>
                    <img src={ImageUrl+"/"+"Hris-image/no-data.png"}/>
                  </div>
                  <div className='text'>
                    Want to save time? Integrate your HRIS Platform to automate new hire invites.<br/>
                    Also, we’ll remove termed employees to reduce your seats. Saving you money!
                  </div>
                </NoData>:
                <Wrapper>
                  <HeaderTitle>
                    <div className='text'>HRIS Tools</div>
                    <div className='line'></div>
                  </HeaderTitle>
                  {integrations.length>0&& integrations.map((data,index)=>(
                    data.enabled&&
                    <HrisCardComponent data={data} key={index} showSyncPopup={this.showSyncPopup}/>
                  ))}
                  {this.syncPopup()}
                </Wrapper>}
          </>:null}
          <Wrapper margin="20px">
            {getOrganizationData?.name&&<HeaderTitle>
              <div className='text'>SSO Integration</div>
              <div className='line' style={{width:"calc(100% - 150px)"}}></div>
            </HeaderTitle>}
            {/* {getOrganizationData?.name&&<div className='title'>SSO Integration</div>} */}
            {getOrganizationData?.name&&<div className='sso-card'>
            Organization: {getOrganizationData?.name}
              <DownAngle  feed style={{display:"flex", margin:"auto 0 auto auto"}}>
                <DropdownButton id="editDelete" title={<img src={ImageUrl+"/social-feed/menu.svg"} />}>
                  <Dropdown.Item onClick={()=>this.hideShowOrganizationPopup()}>
                    {("Update")}
                  </Dropdown.Item>
                  <div className='line'></div>
                  <Dropdown.Item  onClick={()=>this.showDeleteOrganization()}>
                    {("Delete")}
                  </Dropdown.Item>
                </DropdownButton>
              </DownAngle>
              <div className='border'></div>
              <div className='content-wrapper'>
                {getOrganizationData?.connections[0]?.raw?.connection_type?<div className='image-logo'>
                  {this.isSSOProviderPresent(getOrganizationData?.connections[0]?.raw?.connection_type)}
                  {/* <img src={"https://res.cloudinary.com/apideck/image/upload/v1670332932/icons/adp-next-gen-hcm.png"}/> */}
                </div>:null}
                <div className='description'>
                  {/* Organization: {getOrganizationData?.name}
                  <DownAngle  feed style={{display:"flex", margin:"auto 0 auto auto"}}>
                    <DropdownButton id="editDelete" title={<img src={ImageUrl+"/social-feed/menu.svg"} />}>
                      <Dropdown.Item onClick={()=>this.hideShowOrganizationPopup()}>
                        {("Update")}
                      </Dropdown.Item>
                      <div className='line'></div>
                      <Dropdown.Item  onClick={()=>this.showDeleteOrganization()}>
                        {("Delete")}
                      </Dropdown.Item>
                    </DropdownButton>
                  </DownAngle> */}
                  {getOrganizationData?.connections?.length>0&&<>
                    {/* <div className='border'></div> */}
                    <div className='text'>Connection Details:
                      <DownAngle  feed style={{display:"flex", margin:"auto 0 auto auto"}} height="48px">
                        <DropdownButton id="editDelete" title={<img src={ImageUrl+"/social-feed/menu.svg"} />}>
                          <Dropdown.Item  onClick={()=>this.showDeleteConnection()}>
                            {("Delete")}
                          </Dropdown.Item>
                        </DropdownButton>
                      </DownAngle>
                    </div>
                    <div className='sub-text'>Domain: {getOrganizationData?.domains[0].domain}</div>
                    <div className='sub-text'>Connection: {getOrganizationData?.connections[0]?.raw?.connection_type}</div>
                    <div className='sub-text'>State: {getOrganizationData?.connections[0]?.raw?.state}</div>
                  </>}
                </div>
              </div>

              {/* Organization: {getOrganizationData?.name}
              <DownAngle  feed style={{display:"flex", margin:"auto 0 auto auto"}}>
                <DropdownButton id="editDelete" title={<img src={ImageUrl+"/social-feed/menu.svg"} />}>
                  <Dropdown.Item onClick={()=>this.hideShowOrganizationPopup()}>
                    {("Update")}
                  </Dropdown.Item>
                  <div className='line'></div>
                  <Dropdown.Item  onClick={()=>this.showDeleteOrganization()}>
                    {("Delete")}
                  </Dropdown.Item>
                </DropdownButton>
              </DownAngle>
              {getOrganizationData?.connections?.length>0&&<>
                <div className='border'></div>
                <div className='text'>Connection Details:
                  <DownAngle  feed style={{display:"flex", margin:"auto 0 auto auto"}} height="48px">
                    <DropdownButton id="editDelete" title={<img src={ImageUrl+"/social-feed/menu.svg"} />}>
                      <Dropdown.Item  onClick={()=>this.showDeleteConnection()}>
                        {("Delete")}
                      </Dropdown.Item>
                    </DropdownButton>
                  </DownAngle>
                </div>
                <div className='sub-text'>Domain: {getOrganizationData?.domains[0].domain}</div>
                <div className='sub-text'>Connection: {getOrganizationData?.connections[0]?.raw?.connection_type}</div>
                <div className='sub-text'>State: {getOrganizationData?.connections[0]?.raw?.state}</div>
              </>} */}
              {getOrganizationData?.connections?.length>0 && getOrganizationData?.connections[0].raw.state=="active"?null:<div className='button-size'>
                { getOrganizationData?.name && <ButtonHris onClick={()=>this.createConnection()}>{getOrganizationData?.connections?.length>0 && getOrganizationData?.connections[0].raw.state!="active"? "Update Connection":"Add Connection+"}</ButtonHris>}
              </div>}
            </div>}
          </Wrapper>
          {showOrganization&&<UpdateOrganization show={showOrganization} onClose={this.hideShowOrganizationPopup} domainName={domainName} onChangeInput={this.onChangeInput} updateOrganizationData={this.updateOrganizationData}/>}
          {showOrganizationDomain&&<UpdateOrganization show={showOrganizationDomain} onClose={this.hideShowOrganizationDomainPopup} domainName={organizationDomainName} onChangeInput={this.onChangeInput} updateOrganizationData={this.createOrganizationData} type="create"/>}
          {showOrganizationDelete&&<DeletePostPopup
            showModal={showOrganizationDelete}
            onClose={this.showDeleteOrganization}
            deletePopupOrganization={1}
            deleteOrganization={this.deleteOrganization}
            string="organization"
          />}
          {showConnectionDelete&&<DeletePostPopup
            showModal={showConnectionDelete}
            onClose={this.showDeleteConnection}
            deletePopupOrganization={1}
            deleteOrganization={this.deleteConnection}
            string="connection"
          />}
        </div>
      </Layout>
    )
  }
}

HrisMain.propTypes = {
  history: PropTypes.object.isRequired,
  companyInfo: PropTypes.object,
  companyBranding: PropTypes.object,
};

const mapStateToProps = (state) => ({
  companyBranding: state?.wellnessDashboard?.companyBrandingDetails,
})

export default connect(mapStateToProps, null)(HrisMain);
