import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, ProgressBar } from '../HealthTabUpdated/styles';
import { AssesmentWrapper, InsightReport, CategoryScoreCard, QuestionsCard, AnswerWrapper, AnswerCard, CircleNew, MultipleAnswerCard, CircularGraph, DropdownWrapper } from './styles';
import { BuddyTabContainer, BuddyTab } from "../../PeopleHomeV2/styles";
import CircularProgressBar from '../HealthTabUpdated/circularProgressBar';
import VerticalBarChart from "../../VerticalBarChart/VirticalBarChart";
import { NoData } from '../../ReportsV2/styles';
import LazyImage from '../../common/LazyImage/LazyImage';
import { ImageUrl } from '../../../utils/constants';
import Select from 'react-select';
import {SearchDropDown} from '../../CreateEvent/style';
import {CategoryCard} from '../../MainEvents/styles';

class Assesment extends Component {
  constructor() {
    super();
    this.state = {
      selectCategory: "Health",
      indexValue: 0,
      categoryId: 1,
      displayPercentage: false,
      showOption: null ,
      selectdQuestionId: null,
      showDropdownData:0,
      showDropdownValue:'all',
      menuIsOpen: false,
      filterValue:"All",
      hoveredCategoryID: null,
      dropdownArrowDirection: true,
    }
  }

  onSelectCategory = (value, index, id) => {
    const { selectedYearInfo, assesmentAPI } = this.props;
    this.setState({
      selectCategory: value,
      indexValue: index,
      categoryId: id
    }, () => {
      assesmentAPI(selectedYearInfo, id);
      this.onChangeDropdown("all", "All")
    })
  };

  categoriesCard = [
    {
      name: 'Health',
      background: 'rgba(244, 170, 169, 1)',
      color: 'rgba(159, 201, 137, .15)',
      icon: '/images/AdminReports/Health.png',
      image: '/wellbeing-category/health_white.png',
      gray_image: '/wellbeing-category/health_grey.png',
      shadow_color: 'rgba(244, 170, 169, 0.5)',
      id: 1
    },
    {
      name: 'Nutrition',
      background: 'rgba(118, 171, 120, 1)',
      color: 'rgba(159, 201, 137, .15)',
      icon: '/images/AdminReports/Nutrition.png',
      image: '/wellbeing-category/nutrition_white.png',
      gray_image: '/wellbeing-category/nutrition_grey.png',
      shadow_color: 'rgba(118, 171, 120, 0.5)',
      id: 4
    },
    {
      name: 'Workplace Wellness',
      background: 'rgba(241, 201, 119, 1)',
      color: 'rgba(246, 180, 121, .15)',
      icon: '/images/AdminReports/workplace.png',
      image: '/wellbeing-category/workplace_white.png',
      gray_image: '/wellbeing-category/workplace_grey.png',
      shadow_color: 'rgba(241, 201, 119, 0.5)',
      id: 6
    },
    {
      name: 'Physical Activity',
      background: 'rgba(133, 192, 234, 1)',
      color: 'rgba(105, 194, 255, .15)',
      icon: '/images/AdminReports/fitness.png',
      image: '/wellbeing-category/fitness_white.png',
      gray_image: '/wellbeing-category/fitness_grey.png',
      shadow_color: 'rgba(133, 192, 234, 0.5)',
      id: 3
    },
    {
      name: 'Lifestyle',
      background: 'rgba(175, 135, 193, 1)',
      color: 'rgba(157, 135, 169, .15)',
      icon: '/images/AdminReports/lifestyle.png',
      image: '/wellbeing-category/lifestyle_white.png',
      gray_image: '/wellbeing-category/lifestyle_grey.png',
      shadow_color: 'rgba(175, 135, 193, 0.5)',
      id: 2
    },
    {
      name: 'Mental Health',
      background: 'rgba(255, 191, 115, 1)',
      color: 'rgba(255, 153, 134, .15)',
      icon: '/images/AdminReports/emotional_wellness.png',
      image: '/wellbeing-category/stress_white.png',
      gray_image: '/wellbeing-category/stress_grey.png',
      shadow_color: 'rgba(255, 191, 115, 0.5)',
      id: 5
    },
  ]

  MedicalFormData = [
    {
      question: 'Q.1 Please select if you have been diagnosed with any of the following',
      questionCondition: '(choose all that apply).',
      isMultipleAnswers: true,
      percentage: 50,
      answers: [
        {
          type: "radio",
          value: "More than 5 years ago",
          count: 1,
          background: 'rgba(156, 156, 156, .20)',
          color: 'rgba(175, 135, 193, 1)',
          percentage: 3,
          positive: 1,
          negative: 0,
          percentageValue: 30
        },
        {
          type: "radio",
          value: "Every 4-5 years",
          count: 2,
          background: 'rgba(156, 156, 156, .20)',
          color: '#F4AAA9',
          percentage: 2,
          positive: 0,
          negative: 1,
          percentageValue: 20
        },
        {
          type: "radio",
          value: "Every 3-5 years",
          count: 3,
          background: 'rgba(156, 156, 156, .20)',
          color: '#F1C977',
          percentage: 4,
          positive: 1,
          negative: 0,
          percentageValue: 40
        },
        {
          type: "radio",
          value: "Every 1-3 Years",
          count: 4,
          background: 'rgba(156, 156, 156, .20)',
          color: '#85C0EA',
          percentage: 4,
          positive: 0,
          negative: 1,
          percentageValue: 20
        },
        {
          type: "radio",
          value: "Every Year",
          count: 5,
          background: 'rgba(156, 156, 156, .20)',
          color: '#76AB78',
          percentage: 6,
          positive: 1,
          negative: 0,
          percentageValue: 50
        }
      ]
    }
  ];

  insightReport = [
    {
      healthScore: "25",
      takeHRA: '50',
      category: 'health',
      icon: '/public/images/AdminReports/Health.png',
      color: 'rgba(253, 113, 117, 1)',
      taken: '4000',
      total: '9000',
      percentage: '40',
      name: "Health"
    },
    {
      name: 'Nutrition',
      healthScore: "67",
      takeHRA: '50',
      category: 'health',
      icon: '/public/images/AdminReports/Nutrition.png',
      color: 'rgba(159, 201, 137, 1)',
      taken: '4000',
      total: '9000',
      percentage: '40'
    },
    {
      name: 'Workplace',
      background: 'rgba(246, 180, 121, 1)',
      color: 'rgba(246, 180, 121, 1)',
      healthScore: "50",
      takeHRA: '50',
      category: 'health',
      icon: '/public/images/AdminReports/workplace.png',
      taken: '4000',
      total: '9000',
      percentage: '40'
    },
    {
      name: 'Physical',
      color: 'rgba(133, 192, 234, 1)',
      icon: '/public/images/AdminReports/fitness.png',
      healthScore: "40",
      takeHRA: '50',
      category: 'health',
      taken: '4000',
      total: '9000',
      percentage: '40'
    },
    {
      name: 'Lifestyle',
      color: 'rgba(175, 135, 193, 1)',
      icon: '/public/images/AdminReports/lifestyle.png',
      healthScore: "60",
      takeHRA: '50',
      category: 'health',
      taken: '4000',
      total: '9000',
      percentage: '40'
    },
    {
      name: 'Emotional',
      color: 'rgba(241, 201, 119, 1)',
      icon: '/public/images/AdminReports/emotional_wellness.png',
      healthScore: "70",
      takeHRA: '50',
      category: 'health',
      taken: '4000',
      total: '9000',
      percentage: '40'
    },
  ]

  graphRender = (data, id) => {
    window.console.log("graph data is ---",data)
    const topActivities = [{ y: data[0]['answer_percentage'], color: 'rgba(175, 135, 193, 1)' }, { y: data[1]['answer_percentage'], color: '#F4AAA9' }, { y: data[2]['answer_percentage'], color: '#F1C977' }, { y: data[3]['answer_percentage'], color: '#85C0EA' }, { y: data[4]['answer_percentage'], color: '#76AB78' }];
    const xAxisLabels = [data[0]['answer'], data[1]['answer'], data[2]['answer'], data[3]['answer'], data[4]['answer']];
    const yAxisLabels = ['20%', '40%', '60%', '80%', '100%'];
    const dataValue = [{
      name: '',
      data: topActivities,
      showInLegend: false,
      color: '#DE9444'
    }];
    return (
      <div className="bargraph">
        <VerticalBarChart
          id={`${id} ${1}`}
          seriesData={dataValue}
          xAxisLabel={xAxisLabels}
          yAxisVisible={yAxisLabels}
          fillColor={1}
          cultureSurvey={1}
        />
      </div>)
  }

  handleMouseEnter = (index, id) => {
    this.setState({ showOption: index, displayPercentage: true , selectdQuestionId:id})
  }

  handleMouseLeave = (index) => {
    this.setState({ showOption: index, displayPercentage: false, selectdQuestionId:null })
  }

  onChangeDropdown = (value, label) => {
    this.setState({ showDropdownValue:value, filterValue:label })
  }

  sortArrayDescending= (arr) =>{
    arr.sort((a, b) => {
      const numA = a.answers.average_percentage !== undefined ? a.answers.average_percentage : Number.POSITIVE_INFINITY;
      const numB = b.answers.average_percentage !== undefined ? b.answers.average_percentage : Number.POSITIVE_INFINITY;
      return numB - numA;
    });
    return arr;
  }

  sortArrayAscending = (arr) =>{
    arr.sort((a, b) => {
      const numA = a.answers.average_percentage ; 
      const numB = b.answers.average_percentage ;
      return numA - numB;
    });
    return arr;
  }

  DropdownIndicator = () => (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{position: 'relative', top: '0px', right: '10px', transform: this.state.menuIsOpen?"rotate(90deg)":"none" }} >
      <path d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z" fill="#0D4270"/>
    </svg>
  );

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen,
    }));
  };

  // To update background color of category card on hover
  handleMouseEnterCategoryCard = (value) =>{
    this.setState({
      hoveredCategoryID: value,
    })
  }
  // To update background color of category card on hover
  handleMouseLeaveCategoryCard = () =>{
    this.setState({
      hoveredCategoryID: null,
    })
  }

  render() {
    const { selectCategory, indexValue,   /*displayPercentage, showOption, selectdQuestionId,*/ showDropdownValue, /*menuIsOpen,*/ filterValue, hoveredCategoryID } = this.state;
    const { WbaQuestionData, healthWellbeingCardData, assesmentDetails } = this.props;
    let WbaData=[];
    if(showDropdownValue==="high"&& WbaQuestionData && WbaQuestionData.length>0){
      WbaData=this.sortArrayDescending(WbaQuestionData);
    }
    else if(showDropdownValue==="low" && WbaQuestionData && WbaQuestionData.length>0){
      WbaData=this.sortArrayAscending(WbaQuestionData);
    }
    else{
      WbaData=WbaQuestionData;
    }
    const insightReportData = [
      healthWellbeingCardData['health_wellness_assessment']['having_wellness_users'],
      healthWellbeingCardData['company_wellbeing_score']['over_all_company_wellbeing_percentage']
    ]
    const healthWellnessScore = [
      {
        name: 'Health',
        percentage: assesmentDetails ? assesmentDetails.health : 0,
        earned: assesmentDetails ? assesmentDetails.health_points : 0,
        total: assesmentDetails ? assesmentDetails.health_total_points : 0,
        background: 'rgba(244, 170, 169, 1)',
        color: 'rgba(159, 201, 137, .15)',
        icon: '/images/AdminReports/Health.png',
        imageBackgroundColor: 'rgba(244, 170, 169, 0.10)',
      },
      {
        name: 'Nutrition',
        percentage: assesmentDetails ? assesmentDetails.nutrition : 0,
        earned: assesmentDetails ? assesmentDetails.nutrition_points : 0,
        total: assesmentDetails ? assesmentDetails.nutrition_total_points : 0,
        background: 'rgba(118, 171, 120, 1)',
        color: 'rgba(159, 201, 137, .15)',
        icon: '/images/AdminReports/Nutrition.png',
        imageBackgroundColor: 'rgba(118, 171, 120, 0.10)',
      },
      {
        name: 'Workplace',
        percentage: assesmentDetails ? assesmentDetails.workplace : 0,
        earned: assesmentDetails ? assesmentDetails.workplace_points : 0,
        total: assesmentDetails ? assesmentDetails.workplace_total_points : 0,
        background: 'rgba(241, 201, 119, 1)',
        color: 'rgba(246, 180, 121, .15)',
        icon: '/images/AdminReports/workplace.png',
        imageBackgroundColor: 'rgba(241, 201, 119, 0.10)',
      },
      {
        name: 'Physical',
        percentage: assesmentDetails ? assesmentDetails.fitness : 0,
        earned: assesmentDetails ? assesmentDetails.fitness_points : 0,
        total: assesmentDetails ? assesmentDetails.fitness_total_points : 0,
        background: 'rgba(133, 192, 234, 1)',
        color: 'rgba(105, 194, 255, .15)',
        icon: '/images/AdminReports/fitness.png',
        imageBackgroundColor: 'rgba(133, 192, 234, 0.10)',
      },
      {
        name: 'Lifestyle',
        percentage: assesmentDetails ? assesmentDetails.lifestyle : 0,
        earned: assesmentDetails ? assesmentDetails.lifestyle_points : 0,
        total: assesmentDetails ? assesmentDetails.lifestyle_total_points : 0,
        background: 'rgba(175, 135, 193, 1)',
        color: 'rgba(157, 135, 169, .15)',
        icon: '/images/AdminReports/lifestyle.png',
        imageBackgroundColor: 'rgba(175, 135, 193, 0.10)',
      },
      {
        name: 'Emotional',
        percentage: assesmentDetails ? assesmentDetails.stress : 0,
        earned: assesmentDetails ? assesmentDetails.stress_points : 0,
        total: assesmentDetails ? assesmentDetails.stress_total_points : 0,
        background: '#FFBF73',
        color: 'rgba(255, 153, 134, .15)',
        icon: '/images/AdminReports/emotional_wellness.png',
        imageBackgroundColor: 'rgba(255, 191, 115, 0.10)',
      }
    ]
    const filter = [
      {
        label: "All" ,
        value: "all",
        key: "All"
      },
      {
        label: "Low - High Score" ,
        value: "low",
        key:"Low - High"
      },
      {
        label: "High - Low Score" ,
        value: "high",
        key:"High - Low"
      },
    ]  

    const customStyles = {
      control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          borderColor: state.isFocused ? "hsl(0,0%,70%)" : "hsl(0,0%,70%)"
        }
      }),
      option: (styles) => ({
        ...styles,
        fontSize: '12px',
        fontWeight: 700,
        color: 'rgb(0, 92, 135) !important',  
      }),
    };

    return (
      <Wrapper>
        <div className="categoryCardsWrapper">
          {this.categoriesCard.map((data, index) => (
            <CategoryCard
              key={index}
              onClick={() =>
                this.onSelectCategory(data.name, index, data.id)
              }
              onMouseEnter={()=>this.handleMouseEnterCategoryCard(data.id)}
              onMouseLeave={()=>this.handleMouseLeaveCategoryCard()}
              bgColor={
                (this.state.selectCategory == data.name || this.state.hoveredCategoryID == data.id) ? "1" : ""
              }
              shadow={
                this.state.selectCategory == data.name || this.state.hoveredCategoryID == data.id ? data.background : ""
              }
            >
              {hoveredCategoryID === data.id || selectCategory==data.name ? (
                <img src={ImageUrl + data.image} />
              ) : (
                <img src={ImageUrl + data.gray_image} />
              )}
              <div className="categoryName">{data.name}</div>
            </CategoryCard>
          ))}
        </div>
        <BuddyTabContainer marginBottom="25px" marginRight="15px">
          <BuddyTab margin="10px 0px" active={true}>
            Overview
          </BuddyTab>
          <DropdownWrapper>
            <div className="label">Filter</div>
            <SearchDropDown
              padding="10px"
              width="145px"
              height="40px"
              indicatorWidth="11px"
              valueContainerP="0px 10px"
              dropdownTextFont="rubik-medium !important"
              transform={ this.state.dropdownArrowDirection ? "rotate(0deg)" : "rotate(90deg)"}
              pcolor={filterValue ? "#005C87" : "#005C87"}
              pfont={filterValue ? "rubik-medium" : "rubik"}
              right="10px"
              onClick={()=>this.setState({dropdownArrowDirection: !this.state.dropdownArrowDirection})}
              onBlur={()=>this.setState({dropdownArrowDirection: true })}
              isReportDropdown={false}
            >
              <Select
                value={filterValue}
                options={filter}
                onChange={(option) =>
                  this.onChangeDropdown(option.value, option.key)
                }
                classNamePrefix={"menu-item"}
                styles={customStyles}
                components={{ DropdownIndicator: this.DropdownIndicator, IndicatorSeparator: () => null }}
                onMenuOpen={this.toggleMenu}
                onMenuClose={this.toggleMenu}
                placeholder={filterValue}
                isSearchable={false}
                formatOptionLabel={filterValue}
              />
            </SearchDropDown>
          </DropdownWrapper>
        </BuddyTabContainer>
        <AssesmentWrapper>
          <div className="reportCard">
            <InsightReport >
              <div className="header">
                Insight Report
              </div>
              <div className="body">
                <div className="cardNoBorder">
                  <LazyImage
                    div={"img"}
                    src={ImageUrl + "/images/AdminReports/response.png"}
                    alt={"arrow-up"}
                  />
                  <div className="name">Total Responses</div>
                  <div className="score">{insightReportData[0]}</div>
                </div>
              </div>
            </InsightReport>
            <CategoryScoreCard
              color={healthWellnessScore[indexValue]["background"]}
              imageBackgroundColor={healthWellnessScore[indexValue]["imageBackgroundColor"]}
            >
              <div className="categoryDetails">
                <LazyImage
                  div={"image"}
                  src={ImageUrl + healthWellnessScore[indexValue]["icon"]}
                  alt={"arrow-up"}
                />
                <div className="name">
                  {" "}
                  Company {healthWellnessScore[indexValue]["name"]}
                  <br />
                  Score
                </div>
              </div>
              <ProgressBar
                height={"25px"}
                background={"rgba(0, 92, 135, .1)"}
                backgroundActive={healthWellnessScore[indexValue]["background"]}
                width={
                  (healthWellnessScore[indexValue]["earned"] * 100) /
                  healthWellnessScore[indexValue]["total"]
                }
                paddingRight="0px"
              >
                <div className="progress"></div>
              </ProgressBar>
              <div className="score">
                <div className="value">
                  {healthWellnessScore[indexValue]["earned"]
                    ? healthWellnessScore[indexValue]["earned"]
                    : 0}
                  /
                  {healthWellnessScore[indexValue]["total"]
                    ? healthWellnessScore[indexValue]["total"]
                    : 0}
                </div>
                <div className="value">
                  {healthWellnessScore[indexValue]["earned"]
                    ? `${Math.round(
                      (healthWellnessScore[indexValue]["earned"] * 100) /
                          healthWellnessScore[indexValue]["total"]
                    )}%`
                    : 0}
                </div>
              </div>
            </CategoryScoreCard>
          </div>
          {WbaData && WbaData.length === 0 ? (
            <NoData width="65.9%">No Question Available</NoData>
          ) : (
            <div className="questionCard">
              {WbaData &&
                WbaData.length > 0 &&
                WbaData.map((data, index) =>
                  data.graph_type === 1 ? (
                    <QuestionsCard key={index} width={data.is_multiple_answers}>
                      <div className="question">{`Q${data.question_no}. ${data.question}`}</div>
                      <div className="body">
                        <div className="answers">
                          <AnswerWrapper
                            display="flex"
                            wrap="wrap"
                            padding="25px 25px 15px 25px"
                          >
                            {data.answers["options"].map((ans, index) => (
                              <MultipleAnswerCard
                                key={index}
                                color={ans.color}
                                margin={(index + 1) % 3 === 0 ? "0px" : "15px"}
                                backgroundActive={ans.graph_color}
                              >
                                <div className="option">{ans.answer}</div>
                                <div className="bar">
                                  <ProgressBar
                                    height={"16px"}
                                    backgroundActive={ans.graph_color}
                                    background={ans.background_color}
                                    width={ans.answer_percentage}
                                    paddingRight="0px"
                                  >
                                    <div className="progress"></div>
                                    <div className="percentageValue">
                                      {ans.answer_percentage > 0
                                        ? `${ans.answer_percentage}%`
                                        : null}
                                    </div>
                                  </ProgressBar>
                                </div>
                              </MultipleAnswerCard>
                            ))}
                          </AnswerWrapper>
                        </div>
                      </div>
                    </QuestionsCard>
                  ) : data.graph_type === 6 ? (
                    <QuestionsCard key={index} width={data.graph_type === 6}>
                      <div className="question">{`Q${data.question_no}. ${data.question}`}</div>
                      <div className="body">
                        <div className="answers">
                          <AnswerWrapper
                            display="flex"
                            wrap="wrap"
                            padding="25px 25px 15px 25px"
                          >
                            <div className="circleWrapper">
                              {data.answers["options"].map((ans, index) => (
                                <CircularGraph
                                  key={index}
                                  backgroundActive={ans.graph_color}
                                  color={
                                    ans.answer_percentage > 30
                                      ? "white"
                                      : ans.graph_color
                                  }
                                  circleWidth={
                                    ans.answer_percentage * 0.36 * 10
                                  }
                                  background={ans.graph_color}
                                >
                                  <div className="circleContainer">
                                    <div className="circle">
                                      <div className="hidePercentCard">
                                        <div className="showPercent">
                                          {ans.answer_percentage}%
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="option">{ans.answer}</div>
                                </CircularGraph>
                              ))}
                            </div>
                          </AnswerWrapper>
                          {data.point_status ? (
                            <div className="circularGraphPercent">
                              <ProgressBar
                                marginBottom="10px"
                                height={"16px"}
                                backgroundActive={"#005C87"}
                                width={data.answers["average_percentage"]}
                                paddingRight="0px"
                              >
                                <div className="progress"></div>
                              </ProgressBar>
                              <span className="value">
                                {data.answers["average_percentage"]}%
                              </span>
                              <span className="average">Average Score</span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </QuestionsCard>
                  ) : data.graph_type === 5 || data.graph_type === 4 ? (
                    <QuestionsCard
                      key={index}
                      width={data.graph_type === 5 || data.graph_type === 4}
                    >
                      <div className="question">{`Q${data.question_no}. ${data.question}`}</div>
                      <div className="body">
                        <div className="answers">
                          <AnswerWrapper
                            display="flex"
                            wrap="wrap"
                            padding="25px 25px 15px 25px"
                            flex="space-around"
                          >
                            <div className="circleWrapper">
                              {data.answers["options"].map((ans, index) => (
                                <CircularGraph
                                  key={index}
                                  circleWidth={
                                    ans.answer_percentage * 0.36 * 10
                                  }
                                  background={ans.graph_color}
                                  backgroundColor="white"
                                  align="center"
                                  padding="20px 20px 0px 30px"
                                >
                                  <div className="circleContainer">
                                    <CircularProgressBar
                                      strokeWidth="9"
                                      sqSize="120"
                                      percentage={ans.answer_percentage}
                                      color={ans.graph_color}
                                      hideText={true}
                                      textValue=""
                                    />
                                  </div>
                                  <div className="option">{ans.answer}</div>
                                </CircularGraph>
                              ))}
                            </div>
                          </AnswerWrapper>
                          {data.point_status ? (
                            <div className="circularGraphPercent">
                              <ProgressBar
                                marginBottom="10px"
                                height={"16px"}
                                background={"rgba(0, 92, 135, 0.10)"}
                                backgroundActive={"#005C87"}
                                width={data.answers["average_percentage"]}
                                paddingRight="0px"
                              >
                                <div className="progress"></div>
                              </ProgressBar>
                              <span className="value">
                                {data.answers["average_percentage"]}%
                              </span>
                              <span className="average">Average Score</span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </QuestionsCard>
                  ) : data.graph_type === 2 ? (
                    <QuestionsCard
                      key={index}
                      widthValue={data.point_status ? "67.4%" : "100%"}
                    >
                      <div className="question">{`Q${data.question_no}. ${data.question}`}</div>
                      <div className="body">
                        {data.point_status ? (
                          <div className="circularPercentage">
                            <CircleNew width="170px" height="170px">
                              <CircularProgressBar
                                strokeWidth="17"
                                sqSize="170"
                                percentage={data.answers["average_percentage"]}
                                color="#005C87"
                                textValue="Average Score"
                              />
                            </CircleNew>
                          </div>
                        ) : null}
                        <div className="answers">
                          <AnswerWrapper padding="35px 35px 37px 37px">
                            {data.answers["options"].map((ans, index) => (
                              <AnswerCard
                                key={index}
                                color={ans.color}
                                display="flex"
                                margin="10px"
                                border="1"
                              >
                                <div className="wrapper">
                                  <div className="optionValue">
                                    {ans.answer}{" "}
                                    {/^\d+\+?$/.test(ans.answer) &&
                                      "Drugs /Day"}
                                  </div>
                                  <div className="progressGraph">
                                    <ProgressBar
                                      radius="1"
                                      height={"35px"}
                                      backgroundActive={ans.graph_color}
                                      background={ans.background_color}
                                      width={ans.answer_percentage}
                                      paddingRight={
                                        ans.answer_percentage ? "15px" : "0px"
                                      }
                                    >
                                      <div
                                        className="progress"
                                        onMouseEnter={() =>
                                          this.handleMouseEnter(
                                            index,
                                            data.question_id
                                          )
                                        }
                                        onMouseLeave={() =>
                                          this.handleMouseLeave(
                                            index,
                                            data.question_id
                                          )
                                        }
                                      ></div>
                                      {
                                        <span className="showPercent">
                                          {ans.answer_percentage}%
                                        </span>
                                      }
                                    </ProgressBar>
                                  </div>
                                </div>
                              </AnswerCard>
                            ))}
                            <div className="line">
                              <div className="fill"></div>
                              <div className="borderLine">
                                <div className="value">0</div>
                                <div className="value">20%</div>
                                <div className="value">40%</div>
                                <div className="value">60%</div>
                                <div className="value">80%</div>
                                <div className="value">100%</div>
                              </div>
                            </div>
                          </AnswerWrapper>
                        </div>
                      </div>
                    </QuestionsCard>
                  ) : data.graph_type === 3 ? (
                    <QuestionsCard
                      key={index}
                      width={data.point_status ? false : true}
                      widthValue={data.point_status ? "67.4%" : "67.4%"}
                      type={data.graph_type === 3}
                    >
                      <div className="question">{`Q${data.question_no}. ${data.question}`}</div>
                      <div className="body">
                        {data.point_status ? (
                          <div className="circularPercentage">
                            <CircleNew width="170px" height="170px">
                              <CircularProgressBar
                                strokeWidth="17"
                                sqSize="170"
                                percentage={data.answers["average_percentage"]}
                                color="#005C87"
                                textValue="Average Score"
                              />
                            </CircleNew>
                          </div>
                        ) : null}
                        <div className="answers">
                          <AnswerWrapper>
                            {this.graphRender(
                              data.answers["options"],
                              data.question_id
                            )}
                          </AnswerWrapper>
                        </div>
                      </div>
                    </QuestionsCard>
                  ) : (
                    <QuestionsCard key={index}>
                      <div className="question">{`Q${data.question_no}. ${data.question}`}</div>
                      <div className="body">
                        <div className="circularPercentage">
                          <CircleNew width="170px" height="170px">
                            <CircularProgressBar
                              strokeWidth="17"
                              sqSize="170"
                              percentage={data.answers["average_percentage"]}
                              color="#005C87"
                              textValue="Average Score"
                            />
                          </CircleNew>
                        </div>
                        <div className="answers">
                          <AnswerWrapper>
                            {data.answers["options"].map((ans, index) => (
                              <AnswerCard
                                key={index}
                                color={ans.graph_color}
                                margin="0px"
                              >
                                <div className="wrapper">
                                  <div className="answerCard">
                                    <div className="answerDataValue">
                                      <div className="first">
                                        <div className="optionName">
                                          {ans.answer}
                                        </div>
                                      </div>
                                      <div className="second">
                                        {ans.answer_percentage}%
                                      </div>
                                    </div>
                                  </div>
                                  <ProgressBar
                                    height={"16px"}
                                    backgroundActive={ans.graph_color}
                                    background={ans.background_color}
                                    width={ans.answer_percentage}
                                    paddingRight="0px"
                                  >
                                    <div className="progress"></div>
                                  </ProgressBar>
                                </div>
                              </AnswerCard>
                            ))}
                          </AnswerWrapper>
                        </div>
                      </div>
                    </QuestionsCard>
                  )
                )}
            </div>
          )}
        </AssesmentWrapper>
      </Wrapper>
    );
  }
}

Assesment.propTypes = {
  history: PropTypes.object,
  wellbeingCategoriesData: PropTypes.array,
  WbaQuestionData: PropTypes.array,
  selectedYearInfo: PropTypes.number,
  companyId: PropTypes.number,
  assesmentAPI: PropTypes.func,
  healthWellbeingCardData: PropTypes.array,
  assesmentDetails: PropTypes.array
}

export default Assesment;