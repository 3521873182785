import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import { withRouter } from 'react-router-dom';
// import Image from 'react-image-resizer';
import { CircleContainerNew, Circle, Tick } from '../Journeys/styles';
import { Layout, ArticleSection, Description, Button, ButtonContainer, ImageContainer, TitleContainer,ImageContainerIcons, AdminButtons } from './styles';
import Waiting from '../Waiting';
import { ImageUrl } from '../../utils/constants';
import { getAdventureArticle, updateLockStatus, FetchQuizzes, SubmitQuiz, getFavoriteArticles, getRecommendedArticle, fetchsWellnessJourneysById } from '../../redux/actions';
import AdventurePointDashBoard from'../AdventurePointDashBoard/index';
const PopUp = React.lazy(() => import('./popup'));
const ResultNew = React.lazy(() => import('./articleQuiz'));
import { getUserQuizScore } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import parse from 'react-html-parser';
// import styled from 'styled-components';
// const SanitizedDescription = styled.div``;

class Article extends Component {
  constructor() {
    super();
    this.descriptionRef = React.createRef();
    this.state = {
      save: 0,
      like: 0,
      showQuiz: false,
      showResultPopUp: false,
      showResult: false,
      imagestatus: 0
    }
  }
  
  componentDidMount() {
    const { fetchAdventureArticle, match, fetchQuizzes, getUserQuizScore, fetchsWellnessJourneysById } = this.props;
    fetchAdventureArticle(match.params.id, match.params.categoryName);
    fetchQuizzes(match.params.id, 0);
    this.updateUserPoints();
    getUserQuizScore(match.params.id);
    fetchsWellnessJourneysById(match.params.id);
    this.setSanitizedDescription();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.journeyDetails?.description !== this.props?.journeyDetails?.description) {
      this.setSanitizedDescription();
    }
  }

  onSuccess = () =>{
    const { fetchAdventureArticle, match, getFavoriteArticles, fetchRecommendedArticles, fetchsWellnessJourneysById, journeyDetails} = this.props;
    fetchAdventureArticle(match.params.id, match.params.categoryName);
    getFavoriteArticles(10);
    fetchRecommendedArticles();
    !journeyDetails && fetchsWellnessJourneysById(match.params.id)
  }

  updateUserPoints = () => {
    const { updateUserGlobalPoints, journeyDetails, updateLockStatus } = this.props;
    journeyDetails && updateLockStatus(journeyDetails.id);
    journeyDetails && updateUserGlobalPoints(journeyDetails.id, 7, this.onSuccess);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // const nextArray = nextProps.location.pathname.split('/');
    // if ((this.props.adventureArticle && this.props.adventureArticle.length==0) &&(this.props.location !== nextProps.location || this.props.adventureArticle !== nextProps.adventureArticle || !this.props.adventureArticle)) {
    //   nextProps.fetchAdventureArticle(nextArray[nextArray.length - 1], nextArray[nextArray.length - 3]);
    // }

    if ((this.props.journeyDetails !== nextProps.journeyDetails) && nextProps.journeyDetails){
      if(nextProps.journeyDetails && !nextProps.journeyDetails.read_status){
        nextProps.updateLockStatus(nextProps.journeyDetails.id);
        nextProps.updateUserGlobalPoints(nextProps.journeyDetails.id, 7, this.onSuccess)
      }
    }
  }

  componentWillUnmount() {
    const { getFavoriteArticles, fetchRecommendedArticles} = this.props;
    getFavoriteArticles(10);
    fetchRecommendedArticles();
  }

  getNextArticle = () => {
    const { history, adventureIds } = this.props;
    const stringArray = history.location.pathname.split('/');
    const adventureId = stringArray[stringArray.length - 1];
    const index = adventureIds && adventureIds.findIndex((id) => adventureId == id);
    history.push(`/education/adventure/${stringArray[stringArray.length - 3]}/article/${adventureIds[index]}/quiz`);
  };

  displayDeviceModal = () => {
    this.setState({ showQuiz: true })
  };

  displayResultModal = () => {
    const { journeyDetails, getUserQuizScore } = this.props;
    getUserQuizScore(journeyDetails.id);
    this.setState({ showResult: true })
  };

  hideResultModal = () => {
    this.setState({ showResult: false });
    this.onSuccess();
  };

  editJourney = () => {
    const { history, location, journeyDetails } = this.props;
    history.push({
      pathname: `${location.pathname}/edit`,
      state: { ...journeyDetails }
    });
  };

  addQuiz = () => {
    const { history, location } = this.props;
    history.push({ pathname: `${location.pathname}/show-all-quiz` });
  }

  onSave = () => {
    this.setState({
      save: !this.state.save,
    })
  };

  saveImage = () => {
    this.setState({
      save: !this.state.save,
    })
  }

  addLike = (journeys) => {
    const { onClickFavoriteRibbon } = this.props;
    onClickFavoriteRibbon("article", journeys?.id, journeys?.like_status ? 0 : 1, journeys?.favorite_status, 1)
    this.setState({
      like: !this.state.like,
      imagestatus: journeys.like_status===1?1:0
    })
  };

  addCategory = (categoryName, categories) => {
    let index = categories.findIndex((data) => data.category_name.toLowerCase() == categoryName.toLowerCase());
    return index != -1 ? categories[index].image : "test.png";
  };

  hideQuiz = () => {
    this.setState({ showQuiz: false });
  };

  setSanitizedDescription() {
    if (this.descriptionRef.current) {
      const sanitizedDescription = DOMPurify.sanitize(this.props?.journeyDetails?.description);
      const fragment = document.createRange().createContextualFragment(sanitizedDescription);
      while (this.descriptionRef.current.firstChild) {
        this.descriptionRef.current.removeChild(this.descriptionRef.current.firstChild);
      }
      this.descriptionRef.current.appendChild(fragment);
    }
  }

  render() {
    const { location, history, quizQuestions, createOrUpdateQuiz,onClickFavoriteRibbon, updateArticle, role, match, fetchAdventureArticle, fetchQuizzes, submitQuiz, userQuizScore, t, journeyDetails } = this.props;
    const { save, like, showQuiz,showResult, imagestatus } = this.state;
    const stringArray = location.pathname.split('/');
    const adventureId = stringArray[stringArray.length - 1];
    if (!quizQuestions || isUndefined(journeyDetails) || isNull(journeyDetails) || (adventureId != (journeyDetails && journeyDetails.id)) || !role) {
      return <Waiting />
    }
    return (
      <Layout>
        <div>
          <TitleContainer>
            <div>
              <div><img src={`${ImageUrl}/${journeyDetails.category_image}`} /></div>
              <span >{t(journeyDetails.category_name)}</span>
            </div>
            <div>
              <div>{t(journeyDetails.title)}</div>
              <div>
                {save !== 0 || journeyDetails?.favorite_status ?
                  <img src="/public/images/NewDashboardV2/ribbonColor.png" onClick={() => { onClickFavoriteRibbon("article", journeyDetails.id, 0, journeyDetails.favorite_status ? 0 : 1, 0); this.saveImage() }} /> :
                  <img src="/public/images/NewDashboardV2/ribbonNew.png" onClick={() => { onClickFavoriteRibbon("article", journeyDetails.id, 0, journeyDetails.favorite_status ? 0 : 1, 0); this.saveImage() }} />
                }
              </div>
            </div>
          </TitleContainer>
          <ArticleSection>
            <div style={{ "background": "white" }}>
              <ImageContainer >
                <img src={`${ImageUrl}/${journeyDetails.image}`} width="100%" height="100%" />
              </ImageContainer>
              <ImageContainerIcons>
                <div>
                  <div>
                    {!imagestatus && (like || journeyDetails.like_status)? <img src="/public/images/NewDashboardV2/ColorLike.png" alt="like" onClick={() => this.addLike(journeyDetails)} />:<img src="/public/images/NewDashboardV2/like.png" alt="like" onClick={() => this.addLike(journeyDetails)} /> }
                    {like ? <span>{journeyDetails.like_status ?((journeyDetails.likes_count) -1 ): ((journeyDetails.likes_count) + 1)}</span> : <span>{(journeyDetails.likes_count)}</span>}
                  </div>

                  <div>
                    <img src="/public/images/NewDashboardV2/view.png" alt="view" />
                    <span>{journeyDetails.seen_count}</span>
                  </div>
                </div>
                <div >
                  <CircleContainerNew marginRight={1} font={"14px"}>
                    <div className="flex">
                      <Circle borderColor="#9c9c9c" checked={journeyDetails.read_status}>
                        <Tick checked={journeyDetails.read_status} />
                      </Circle>
                    </div>
                    <div className="quiz">
                      {t("Article")}
                    </div>
                  </CircleContainerNew>
                  {quizQuestions && quizQuestions.length>0?<CircleContainerNew style={{ "marginLeft": "15px" }} font={"14px"}>
                    <div className="flex">
                      <Circle borderColor="#9c9c9c" checked={journeyDetails.quiz_status}>
                        <Tick checked={journeyDetails.quiz_status} />
                      </Circle>
                    </div>
                    <div className="quiz">
                      {t("Quiz")}
                    </div>
                  </CircleContainerNew>:null}
                </div>
              </ImageContainerIcons>
            </div>
            <Description>
              {/* <SanitizedDescription ref={this.descriptionRef} /> */}
              <div>{parse(journeyDetails?.description)}</div>
              <ButtonContainer>
                { journeyDetails.quiz_status || userQuizScore.quiz_status?
                  <Button  onClick={() => this.displayResultModal()} color={"#9FC989"}>
                    {t("Quiz Details")}
                  </Button>:
                  quizQuestions && quizQuestions.length===0?null:
                    <Button /*disabled={quizQuestions && quizQuestions.length==0}*/  onClick={() => quizQuestions && quizQuestions.length>0 && this.displayDeviceModal()}>
                      <img src="/public/images/NewDashboardV2/tquizarrow.png" alt="back-arrow" />
                      {t("Take Quiz")}
                    </Button>}  
                <React.Suspense fallback={<Waiting/>}>
                  <PopUp
                    showModal={showQuiz}
                    closeModal={this.hideQuiz}
                    quetions={this.props.quizQuestions}
                    adventureId={match.params.id}
                    categoryName={match.params.categoryName}
                    fetchAdventureArticle={fetchAdventureArticle}
                    fetchQuizzes={fetchQuizzes}
                    submitQuiz={submitQuiz}
                    adventureArticle={journeyDetails}
                    quizQuestions={quizQuestions}
                    history={history}
                  />
                </React.Suspense>
                <React.Suspense fallback={<Waiting/>}>
                  <ResultNew showModal={showResult} closeModal={this.hideResultModal} restartQuiz={this.restartQuiz} userQuizScore={userQuizScore} />
                </React.Suspense>
              </ButtonContainer>
            </Description>
          </ArticleSection>
        </div>
        <div >
          {updateArticle &&
            <AdminButtons background={"#9FC989"} onClick={this.editJourney}>
              <div>
                <img src="/public/images/NewDashboardV2/editarticle.png" alt="back-arrow" />
              </div>
              <div>
                {t("Edit Article")}
              </div>
            </AdminButtons>
          }
          {createOrUpdateQuiz &&
            <AdminButtons background={"#69C2FF"} onClick={this.addQuiz}>
              <div>
                <img src="/public/images/NewDashboardV2/showquiz.png" alt="back-arrow" />
              </div>
              <div>
                {t("Show Quiz")}
              </div>
            </AdminButtons>
          }
          <AdventurePointDashBoard />
        </div>
      </Layout>
    )
  }
}

Article.propTypes = {
  history: PropTypes.object.isRequired,
  fetchAdventureArticle: PropTypes.func.isRequired,
  adventureArticle: PropTypes.object,
  count: PropTypes.number,
  adventureIds: PropTypes.array,
  location: PropTypes.object.isRequired,
  updateUserGlobalPoints: PropTypes.func.isRequired,
  updateLockStatus: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  quizQuestions: PropTypes.array,
  fetchQuizzes: PropTypes.func.isRequired,
  submitQuiz: PropTypes.func.isRequired,
  match: PropTypes.object,
  updateArticle: PropTypes.bool,
  createOrUpdateQuiz: PropTypes.bool,
  onClickFavoriteRibbon: PropTypes.func,
  data: PropTypes.object,
  getUserQuizScore: PropTypes.func.isRequired,
  userQuizScore: PropTypes.object,
  getFavoriteArticles: PropTypes.func,
  fetchRecommendedArticles: PropTypes.func.isRequired,
  t: PropTypes.func,
  fetchsWellnessJourneysById: PropTypes.func,
  journeyDetails: PropTypes.array
};

const mapStateToProps = (state) => ({
  adventureArticle: state.education.adventureArticle,
  adventureIds: state.education.adventureIds,
  count: state.education.count,
  quizQuestions: state.education.quizQuestions,
  quizScore: state.education.quizScore,
  userQuizScore: state.education.userQuizScore,
  journeyDetails: state.education.journeyDetails
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdventureArticle: (id, category) => dispatch(getAdventureArticle(id, category)),
  updateLockStatus: (id) => dispatch(updateLockStatus(id)),
  fetchQuizzes: (id, value) => dispatch(FetchQuizzes(id, value)),
  submitQuiz: (data, categoryName, push) => dispatch(SubmitQuiz(data, categoryName, push)),
  getUserQuizScore: (id) => dispatch(getUserQuizScore(id)),
  getFavoriteArticles: (id) => dispatch(getFavoriteArticles(id)),
  fetchRecommendedArticles: () => dispatch(getRecommendedArticle()),
  fetchsWellnessJourneysById: (id) => dispatch(fetchsWellnessJourneysById(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Article)));
