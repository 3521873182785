import styled, { css } from "styled-components";
import { ModalTitle, Tabs, Modal } from 'react-bootstrap';
import { ImageInput } from '../CreateEvent/styles';
const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';
import { ImageUrl } from "../../utils/constants";
import { DropdownItem, DropdownButton, Dropdown } from 'react-bootstrap';


const StyledTabContainer = styled(Tabs)`
   float: left;
   width: 100%;
  > ul {
    padding: 20px 20px 1px 33px;
    font-size: 12px;
    text-transform: uppercase;
    color: rgb(153,153,153);
    background: ${({ bgColor }) => bgColor ? bgColor : '#fff'};
    border-radius: 6px;
    margin: 0 37px 0 32px;
    
    > li {
      > a {
        font-size: 12px;
        letter-spacing: 0px;
        color: rgb(153,153,153);
        font-family: ${BoldFont};
        margin-right: 5vw;
        text-transform: uppercase;
        padding: 5px 5px 17px 5px;
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border-bottom: none;
        }
      }
    }
    
    .active {
      border-bottom: none;
      > a {
        background-color: transparent;
        color: rgb(21,159,202);
        border: none;
        border-bottom: 3px solid rgb(21,159,202);
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border: none;
          border-bottom: 3px solid rgb(21,159,202);
          color: rgb(21,159,202);
        }
      }
    }
  }
`;

const SocialFeedsContainer = styled.div`
  float: left;
  width: ${({ webinar, width }) => width?width: webinar ? '100%' : '65%'};
  margin-right: ${({ videoCSS }) => videoCSS ? '0px' : '25px'};
  max-width: 568px;
  >div:nth-child(2){
    float: left;
    width: 100%;
    margin-top: 25px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const ScrollableFeedsContainer = styled.div`
  width: 100%;
  float: left;
  height: 1550px;
  // overflow-x: hidden;
  // overflow-y: auto;

  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    
    @media (max-width: 500px) {
      width: 5px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: #c7c8c7;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: white;
  }
`;

const InputFieldContainer = styled.div`
  width: ${({ UpdateStatus, survey, width }) => UpdateStatus || survey || width ? '100%' : 'calc(100% - 58px)'};
  float: left;
  display: flex;
  background: white;
  -moz-box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  -webkit-box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  height: 'auto';
  border-color: ${({ comment, survey }) => comment ? 'rgba(0, 0, 0, 0.09)' : survey ? '#cdcbcb' : 'none'};
  border-image: ${({ comment }) => comment ? 'initial' : 'none'};
  padding: ${({ padding }) => padding ? padding : '15px'};
  border-radius: ${({ comment }) => comment ? '0px' : '6px'};
  border-width: ${({ comment, survey }) => comment || survey ? '1px' : '0px'};
  border-style: ${({ comment, survey }) => comment || survey ? 'solid' : 'none'};
  height: ${({ height }) => height};
  flex-direction: ${({ flex }) => flex && 'row-reverse'};
  ${({ height }) => height && css`
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 6px 6px 0px 0px !important;
  `}

  >div{
  // height: ${({ isCommentOrReply }) => isCommentOrReply && "100%"};

  }
  >div:nth-child(2){
    width: 90%; 
    .
  }
  @media (max-width: 500px) {
    width: ${({ UpdateStatus }) => UpdateStatus ? '100%' : 'calc(100% - 50px)'};
  }
  .CustomTextAreaField {
    ${'' /* padding-top: 15px; */}
    line-height: 20px;
    height: 50px;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    // width: calc(100% - 4px);
    width: 100%;
    color: #005C87;
    resize: none;
    float: left;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    scrollbar-width: none;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #005C8799;
      opacity: 1;
    } 

    :-ms-input-placeholder {
      color: #005C8799;
    }

    ::-ms-input-placeholder {
      color: #005C8799;
    }  
  }
  .CustomTextAreaField::-webkit-scrollbar {
  width: 0;
}
  
  .CustomTextAreaField2 {
    padding-top: 15px;
    line-height: 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    width: calc(100% - 4px);
    color: #005C8799;
    resize: none;
    float: left;
    height: 80px;
    position: absolute;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #005C8799;
      opacity: 1;
      font-size: 18px;
      font-family: Rubik-Regular;
    }

    :-ms-input-placeholder {
      color: #005C8799;
      font-size: 18px;
      font-family: Rubik-Regular;
    }

    ::-ms-input-placeholder {
      color: #005C8799;
      font-size: 18px;
      font-family: Rubik-Regular;
    }
  }
`;

const InputTextArea = styled.div`
  position: relative;
  height: ${({ isCommentOrReply, height, peer }) => peer ? '100%' : height ? '100px' : isCommentOrReply ? '50px' : '100px'};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  >textarea{
    width: 100%;
    display: flex;
    resize: none;
    border: ${({ border }) => border};
    padding-top: ${({ border }) => border && '2px'};  
    height: ${({ border }) => border && '20px'};
    font-size: 16px;
    color: #3a3a3a;
    &:focus, &:active, &:hover, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
    
    &::placeholder {
      color: #9c9c9c;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #9c9c9c;
    }

    &::-ms-input-placeholder {
      color: #9c9c9c;
    }
  }
`;

const ShowTaggedUser = styled.div`
  margin-bottom: 5px;
  display: inline-block;  
  position:relative;
  width: ${({ width }) => width ? 'auto' : '100%'};
  border-radius: 5px;
  background: ${({ height }) => height ? '#f9f9f9' : '#f9f9f9'};
  cursor: default;
  border: ${(border) => border ? 'none' : 'solid 1px #dadada'};
  float: left;
  padding: ${(padding, paddingValue) => paddingValue?"5px 8px":padding ? '2px 0px' : '2px 10px'};
  height: ${({ height }) => height && "39px !important"}
  font-family: ${MediumFont};
  display: flex;
  align-items: center;
  color: ${(color) => color ? '#005c87' : '#005c87'}; 
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-right: 5px;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 10px 5px 5px;
  border-radius: 30px;
  padding-left:${({ isCommentOrReply }) => isCommentOrReply && "10px"};

  >img: first-child{
    width: ${({ imgWidth }) => imgWidth ? imgWidth : '22px'};
    height: ${({ imgWidth }) => imgWidth ? imgWidth : '22px'};
    margin-right: 9px;
    margin-bottom: 2px;
    margin-left: 0px;
  }

 .tagUserText{
  display:inline-block;
  height:40px;
  width:${({ isCommentOrReply }) => isCommentOrReply ? "280px" : "150px"};
    // border:none !important;
    padding: 15px !important;
    padding-right: 0px !important;
    margin-left:5px;
    resize: none !important;
    outline: 0px none transparent !important; 
    // background: rgba(156, 156, 156, 0.05);
    // border: 1px solid rgba(156, 156, 156, 0.5);
    border-radius: 6px;
    margin-bottom:6.5px;
  }
  
  .cross{
    // position:absolute;
    // width:15px;

    cursor: pointer;
    z-index:1000;
    // top:6px;
    // right:${({ isCommentOrReply }) => isCommentOrReply ? "-135px" : "-20px"};
    margin-left: 10px;
  }
`;

const ShowTaggedUser2 = styled.div`
  margin-bottom: 5px;
  border-radius: 5px;
  border: none;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #ffffff;;
  cursor: default;
  float: left;
  padding: 2px 5px;

  font-family: Rubik-Regular;
  color: #005c87;
  letter-spacing: 1px;
  text-transform: capitalize;
`;
const ShoutImage = styled.img`
  margin-left: 5px;
  max-width: 20px;
  max-height: 20px;
`;

const Cross = styled.img`
  margin-left: 5px;
  width: 20px;
  height: 20px;
  z-index: 999;
  cursor: pointer;
`;

const UserContainer = styled.div`
  width: ${({ width }) => width ? width : "100%"};
  position: ${({ position }) => position ? position : "absolute"};
  margin-top: ${({isManagerMargin}) => isManagerMargin ? isManagerMargin : ''};
  height: auto;
  border-radius: 6px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px;
  z-index: 50;
  padding: 15px;
  display: ${({ showSuggestor }) => showSuggestor ? "block !important" : "none !important"};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  overflow-x: hidden;
  overflow-y: auto;
  height:270px;
   ::-webkit-scrollbar {
   width: 7px;
   height: 10px;
 }
 ::-webkit-scrollbar-thumb {
   background: #005C8799;
   cursor: pointer;
   border-radius: 2.5px;
 }
 
 ::-webkit-scrollbar-track {
   background: #005C870A;
   border-radius: 2.5px;
 }

`;


const UserDropdownContainer = styled.div`
  // background: ${({ index, currentSelection }) => index === currentSelection ? 'rgba(156, 156, 156, 0.1)' : ''};
  : hover {
    cursor: pointer;
  }
`;

const ListDropdown = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  word-spacing: normal;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  font-family:"Rubik";
  font-weight: ${({ index, currentSelection }) => index === currentSelection ? 'bold' : 'normal'};
  color: ${({ index, currentSelection }) => index === currentSelection ? '#005c87' : '#005c87'};
`;
const ProfilePicture = styled.div`
  width: 100%;
  display: inline-block;
`;

const CustomTextAreaField = styled.textarea`
  font-size: 14px;
  border-radius: 4px;
  border: none;
  width: calc(100% - 25px);
  color: rgb(153,153,153);
  resize: none;
  float: left;
  height: 100%;
  resize: none;
  ${'' /* &:focus, &:active {
    outline: none;
  } */}
    
  ${'' /* ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  } */}

  ${'' /* :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }*/}
`;

const ContentContainer = styled.div`
  float: left;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  display: flex;
`;

const InputImage = styled(ImageInput)`
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  float: right;
  margin-bottom: 0;
  margin-top: ${({ noMarginTop }) => noMarginTop ? '5px' : '55px'}};
  > i {
    color: #dddddd;
    font-size: 25px;
  }
`;

const IconContainer = styled.div`
  display: ${({ cameraIcon }) => cameraIcon ? 'contents' : 'block'}
  width: 100%;
  height: auto;
  float: inherit;
`;

const ShoutoutDiv = styled.div`

  >div {
    padding: 13px 10px;
    margin: 0px 0;
    height: 50px;
    padding-left: 0px;width:${({ isCommentSection }) => isCommentSection && "125%"};
  }
  // display: flex;
`;

const ShoutoutContainer = styled.div`
  width: ${({ width }) => width ? width : '135px'};
  font-family: Rubik-Regular;
  margin-right: 5px;
  margin-top: 5px
  float: left;
  padding: ${({ height }) => height ? '3px 15px' : '2px 15px'};
  border-radius: 18px;
  height: ${({ height }) => height}
  border: 1px solid #dddddd;
  cursor: pointer;
  color: #423d3d;
  background: #e6e6e6;
  display: ${({ display }) => display === 1 ? 'flex' : 'block'};
  justify-content: ${({ display }) => display === 1 ? 'center' : 'none'};
    
  > img {
    vertical-align: text-bottom;
    padding-right: 10px;
    width: 30px;
  }
`;

const FeedsContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: 15px;
  // background-color: ${({ background }) => background ? 'transparent' : 'white'};
  border-radius: 6px;
  // -moz-box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);
  // -webkit-box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);
  // box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);

  &:first-child {
    margin-top: 0;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  float: left;
  padding: ${({ onModal }) => onModal ? '16px' : '25px 25px 0'};
  display: flex;
  align-items: center;
  margin:${({ margin }) => margin ? margin : '0'};
  .buttonWrapper{
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    .closeicon{
      margin-bottom:12px;
      cursor:pointer;
    }
  }
  .buttondiv{
    display:flex;
    margin: 0px auto auto auto;
    .closeicon{
      margin-left:16px;
      cursor:pointer;
    }
    >i{
      .dropdown{
        display:flex;
      }
    }
  }
  > img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    float: left;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 767px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }

  @media (max-width: 500px) {
    padding: ${({ onModal }) => onModal ? '0' : '25px 15px 0 15px'};
  }
`;

const ImageWrapper = styled.div`
  width: ${({ isCommentSection }) => isCommentSection ? '48px' : '50px'};
  height: ${({ isCommentSection }) => isCommentSection ? '48px' : '50px'};
  border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '50%'};
  float: left;
  cursor: pointer;
  margin-right: ${({ isCommentSection }) => isCommentSection ? '20px' : '0'};
  display: ${({ display }) => display ? '' : 'none'};

  div{
    > img {
    width: 50px;
    height: 50px;
    border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '50%'};
    float: left;
    cursor: pointer;
    object-fit: cover;
  }

  }

  > img {
    width: 50px;
    height: 50px;
    border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '50%'};
    float: left;
    cursor: pointer;
    object-fit: cover;
  }

  ${({ isCommentSection }) => isCommentSection && css`
  div{
    > img {
      width: 50px;
      height: 50px;
    }

  }
    > img {
      width: 50px;
      height: 50px;
    }
  `}

  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    > img {
      width: 40px;
      height: 40px;
    }
  }
`;

const UserInformation = styled.div`
  float: left;
  display: inline-block;
  margin-left: 10px;
  line-height: 25px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: ${({ width, newWidth }) => newWidth ? "calc(100% - 200px)" : width ? 'calc(100% - 130px)' : 'calc(100% - 70px)'};
  max-width: ${({ width, newWidth }) => newWidth ? "calc(100% - 200px)" : width ? 'calc(100% - 130px)' : 'calc(100% - 70px)'};
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: flex-start;
  > span {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #005c87;
    height: 50%;
    padding-top: 5px;
    letter-spacing: 0px;
    font-family: ${BoldFont};
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    // cursor: pointer;
  }
  .name {
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #005c87;
    // height: 50%;
    letter-spacing: 0px;
    font-family: ${BoldFont};
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  
  > div {
    font-size: 14px;
    line-height:20px;
    letter-spacing: 0px;
    color: #005C87;
    margin-top: 5px;
    > i {
      margin-right: 5px;
    }
  }
  .time{
    color: #005C87;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display:flex;
    margin:0px;
  }
  
  @media only screen and (max-width: 767px) {
    margin-right: 10px;
    width: ${({ width }) => width ? 'calc(100% - 130px)' : 'calc(100% - 70px)'};
  }

  ${({ isOwnPost }) => isOwnPost && css`
    width: calc(100% - 110px);
  `}
`;

const Description = styled.div`
  width: ${({ width }) => width ? 'auto' : '100%'};
  display: ${({ contents }) => contents ? 'inline-block' : 'block'}
  font-size: 16px;
  letter-spacing: 0px;
  color: #005c87;
  padding: ${({ paddingmain }) => paddingmain ? paddingmain : '0px 20px'};
  margin-top: 0px;
  white-space: pre-line;
  word-break: break-word;
  font-family: ${MediumFont};
  position:relative;
  .truncate {
    display: block;
    max-height: ${({ text }) => text ? text : ''};
    line-height: 20px; /* Set line height according to your design */
    overflow: hidden; /* Hide overflow */
    white-space: normal;
  }

  .more-text{
    // position: absolute;
    // bottom: 1px;
    // right: 15px;
    width:100%;
    color:rgba(0, 92, 135, 0.60);
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display:flex;
    justify-content:flex-end;
    cursor:pointer;
  }
  
  
  
  
  @media (max-width: 500px) {
    padding: 0 0px;
  };

  span {
    letter-spacing: 0px;
    // color: rgb(21,159,202);
    font-family: ${MediumFont};
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
    color: rgba(0, 92, 135, 0.60);
    // padding: 0px 25px 0;
  }
`;

const FeedInformation = styled.div`
  width: 100%;
  float: left;
  background: ${({ background }) => background ? background : ''};
  // border-bottom: 1px solid #e2e2e4;
  ${'' /* padding: 0 45px 25px 35px; */}

  @media (max-width: 500px) {
    padding: 0 0 25px;
    > img {
      width: 100%;
    }
  }
`;

const SingleFeedDescription = styled.div`
  margin-top: 35px;
  padding: 0 45px 0 25px;
  @media (max-width: 500px) {
    padding: 0 15px;
  };
`;

const ShoutoutImage = styled.div`
  display: inline;
  > img {
    width: ${({ comment }) => comment ? '16px' : '24px'};
    height: ${({ comment }) => comment ? '16px' : '24px'};
    margin-left: 7px;
  }
`;

const FeedFeatures = styled.div`
  // float: left;
  padding: 0 25px 0px;
  width: 100%;
  margin-top: 15px;
  border-top: 1px solid #D8D8D8;
  border-top: ${({ border, zeroBorder }) => zeroBorder?"none": border ? '1px solid rgba(156, 156, 156, 0.3)' : '1px solid #D8D8D8'};
  height: 40px;
  
  > div {
    float: left;
    line-height: 36px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;

  }
  
  > button {
    float: right;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  @media (max-width: 500px) {
    padding: 0 15px;
  }
`;

const CommentCounts = styled.button`
  display: inline-block;
  float: left;
  margin-left: 18px;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: white;

  > i {
    float: left;
    color: #bbbbbb;
    font-size: 16px;
    margin-right: 6px;
  }
  
  > span {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #005C8799;
    font-family: 'Rubik';
    float: left;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-top: 0;
    margin-right: 6px;
  }

  &:active, &:focus {
    outline: none;
  }
`;

const LikeCounts = styled(CommentCounts)`
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 25px;
    margin-right: 7px;
  }
  > i {
    color: #bbbbbb;
  }
  > span {
    font-family: ${MediumFont};
    font-size: 12px;
    line-height: 20px;
    color: #005c87;
  }
  ${({ active }) => active && css`
    > i {
      color: #ef6827;
    }
  `}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed !important;
    opacity: 0.5;
    background-color: white;
  `}
`;

const CommentButton = styled.button`
  background-color: #159fc9;
  border: 1px solid #159fc9;
  color: white;
  white-space: nowrap;
  padding: 8px 20px;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 30px;
  text-transform: uppercase;
  font-family: ${BoldFont};

  ${({ right }) => right && css`
    float: right;
    margin-top: 15px;
  `}
  
  &:focus, &:active, &:hover {
    outline: none;
  }

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
`;

const StyledTitle = styled(ModalTitle)`
  font-size: 18px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  font-family: ${BoldFont};
  text-align: center;
`;

const StyledInput = styled.input`
  width: calc(100% - 52px);
  float: right;
  font-size: 14px;
  padding: 12px 10px;
  border-radius: 4px;
  border: none;
  color: rgb(153,153,153);
  
  &:focus, &:active {
    outline: none;
  }
      
  ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }
`;

const ImageContainer = styled.div`
  padding: 15px;
  width: 100%;
  float: left;
  
  > img {
    width: 100%;
    height: ${({ editPost }) => editPost && '264px'};
  }

  @media (max-width: 500px) {
    padding: 15px 0;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  margin: 20px 0;
`;

const SideBarContainer = styled.div`
  width: ${({ videoCSS, width }) => width?width: videoCSS ? '35%' : 'calc(35% - 25px)'};
  max-width: 385px;
  float: left;
  .wellnessInitiatives {
    width: 100%;
    background-color: white;
    box-shadow: 0 0 21px rgba(0,0,0,0.08);
    float: left;
    margin-bottom: 20px;
  }
  
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-left: ${({ videoCSS }) => videoCSS && '10px'}
  }
  .biometric-label {
    font-size: 12px;
    letter-spacing: 0px;
    color: rgb(255,255,255);
    margin-bottom: 20px;
    cursor: pointer;
    width: 100%; 
  }
`;

const TileContainer = styled.div`
  width: 100%;
  float: left;
  background-color: white;
  padding: ${({ padding }) => padding ? '0px' : '15px'};
  margin-top: ${({ profilePage }) => profilePage ? '0' : '30px'};
  box-shadow: ${({ padding }) => padding ? 'none' : '1px 1.732px 46px rgba(0,0,0,0.1)'};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  border-radius: ${({ padding }) => padding && '6px'};
  position: ${({ padding }) => padding && 'relative'};
  cursor: ${({ padding }) => padding && 'pointer'};
  &:first-child {
    margin-top: 0;
  }
  .bg-opacity {
    position: absolute;
    height: 225px;
    width: 100%;
    top: 0;
    left: 0;
    background: #000000;
    opacity: 0.3;
    border-radius: 6px;
  }
  .image{
    width: 41px;
    height: 41px;
    position: absolute;
    bottom: 65px;
    left: 25px;
  }
  .featured-text{
    font-family: ${MediumFont};
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
    position: absolute;
    bottom: 85px;
    left: 68px;
  }
  @media (max-width: 1235px){
    width: ${({ width }) => width ? '49%' : '100%'}
  }
  @media (max-width: 500px){
    width: 100%;
  }
`;

const TitleContent = styled.div`
  font-size: 14px;
  border-bottom: 1px solid #e2e2e4;
  width: 100%;
  float: left;
  padding: 0 10px 10px 5px;
  letter-spacing: 0px;
  line-height: 28px;
  cursor: ${({ isClickable }) => isClickable ? 'pointer' : ''};
  
  > i {
    color: #159fc9;
    font-size: 13px;
  }
  > span {
    margin-left: 8px;
  }
  
  span:nth-child(3) {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 28px;
    float: right;
    color: rgb(153,153,153);
    
    > span {
      font-family: ${BoldFont};
      color: rgb(21,159,202);
    }
  }
  
  > svg {
    width: 20px;
    height: 24px;
    padding-top: 4px;
  }
`;

const SideBarContent = styled.div`
  padding: 15px 10px 5px 5px;
  float: left;
  width: 100%;
  .inline {
    float: left;
    height: 100%;
    width: 100%;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
  > span {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
    color: rgb(153,153,153);
  }

  .image-container {
    float: left;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }

    > img {
      float: left;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: ${({ isClickable }) => isClickable && 'pointer'}
    }
  }
  
  ${(props) => props.notificationSection && css`
    border-bottom: 1px solid #dededf;
    padding: 15px 10px 15px 5px;
    height: 100%;
  `}
`;

const StyledImage = styled.div`
  float: left;
  height: 100%;
  display: table;
  width: ${({ width }) => width};

  .middle {
    height: 100%;
    display: ${({ left }) => left ? 'inline-block' : 'table-cell'};
    vertical-align: middle;
    
    img {
      width: 45px;
      height: ${({ height }) => height ? height : '45px'};
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }
  }
`;

const DropDownStyledImage = styled.div`
  float: left;
  height: 100%;
  display: table;
  width: ${({ width }) => width};
  background: transparent;
  border-radius: 3px;
  height: 50px;
  border-bottom:1px solid #005C871A;
  .middle {
    height: 100%;
    display: ${({ left }) => left ? 'inline-block' : 'table-cell'};
    vertical-align: middle;
    min-width: 50px;
    min-height: 40px;
    > img {
      // border-radius: 50%;
      width: 40px;
      height: 40px;
      float: left;
      margin: 5px 10px;
      border-radius:50%;
    }
  }
`;

const NotificationDescription = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  word-spacing: normal;
  display: table-cell;
  vertical-align: middle;
  .lowerCase {
    text-transform: lowercase;
    color: rgb(153,153,153);
  }
`;

const WellnessExpertImage = styled.img`
  float: left;
  width: 100%;
  cursor: pointer;
  border-radius: 6px;
  height: 225px;
`;

const WellnessExpertName = styled.div`
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 28px;
  color: rgb(51,51,51);
  font-family: ${MediumFont};
  float: left;
  width: 100%;
  text-align: left;
  padding: 15px 0 15px 35px;
  cursor: pointer;
`;

const FeaturedEventsContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: ${({ noMargin }) => noMargin ? '0' : '20px'};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
`;

const DownAngle = styled.i`
  float: right;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: ${({ lineH }) => lineH ? '' : '45px'};
  cursor: pointer;
  margin-left: 20px;
  .dropdown{
    >button{
      display:flex;
    }
  }
  .fas {
    margin-right: 4px;
    color: rgb(153,153,153);
  }
  .fa {
    margin-right: 4px;
    color: rgb(153,153,153);
  }  
  @media only screen and (max-width: 767px) {
    position: relative;
    right: 0px;
    margin-left: 38px;
    .btn {
      padding: 0px;
    }
  }
  .dropdown-toggle {
    border: none !important;
    background-color: ${({ background, backgroundColor }) => backgroundColor?"transparent !important": background ? '#F4FAFF !important' : 'white !important'};
    box-shadow: none !important;
    padding: 0px;
    .caret {
      display: none;
    }
    > i {
      color: #3A3A3A;
      transform: rotate(90deg);
    }
  }   
  .dropdown-toggle::after{
    border-right:none !important;
    border-left:none !important;
  }
  .dropdown-item{
    color: rgb(0, 92, 135);
    font-family: Rubik-Medium;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    &:focus, &:active, &:hover, &:focus-visible {
      background: none;
      outline: none !important;
    }
  }
  .dropdown-menu {
    margin-left: ${({ width, margin, feed, marginL }) => marginL?marginL: feed?"-75px": margin?"-120px": width ? '-100px' : '-98px'};
    width: ${({ width }) => width ? 'auto' : '125px'};
    min-width: 0px !important;
    border: none;
    margin-top: ${({ margin, feed, marginT }) => marginT?marginT: feed?"0px": margin ? '0px' : '-10px'};
    border:1px solid #005C871A;
    @media(max-width: 600px) {
      margin-top: -7px;
      margin-left: -86px;
    }
    > li {
      padding:10px 20px 10px 10px;
      border-bottom:1px solid #005C871A;
      :last-child{
        border-bottom:none;
      }
      > a {
        color: #005C87;
        font-family: Rubik-Medium;
        font-style: normal;
        font-size:12px;
        line-height:20px;
        &:focus, &:active, &:hover, &:focus-visible {
          border: none !important;
          outline: none !important;
        }
      }
    }
  }
`;

const NoComments = styled.div`
  float: left;
  width: 100%;
  padding: 30px;
  font-size: 15px;
  font-family: ${BoldFont};
`;

const NoActivityFeeds = styled.p`
  font-size: 14px;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgb(153,153,153);
  float: left;
  width: 100%;
  padding: 15px 0 0 5px;
`;

const Layout = styled.div`
  padding: 25px;
  margin-top: 10px;
  width: 100%;
  float: left;
  min-height: 650px;
  font-family: ${MediumFont};
  overflow: scroll;
  display: flex;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    padding: 45px 15px 20px;
  }
`;

const Profile = styled.div`
  display: inline-block;
  width: 50%;
  text-align: left;
  vertical-align: middle;

  &:last-child {
    text-align: right;
  }

  .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    > img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
  
      @media (max-width: 500px) {
        width: 40px;
        height: 40px;
      }
    }
    > span {
      text-transform: capitalize;
      font-size: 16px;
      font-family: ${BoldFont};
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
  
      @media (max-width: 500px) {
        font-size: 12px;
      }
    }
  }

  > button {
    height: 25px;
    width: 70px;
    border-radius: 4px;
    background-color: #159fc9;
    border: none;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-family: ${BoldFont};
    margin-left: 10px;

    &:last-child {
      border: 1px solid gray;
      color: gray;
      background-color: white;
    }

    @media (max-width: 500px) {
      width: 50px;
      font-size: 10px;
      padding: 0;
    }

    &:focus, &:active {
      outline: none;
    }
  }

  @media (max-width: 500px) {

    > span {
      width: calc(100% - 65px);
      text-align: left;
    }

    > button {
      &:last-child {
        margin-top: 10px;
      }
    }
  }
`;

const NoFriendRequests = styled.p`
  font-size: 14px;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgb(153,153,153);
  margin-bottom: 0;
`;

const CommentSection = styled.div`
  width: 100%;
  float: left;
  border-radius: 6px;
  padding: 10px;
  background: linear-gradient(180deg, #52ABED 0%, #8AD3F7 100%);
  >div:first-child{
    border-radius: 0;
    background: white;
    padding: 10px;
  }
`;

const ProfileContainer = styled.div`
  padding: 5px 60px;

  @media (max-width: 500px) {
    padding: 5px 0;
  }
`;

const AssessmentContainer = styled.div`
  background: linear-gradient(180deg, #37ED7D 0%, #129A8E 100%);
  width: 100%;
  font-family: ${BoldFont};
  display: block;
  color: white;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const TipInformation = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  padding: 23px 20px;
  cursor: pointer;
  > span {
    font-size: 12px;
    letter-spacing: 0px;
    color: rgb(255,255,255);
    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
`;

const InformationIcon = styled.div`
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: transparent;
  text-align: center;
  display: inline-block;
  margin-right: 9px;
  font-family: ${BoldFont};
`;

const WrapImage = styled.div`
  width: 100%;
  margin-top: ${({ noMargin }) => noMargin ? '0' : '20px'};
  float: left;
  // padding: 0px 25px 0;          
  cursor: pointer;
  display:flex !important;
  justify-content:center;
  height:100%;
  div{
    display:flex;
    justify-content:center;
  > img {
    // width: auto;
    // max-width: 100%
    // border-radius: 3px;;
    // float: left;
    // height: 426px;

    // width: 100%;
    // border-radius: 3px;
    // float: left;
    // height: 426px;
    // object-fit: cover;

    width: auto;
    max-width: 100%;
    border-radius: 3px;
    float: left;
    object-fit: cover;
    max-height:600px;
    
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  }
  > img {
    // width: auto;
    // max-width: 100%
    // border-radius: 3px;;
    // float: left;
    // height: 426px;

    // width: 100%;
    // border-radius: 3px;
    // float: left;
    // height: 426px;
    // object-fit: cover;

    width: auto;
    max-width: 100%;
    border-radius: 3px;
    float: left;
    
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog{
    display: flex;
    justify-content: center;
    transform: ${({ transform }) => transform && 'translate(0, 50vh) translate(0, -50%) !important'};
    .modal-content{
      width: 100%;
      border-radius: 10px;
      .modal-header {
        border-bottom: ${({ noBorder }) => noBorder ? noBorder : '1px solid #e5e5e5'};

        > div {
          display: flex;
          justify-content: center;

          > p{
            font-size: 20px;
            font-family: ${BoldFont};
            margin-top: 10px 0 0 0;
          }
        }
      }
      .modal-header .close {
        margin-top: -2px;
        outline: none;
      }
    }
  }
  @media (max-width: 500px) and (min-width: 320px) {
    .modal-dialog {
      width: 100%;

      .modal-content{
        width: 90%;
        margin-left: -6%;
      }
    }
  }
`;

const EmojiContainer = styled.div`
padding: 10px;
width: 100%;
display: ${({ length }) => length === 0 ? 'flex' : 'grid'};
gap: 10px;
grid-template-columns: repeat(2, 35%);
grid-template-rows: repeat(${({ length }) => length ? length : '3'}, 55px);
grid-auto-flow: row;
font-family: ${({ length }) => length === 0 ? `${BoldFont}` : ` ${RegularFont}`};
color: ${({ length }) => length === 0 ? '#a8a5a5' : 'none'};
font-size: ${({ length }) => length === 0 ? '16px' : '14px'};
justify-content: ${({ length }) => length === 0 ? 'center' : 'space-evenly'};
> div {
    font-family: ${RegularFont};
    cursor: pointer;
    float: left;
    display: grid;
    grid-template-columns: 20% 90%;
    gap: 10%;
    height: 50px;
    padding: 5px 5px 5px 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  }

  @media (max-width: 500px) and (min-width: 320px) {
    grid-template-columns: repeat(2, 55%);
    > div {
      width: 90%;
      margin-left: 10px;
      padding: 7px 5px 5px 5px;
      gap: 7%;
      grid-template-columns: 30% 90%;
    }
  }

`;

const Text = styled.p`
  margin: 10px 0 0px;
  font-family: ${RegularFont};

  @media (max-width: 500px) and (min-width: 320px) {
    font-size: 11px;
  }
`;
const ImageWrap = styled.div`
width: 40px;
height: 40px;
border-radius: 100%;
display: flex;
justify-content: center;
  > img {
    width: 50%;
    height: 50%;
    margin-top: 10px;
  }

  @media (max-width: 500px) and (min-width: 320px) {
    width: 35px;
    height: 35px;
  }
`;
const RecognitionSection = styled.div`
width: ${({ width }) => width ? width : '100%'};
display: flex;
height: ${({ height }) => height ? height : '50px'};
align-items: center;
padding: 0;
box-sizing: border-box;
border-radius: 0px 0px 6px 6px;
justify-content: ${({ display }) => display ? display : 'space-between'};
float: ${({ float }) => float};
> div: first-child {
  display: flex;
  > div {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    ${'' /* border: 1px solid #F3F3F3; */}
    border-radius: 4px;
    box-sizing: border-box;
    margin-right: 10px;
    cursor: pointer;
    > img {
      width: 24px;
      position: relative;
    }
    > input {
      opacity: 0;
      position: absolute;
      width: 25px;
      height: 25px;
    }
  }
}
> button {
  // width: 34px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  padding: 0 10px;
  // background: #F9FAFB;

  > img {
    width: 24px;
  }
}

@media(min-width: 1400px) {
  width: ${({ reply }) => reply ? '27%' : '100%'};
}
@media(min-width: 1500px) {
  width: ${({ reply }) => reply ? '25%' : '100%'};
}
`;
const AddBuddyContainer = styled.div`
min-width: 100px;
height: 40px;
margin: auto;
margin-right: 0px;
border-radius:6px;
cursor:pointer;
> button {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 6px;
  color: #005c87;
  background-color: transparent;
  border: 1px solid #005C87;
  font-size: 12px;
  line-height: 20px;
  font-family: 'Rubik-Medium';
  outline: none;
}
`;

const PendingButton = styled.div`
  width: 90px;
  height: 40px;
  background-color:#F1C977;
  background-color: ${({ background }) => background ? 'transparent' : '#F1C977'};
  outline: none;
  border: navajowhite;
  padding: 2px;
  border-radius: 6px;
	cursor: pointer;
	display: flex;
  justify-content: center;
  align-items: center;
  color: #005c87;
  font-size: 12px;
  font-family: 'Rubik-Medium';
  line-height: 20px;
  margin: auto;
  margin-right: 0px;
  padding-left:5px;
  border: 1px solid #005C87;
  cursor:pointer;
  >img{
    width:12px;
    height:12px;
    margin-right:5px;
  }
`

const RemoveBuddyContainer = styled.div`
cursor: pointer;
> img {
  width: 15px;
}
`;
const RemoveButtonContainer = styled.div`
padding-top: 15px;
width: 120px;
> button {
  height: 30px;
  width: 100%;
  outline: none;
  border: none;
  background-color: #FFF;
  color: #000000;
  font-size: 16px;
  line-height: 2px;
  font-family: 'Rubik';
  box-shadow: rgb(0 0 0 / 19%) 0px 2px 2px, rgb(0 0 0 / 7%) 0px 4px 6px;
  border-radius: 4px;
}
`;

const PendingButtonContainer = styled.div`
  padding-top: 15px;
  width: 35%;
  > button {
    height: 41px;
    width: 100%;
    outline: none;
    border: none;
    background-color: #FFF;
    color: #000000;
    font-size: 18px;
    line-height: 2px;
    font-family: 'Rubik';
    box-shadow: rgb(0 0 0 / 19%) 0px 2px 2px, rgb(0 0 0 / 7%) 0px 4px 6px;
    border-radius: 4px;
  }
`;


const TimeOfPost = styled.div`
letter-spacing: 0px;
padding: 0 45px 0 25px;
color: #005C8799;
font-size: 14px;
margin-top: 10px;
display:flex;
padding-bottom:25px;
`;
const EmojiContainerV2 = styled.div`
z-index: 1;
float: left;
position: relative;
display: flex;
align-items: center;
flex-wrap: wrap;
height: 100%;
width: 100%;
outline: none;
border: none;
color: #000000;
font-size: 18px;
line-height: 2px;
font-family: 'Rubik';
background: #FFFFFF;
box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.18);
border-radius: 0px 6px 6px 6px;
margin-top: 27px;
margin-left: -5px;
padding: 10px;
	> div {
		background: #F5F5F5;
		border-radius: 25px;
		margin: 10px;
		cursor: pointer;
		width: 45px;
    height: 45px;
    justify-content: center;
    display: flex;
		align-items: center;

		> img {
			width: 24px;
		}
	}
`;

const PhotoWrapper = styled.div`
  width: 33%;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-right: 1px solid #EAEAEA;
  >img{
    width: 32px;
  }
  >p{
    margin: 0 0 0 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #3E3F42;
    line-height: 12px;
  }
`;

const ButtonWrapper = styled.div`
    height: 60px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 4%);
    border-radius: 0px 0px 6px 6px;
    background: #F7F7F7;
    width: 100%;
    float: left;
    display: flex;
`;

const PostWrapper = styled.div`
  width: 33%;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-right: 1px solid #EAEAEA;
  border-radius: 0 0 0 6px;
  >img{
    width: 30px;
    height: 30px;
  }
  >p{
    margin: 0 0 0 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #3E3F42;
    line-height: 12px;
  }
`;

const RecognitionWrapper = styled.div`
  width: 34%;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-radius: 0 0 6px 0px;
  >img{
    width: 30px;
    height: 30px;
  }
  >p{
    margin: 0 0 0 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #3E3F42;
    line-height: 18px;
  }
`;

const LinkContainer = styled.div`
    width: 100%;
    height: 55px;
    float: left;
    margin-bottom: 35px;
    background: rgb(138, 211, 247);
    border-radius: 0 0 6px 6px;
    padding: 10px;
`;

const Link = styled.div`
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: flex;
    padding: 0 10px;
`;

const LinkTab = styled.div`
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    line-height: 21px;
    text-align: center;
    color: ${({ active }) => active ? '#005c87' : '#8B8B8B'};
    border-bottom : ${({ active }) => active ? '3px solid #0D4270' : 'none'};
    font-family: ${MediumFont};
    font-style: normal;
    font-weight: normal;
`;

const PictureDiv = styled.div`
  width: 10%;
  >img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;

const MainSend = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 10px;
  >img{
    width: 42px;
    height: 42px;
  }
`;

const BottomSection = styled.div`
  width: 100%;
  padding: 0px !important;
  display : flex;
  .imageContainer {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    >img{
      width: 100%;
      max-width: 450px;
      height: auto;
    }
    .remove{
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
`;

const LeftContent = styled.div`
    width: 100%;
    etter-spacing: 0px;
    line-height: 24px;
    color: rgb(51,51,51);
    font-family: "Rubik-Regular";
    > span.title {
      font-size: 18px; 
      color: #005c87;     
    }
    >div{
      text-align: left;
      margin-top: 0;
      color: rgb(153,153,153);
      text-transform: capitalize;
      >textarea{
        padding: 0px;
        resize: none;
        width: 100%;
        font-size: 16px;
        border: none;
        // height: 65px;
        color: #005c87;
        overflow-y:hidden;
        ::placeholder {
          color: #005C874D !important;
        } 
    
        :-ms-input-placeholder {
          color: #005C874D !important;
        }
    
        ::-ms-input-placeholder {
          color: #005C874D !important;
        } 

        &:focus-visible {
          border: none;
          outline: none;
        }
      }
    }
`

const LikedUser = styled.div`
  display: flex;
`

const Text2 = styled.div`
  font-size: 16px;
  line-height:20px;
  color: #005c87;
  font-family:"Rubik-Medium";
  .and{
    font-size: 16px;
    line-height:20px;
    color: #005c87;
    font-family:"Rubik";
  }
`

const SelectedImage = styled.div`
>img{
  width: 150px;
}
`

const FeedTab = styled.div`
  background: #F8F8F8;
  border: 0.1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;
  background: ${({ background }) => background ? background : '#F8F8F8'};
`

const LikeButton = styled.div`
  padding: 10px;
  justify-content: center;
  display: flex;
  width: 33%;
  align-items: center;
  cursor: pointer;
  >div:first-child{
    >img{
      height: 32px;
      width: 32px
    }
  }

  >div:last-child{
    padding-left: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #3A3A3A;
  }
`

const CommentTabButton = styled.div`
  padding: 10px;
  // border: 0.1px solid #EAEAEA;
  // border-top: none;
  // border-bottom: none;
  justify-content: center;
  display: flex;
  width: 33%;
  align-items: center;
  cursor: pointer;
  >div:first-child{
    >img{
      height: 32px;
      width: 32px
    }
  }

  >div:last-child{
    padding-left: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #3A3A3A;
  }  
`

const RecognitionButton = styled.div`
  padding: 10px;
  justify-content: center;
  display: flex;
  width: 34%;
  align-items: center;
  cursor: pointer;
  border: 0.1px solid #EAEAEA;
  border-top: none;
  border-bottom: none;
  >div:first-child{
    >img{
      height: 32px;
      width: 32px
    }
  }

  >div:last-child{
    padding-left: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #3A3A3A;
  }
`

const FieldContainer = styled.div`
padding: 0;
border: 1px solid #EAEAEA;
border-top: ${({ borderTop, borderBottom }) => borderBottom?"none !important": borderTop ? '0px' : '1px solid #EAEAEA'};
border-bottom: ${({ borderTop, borderBottom }) => borderBottom?"none !important": borderTop ? '1px solid rgba(156, 156, 156, 0.3)' : '1px solid #EAEAEA'};
box-sizing: border-box;
border-radius: 0px;
float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
width:${({ widthValue }) => widthValue ? '100%' : '100%'};
// position: relative;
padding-bottom:${({ paddingBottom }) => paddingBottom ? '25px' : ''};
bottom: 0px;
position:${({ position, positionValue }) => positionValue?positionValue: position ? 'fixed' : 'relative'};
bottom:${({ bottom }) => bottom ? '0px' : ''};
border-radius:${({ radius }) => radius ? '0 0 6px 0px' : '0 0 6px 6px'};
background:${({ bottom }) => bottom ? 'white' : ''};
${({ noFloat }) => noFloat && css`
  padding: 34px 0 20px 0;
  @media (max-width: 500px) {
    padding: 0;
  }
`}

>div:first-child{
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom ? '0px' : '10px'};
  // margin-top: 25px;
  // justify-content: space-around;
  
  >div:first-child{
    width: 50px;
    height: 50px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    >img{
      // background: url(Image.png), url(Image.png), url(.png), url(Image.png), #9c9c9c;
      background-blend-mode: normal, normal, normal, normal, normal;
      border-radius: 3px;
      height: 50px;
      width: 50px;
      border-radius:50px;
    }
  }
  >div:nth-child(2){
    width: 100%;
    padding-left: 0;
    // border: none;
    // border: 1px solid #9C9C9C;
    border:none;
    border-radius: 6px !important;
    // background: rgba(156, 156, 156, 0.05);
    background:transparent;
    >div:first-child{
      float: left;
      width: 100%;
      border: 1px solid #005C874D;
      border-radius: 20px;
      margin-right:10px;
      >div:first-child{
        width: 100%;
      }
      >div:last-child{
        width: 100%;
        >div:first-child{
          width: ${({ width }) => width ? '69%' : '100%'};
          width: ${({ isCommentOrReply }) => isCommentOrReply && "100%"};

          
          >textarea{
            padding: 15px 15px;
            resize: none;
          }
        }
      }
    }
  }

  >div:last-child{
    justify-content: center;
    display: flex;
    align-items: center;
    >div:first-child{
      >img{
        width: 32px;
      }
    }

    >div:last-child{
      margin-left: 25px;
      margin-left: ${({ isCommentOrReply }) => isCommentOrReply && "0px"};
      display: flex;
      width: 26%
      padding-right: 8px
      >img{
        width: 32px;
        height: 32px;
      }
    }
  }

}

>div:last-child{
  display : none;
}

> span: first-child {
  display: none;
}
> span {
  font-size: 24px;
}

&:last-child {
  border:none;
  border-bottom: 1px solid #EAEAEA;
  border-bottom: ${({ borderTop }) => borderTop ? '1px solid rgba(156, 156, 156, 0.3)' : '1px solid #EAEAEA'};
}

${(props) => props.fixHeight && css`
  border-bottom: none;
`}

${({ socialPage }) => socialPage && css`
  > span {
    font-size: 18px;
    float: left;
    padding: 20px 0 10px;
  }
`}

@media (max-width: 500px) {
  padding: 10px;
}
`;

const Option = styled.div`
margin-bottom: 5px;
    margin-top: auto;
  >div{
    cursor: pointer;
    margin-right: 6px;
  }
`

const RecognitionText = styled.div`
  width: 100%;
  padding: 0px 0px 0;
  padding-left: ${({ padding }) => padding ? padding : '25px'};
  .first-span{
    font-family: Rubik !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #005c87; 
    >img{
      width: 20px;
      height: 20px;
      margin: 0 8px 9px;
    }
    .recognition-name{
      font-family: rubik-medium;
    }
  }

  .second-span{
    font-family: Rubik-Medium !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: #005c87; 
    margin-left: 5px;
    >span span{
      font-family: Rubik !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: justify;
      color: #3E3F42;
      margin-left: 5px;
    }
  }
  .CommentDescription {
    display: block;
    width: 100%;
    word-break: break-word;
    text-transform: none !important;
    text-align: justify;
    font-size: 16px;
    line-height: 18px;
    color: #3A3A3A;
    font-family: Rubik-Regular !important;
    word-break: break-word;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
`

const ProfileImg = styled.div`
>img{
    width: 28px;
    height: 28px;
    margin: ${({ margin }) => margin};
    border-radius: 50%;
}`

const ConnectDevice = styled.div`
width: 100%;
height: 50px;
margin-bottom: 25px;
> button {
  width: 100%;
  height: 100%;
  font-family: ${BoldFont};
  font-size: 18px;
  background-color: rgb(253,113,117);
  color: #FFF;
  padding: 4px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: stretch;
  outline: none;
  > img {
    width: 15%;
    height: 100%;
    margin-right: 12px;
    float: left;
  }
  > div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 14%;
    > span {
    }
  }
}
@media(max-width: 766px){
  > button {
    > img {
      width: 8%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-left: 0%;
    }
  }
}
@media(max-width: 500px){
  > button {
    > img {
      width: 12%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-right: 25%;
      padding-left: 14%;
    }
  }
}
`;
const CommentContent = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;

  height: ${({ height,h1, heightValue }) =>heightValue?"auto": height ? '150px' :h1?"auto": '50px'};
div{
  div{
    div{
      img{
        /* margin:10px */
      }
    }
  }
}

 padding-bottom: ${({ height }) => height && "20px"};
  
  > img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    float: left;
    border-radius: 50%;
    
    @media (max-width: 500px) {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
  
  ${(props) => props.isReply && css`
    padding: 0 70px;
  `}

  .ImageDiv {
    position: relative;
    right: -82px;
    top: -52px;
    >img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      float: left;
      border-radius: 50%;
    }
  }

  .InputImageSection {
    float: left;
    left: 80px;
    top: -125px;
    >img {
      width: 100px;
      height: 100px;
      margin-right: 10px;
      float: left;
    }
  }

  .InputImageSpan {
    color: #333339;
  }

  >div:nth-child(2){
    padding: 0 0 0 15px;
    padding-bottom: ${({ height }) => height && '15px'}
    >div{
      width: 100%;
    }
  }
`;

const CommentDiv = styled.div`
  padding: 20px;
  float: left;
  width: 100%;
  >div{
    cursor:pointer;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
    text-align: justify;
    color: #3A3A3A;
  }
`

const CommentSectionNew = styled.div`
  width: 100%;
  float: left;
  border-radius: 6px;
  // padding: 8px;
  background: white;
  // border: 1px solid rgba(156, 156, 156, 0.3);
  >div:first-child{
    border-radius: 0;
    background: white;
    padding: 8px;
    border-bottom: 1px solid #005C871A;
  }
  >div:last-child{
    padding: 8px 10px;
  }
`;

const InputFieldContainerNew = styled.div`
  width: ${({ UpdateStatus, survey, width }) => UpdateStatus || survey || width ? '100%' : 'calc(100% - 58px)'};
  float: left;
  display: flex;
  // background: white;
  // -moz-box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  // -webkit-box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  // box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  // height: 'auto';
  border-color: ${({ comment, survey }) => comment ? 'rgba(0, 0, 0, 0.09)' : survey ? '#cdcbcb' : 'none'};
  border-image: ${({ comment }) => comment ? 'initial' : 'none'};
  // padding: ${({ padding }) => padding ? padding : '15px'};
  border-radius: ${({ comment }) => comment ? '0px' : '6px'};
  border-width: ${({ comment, survey }) => comment || survey ? '1px' : '0px'};
  border-style: ${({ comment, survey }) => comment || survey ? 'solid' : 'none'};
  // height: ${({ height }) => height};
  flex-direction: ${({ flex }) => flex && 'row-reverse'};
  cursor: pointer;
  ${({ height }) => height && css`
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 6px 6px 0px 0px !important;
  `}

  >div:nth-child(2){
    width: calc(100% - 60px);
    border-radius: 6px;
    border: 1px solid #005C874D;
    border-radius:40px;
  }
  @media (max-width: 500px) {
    width: ${({ UpdateStatus }) => UpdateStatus ? '100%' : 'calc(100% - 50px)'};
  }
  .CustomTextAreaField {
    ${'' /* padding-top: 15px; */}
    line-height: 20px;
    height: 50px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    // width: calc(100% - 4px);
    width: 100%;
    color: #005c87;
    resize: none;
    float: left;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #005c87;
      opacity: 1;
    } 

    :-ms-input-placeholder {
      color: #005c87;
    }

    ::-ms-input-placeholder {
      color: #005c87;
    }  
  }
  
  .CustomTextAreaField2 {
    padding: 15px;
    // padding-top: 12px;
    line-height: 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    width: calc(100% - 4px);
    color: #005c87;
    resize: none;
    float: left;
    height: 50px;
    position: absolute;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #005C8799;
      opacity: 1;
      font-size: 16px;
      font-family: Rubik-Regular;
    }

    :-ms-input-placeholder {
      color: #005C8799;
      font-size: 16px;
      font-family: Rubik-Regular;
    }

    ::-ms-input-placeholder {
      color: #005C8799;
      font-size: 16px;
      font-family: Rubik-Regular;
    }
  }
`;

const PictureDivNew = styled.div`
  width: 50px;
  margin-right: 10px;
  >img{
    width: 50px;
    height: 50px;
    border-radius: 3px;
    border-radius:50%;
  }
`;

const InputTextAreaNew = styled.div`
  position: relative;
  // height: ${({ isCommentOrReply, height, peer }) => peer ? '100%' : height ? '100px' : isCommentOrReply ? '50px' : '100px'};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  display:flex;
  align-items:center;
  >div{
    display:flex;
    width:100%;
  }
  >textarea{
    width: 100%;
    display: flex;
    resize: none;
    border: ${({ border }) => border};
    padding-top: ${({ border }) => border && '2px'};
    // height: ${({ border }) => border && '20px'};
    font-size: 16px;
    color: #005c87;
    resize:none;
    border: none;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    color: #005c87;
    resize: none;
    margin: 24px 0px;
    &:focus, &:active, &:hover, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
    
    &::placeholder {
      color: #005C874D;
    }

    &:-ms-input-placeholder {
      color: #005C874D;
    }

    &::-ms-input-placeholder {
      color: #005C874D;
    }
  }
`;

const ButtonWrapperNew = styled.div`
    height: 60px;
    border-radius: 0px 0px 6px 6px;
    background: white;
    width: 100%;
    float: left;
    display: flex;
    padding: 0px 30px;
`;

const PostWrapperNew = styled.div`
width: 33.33%;
align-items: center;
display: flex;
justify-content: center;
cursor: pointer;
// border-right: 1px solid #9c9c9c;
border-right: ${({ border }) => border ? '1px solid #005C871A' : ''};
border-radius: 0 0 0 6px;
padding: 0px 8px;
input{
  opacity: 0;
  z-index: 0;
  position: absolute !important;
  width:200px !important;
  height:20px !important;
  display:block !important;
  &:focus, &:active, &:hover, &:focus-visible {
    background: #005C870D;
  }
  cursor:pointer;
}
>div{
  display: flex;
  justify-content: center;
  width: 100%;
  // background: rgba(156, 156, 156, 0.1);
  padding: 10px 0px;
  align-items: center;
  border-radius: 6px;
  &:focus, &:active, &:hover, &:focus-visible {
    background: #005C870D;
  }
  div{
    >img{
    width: 24px;
    height: 24px;
  }

  }
  >img{
    width: 24px;
    height: 24px;
  }

  >p{
    margin: 0 0 0 10px;
    font-family: rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #005C87;
    line-height: 12px;
    // width: ${({ width }) => width ? '63%' : ''};
    line-height: 20px;
  }
}
`;

const PhotoWrapperNew = styled.div`
  width: 33%;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-right: 1px solid #9c9c9c;
  margin: 0px 20px;
  >img{
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  >p{
    margin: 0 0 0 10px;
    font-family: rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #005c87;
    line-height: 12px;
  }
`;

const RecognitionWrapperNew = styled.div`
  width: 34%;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-radius: 0 0 6px 0px;
  margin: 0px 20px
  >img{
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  >p{
    margin: 0 0 0 10px;
    font-family: rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #005c87;
    line-height: 12px;
  }
`;

const LikeButtonew = styled.div`
  // padding: 10px;
  justify-content: center;
  display: flex;
  width: ${({ width }) => width ? '50%' : '33.33%'};
  align-items: center;
  cursor: pointer;
  margin: 20px 0px;
  border-right: ${({ border }) => border ? '1px solid #005C871A' : ''};
  >div:first-child{
    width:100%;
    margin: 0px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    border-radius: 6px;
    &:focus, &:active, &:hover, &:focus-visible {
      background: #005C870D;
    }
  >div:first-child{
    >img{
      height: 24px;
      width: 24px
    }
  }

  >div:last-child{
    padding-left: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #005C87;
  }
}
`
const Border = styled.div`
  width: 100%;
  height: 1px;
  background: #9C9C9C;
  margin-bottom: 25px;
}
`

const MainSendNew = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
  >img{
    width: 42px;
    height: 42px;
  }
`;

const FeedTabNew = styled.div`
  background: #F8F8F8;
  border-top: 1px solid #005C871A;
  border-top: ${({ border }) => border ? "none" : '1px solid #005C871A'};
  border-bottom: 1px solid #005C871A;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;
  background: ${({ background }) => background ? background : '#F8F8F8'};
  width: 100%;
  margin-top: ${({ border }) => border ? "0px" : '25px'};
  `;

const FirstCommentButton = styled.div`
  width: 100%;
  background: #005C870D;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: none;
  text-align: center;
  color: #005C87;
  height:33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
`;

const CoreValueMain = styled.div`
  background: #FFF;
  width: 100%;
  border-radius:6px;
  display:flex;
  flex-wrap:wrap;
  padding:15px;
  margin-bottom:30px;
  .main{
    font-family: Rubik-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #005C87;
    margin-bottom:15px;
  }
  `;

const CoreValue = styled.div`
  background: #005C870D;
  width: 100%;
  border-radius:6px;
  display:flex;
  flex-wrap:wrap;
  padding:10px;
  margin-bottom: ${({ margin }) => margin ? '0' : '5px'};
  .image{
    width:40px;
    height:40px;
    background:white;
    border-radius:6px;
    display:flex;
    justify-content: center;
    margin:auto 0;
    >img{
      width:auto;
      height:24px;
      margin:auto;
    }
  }
  .info{
    width: 22px;
    height:22px;
    display:flex;
    margin:auto;
    margin-right: 0px;
    cursor:pointer;
    >img{
      width: 22px;
      height:22px;
    }
  }
  .values{
    width: calc(100% - 62px);
    display:flex;
    flex-wrap:wrap;
    // padding-left:15px;
    // margin:0 auto 0 auto;
    .main{
      width:100%;
      display:flex;
      flex-wrap:wrap;
      padding-left:10px;
      margin:0 auto 0 auto !important;
    .name{
      font-family: Rubik-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: #005C87;
      width:100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .button{
      width:65px;
      height:30px;
      display:flex;
      background:transparent;
      border: 1px solid #005C8799;
      font-family: Rubik-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      color: #005C8799;
      justify-content:center;
      align-items:center;
      border-radius:3px;
      margin-top:5px;
      cursor:pointer;
    }
    .points{
      font-family: Rubik;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #005C8799;
      margin: auto;
      margin-right: 0px;
    }
  }
  
  .description{
    font-family: Rubik;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #005C8799;
    display:flex;
    width:100%;
    padding-top:5px;
    padding-left: 15px;
  }}
  `;  

const BirthdayImage = styled.div`
 width:100%;
 height:160px;
 display:flex;
 background-repeat: no-repeat;
 background-size: 100% 100%;
 background-image:  ${({ birthday }) => birthday ? `url(${ImageUrl}/social-feed/birthdayPopup.png)` :  `url(${ImageUrl}/social-feed/anniversoryPopup.png)`};
 .firstContainer{
  width:20%;
  display:flex;
 .count{
  min-width:95px;
  height:50px;
  background:#FFFFFF;
  border-radius:29px;
  display:flex;
  margin:auto;
  margin-right:0px;
  font-family: Rubik-Medium;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color:#005c87;
  justify-content: center;
  align-items: center;
 }}
 .wishContainer{
  display:block;
  flex-wrap:wrap;
  margin-left:15px;
  .birthday{
    margin-top:50px;
    font-family: Great Vibes;
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    color:#005C87;
  }
  .subtext{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color:#FFF;
  }
 }
`; 

const BirthdayWrapper = styled.div`
 width:100%;
 display: ${({ display }) => display ? display : 'flex'};
 flex-direction: ${({ flex }) => flex ? "column" : 'row'};
 flex-wrap: ${({ flex }) => flex ? "nowrap" : 'wrap'};
 padding-bottom: 25px;
 height: 416px;
 overflow-x: hidden;
 overflow-y: auto;
 padding: 0px 24px 24px 24px;
 display:block;
 .title{
  color: #005C87;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin:0px 0 12px 0;
 }
 .birthdayWrapper{
  display:flex;
  width:100%;
  padding: ${({ padding }) => padding ? padding : '0px'};
  flex-wrap:wrap;
  gap:24px;
  margin-bottom:20px;
 }
 ::-webkit-scrollbar {
  width: 7px;
  height: 45px;
}
::-webkit-scrollbar-thumb {
  background: #005C87;
  cursor: pointer;
  border-radius: 2.5px;
}

::-webkit-scrollbar-track {
  background: #005C874D;
  border-radius: 2.5px;
}
`; 

const BirthdayCard = styled.div`
display:flex;
width:calc((100% - 15px) / 2);
border: 1px solid #005C8799;
background: #F7F5EB;
padding: 15px 10px 15px 15px;
flex-wrap:wrap;
max-height:97px;
.image{
  width:60px;
  height:60px;
  border-radius:6px;
  >img{
    width:60px;
    height:60px;
    border-radius:6px;
  }
}
.details{
  display:block;
  margin:auto;
  flex-wrap:wrap;
  width:150px;
  padding-left:15px;
  .name{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color:#005C87;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
  }
  .date{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color:#005C87;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
  }
}
.button{
  display:flex;
  margin:auto;
  margin-right:0;
  border-radius:6px;
  cursor: ${({ disable }) => disable ? "not-allowed" : 'pointer'};
  background: ${({ disable }) => disable ? "#005C874D" : '#005C87'};
  font-family: Rubik-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color:#fff;
  align-items:center;
  text-align:center;
  justify-content:center;
  width:100px;
  height: 40px;
}
`; 

const BirthdayFeed = styled.div`
 width:100%;
 display:flex;
 background-repeat: no-repeat;
 background-size: 100% 100%;
//  background-image:  ${({ birthday }) => birthday ? `url(${ImageUrl}/social-feed/birthday_feed.png)` :  `url(${ImageUrl}/social-feed/work_anniversary_feed.png)`};
 background-image:  ${({ birthday }) => birthday ? `url(${ImageUrl}/social-feed/birthdayBackground.png)` :  `url(${ImageUrl}/social-feed/anniversaryBackground.png)`};
 flex-wrap:wrap;
 margin-bottom:20px;

 .firstContainer{
  width:100%;
  display:flex;
 .count{
  width:95px;
  height:50px;
  background:#FFFFFF;
  border-radius:29px;
  display:flex;
  font-family: Rubik-Medium;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  color:#005c87;
  justify-content: center;
  align-items: center;
  margin: 65px 25px 50px 60px; 
 }
.birthday{
  font-family: Great Vibes;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #005C87;
  display:flex;
  margin:65px 0px 0px 0;
  }
}
.bottomWrapper{
  display:flex;
  width:100%;
  flex-wrap:wrap;
  padding: 0 35px 25px 35px;
  .subText{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color:white;
  }
  .button{
    width:275px;
    height:40px;
    background:#005C87;
    border-radius:6px;
    cursor:pointer;
    font-family: Rubik-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color:white;
    display:flex;
    margin:auto;
    justify-content:center;
    align-items:center;
    margin-right: 0px;
  }
}

`; 

const DailyTipCard = styled.div`
  width:100%;
  display:flex;
  flex-wrap:wrap;
  margin-bottom:20px;
  border-radius:6px;
  margin-top: ${({margin}) => margin ? margin : `0px`};
  .heading{
    width:100%;
    display:flex;
    justify-content:center;
    padding:15px;
    background:white;
    border-radius: 6px 6px 0 0;
    .title{
      font-family: Rubik-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color:#005c87;
    }
    .date{
      font-family: Rubik-Medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color:#005C8799;
    }
  }
  .dailyTipImage{
    width:100%;
    height: 320px;
    display:flex;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFF;
    justify-content:center;
    align-items:center;
    background-image: ${({ image }) => image ? `url(${ImageUrl}/${image})` :  `url(${ImageUrl}/${image})`};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding:20px;
    border-radius: 0px 0px 6px 6px;
  }
`; 

const SendButton = styled.div`
  width: 74px !important;
  height: 46px !important;
  border-radius:25px !important;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  background: ${({ disabled }) => disabled ? '#005C874D !important' : '#005C87 !important'};
  display:flex;
  justify-content:center;
  align-items:center;
  margin-left:10px;
  >img{
    width:24px;
    height:24px;
  }
`; 

const ShowLikeComment = styled.div`
  width: ${({width}) => width ? width : `50%`};
  display:flex;
  // position:fixed;
  bottom:100px;
  bottom: ${({bottom}) => bottom ? bottom : `75px`};
  padding: 15px 25px;
  background:white;
  border-bottom: 1px solid #005C871A;
  .wrapImage{
    display:flex;
    margin-right: ${({margin}) => margin ? margin : `45px`};
    >img{
      width:24px;
      height:24px;
      margin-right:10px;
      cursor:pointer;
    }
    .text{
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color:#005c87;
      align-items: center;
      display: flex;
    }
  }
`; 

const PostCommentsWrapper = styled.div`
width:100%;
display:block;
overflow-y: scroll;
overflow-x: hideen;
background:#FFF;
height:100%;
padding:16px;
border-radius: 0 0 6px 0;
::-webkit-scrollbar {
  width: 7px;
  height: 45px;
}
::-webkit-scrollbar-thumb {
  background: #005C87;
  cursor: pointer;
  border-radius: 2.5px;
}

::-webkit-scrollbar-track {
  background: #005C871A;
  border-radius: 2.5px;
}

`;

const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: ${({marginTopValue}) => marginTopValue ? marginTopValue : '0px'};
  .slick-slider {
    width: 100%;
    // margin: ${({marginValue}) => marginValue ? marginValue : '20px 0 10px'};
    padding: ${({padding}) => padding ? padding : ''};
    box-shadow: ${({ shadow }) => (shadow ? "" : "")};
    .slick-slide{
      width: 568px;
      max-height: ${({ maxHeight }) => (maxHeight ? "650px" : "650px")};
      margin: 0px;
      display: flex;
      justify-content: center;
      display: ${({display}) => display ? display : 'block'};
      justify-content: ${({content}) => content ? content : ''};
      margin: ${({margin}) => margin ? margin : ''};
      >div{
        width:100%;
      }
      @media (max-width: 600px){
        margin: 0 auto;
      }
    }
    .slick-prev {
      left: 0px;
      position: absolute;
      top: 50%;
      right: -52px;
      z-index: 1;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 1.375rem;
      margin-left:24px;
    }
    .slick-prev:before {
      font-family: "Rubik";
      font-size: 42px;
      line-height: 1;
      color: white;
      margin: 0 15px;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: left .3s ease;
      }
      .slick-prev:before {
        content: "‹";
      }
      [dir="rtl"] .slick-prev:before {
        content: "›";
      }
    .slick-next {
        left: unset;
        position: absolute;
        top: 50%;
        right: 0px;
        z-index: 1;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 1.375rem;
        margin-right: 24px;
      }
      .slick-next:before {
        content: "›";
        font-family: "Rubik";
        font-size: 42px;
        line-height: 1;
        color: white;
        margin: 0 18px;
      }
      [dir="rtl"] .slick-next:before {
        content: "‹";
      }
  }
`;

const RecognitionImage = styled.div`
  width:100%;
  background-image: ${({ image }) => image ? `url(${ImageUrl}/${image})` :  `url(${ImageUrl}/${image})`};
  height: ${({height}) => height ? height : '650px'};
  display:flex;
  flex-wrap:wrap;
  margin-top: ${({margin}) => margin ? "0px" : '20px'};
  background-size: ${({background}) => background ? background : '100% 650px'};
  border-radius: ${({radius}) => radius ? "6px 0 0 6px" : '0px'};
  .mainContainer{
    width:100%;
    // background-image: ${({ image }) => image ? `url(${ImageUrl}/${image})` :  `url(${ImageUrl}/${image})`};
    height: ${({height}) => height ? height : '650px'};
    display:flex;
    flex-wrap:wrap;
    cursor:pointer;
  .image{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    margin:auto;
    .imageWidth{
      width:240px;
      height:240px;
      display:flex;
      background:#FFF;
      border-radius:50%;
      margin:0 auto;
      object-fit: cover;
    >img{
      width:100%;
      height:100%;
      display:flex;
      margin:auto;
      border-radius:50%;
    }
  }
  .coreImageWidth{
    width:200px;
    height:200px;
    display:flex;
    background:#FFF;
    border-radius:50%;
    margin:0 auto;
  >img{
    width:100px;
    height:100px;
    display:flex;
    margin:auto;
  }}
  .recogntionValue{
    height:100%;
    display:flex;
    flex-wrap:wrap;
    position:relative;
    width: 0px;
    .wrap{
      width:120px;
      height:120px;
      display:flex;
      flex-wrap:wrap;
      position:relative;
      left: -225px;
      top: 155px;
      justify-content: center;
      >svg{
        width:120px;
        height:120px;
      }
    .icon{
      height:100%;
      display:flex;
      justify-content:center;
      position:absolute;
      width:80px;
      padding-top:30px;
      >img{
        height:24px;
        width:24px;
      }
    }
    .name{
      padding-top:55px;
      width:71px;
      height:100%;
      display:flex;
      position:absolute;
      justify-content:center;
      color: #005C87;
      text-align: center;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      align-items:center;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 0px;
      height: auto;
      margin: auto;
      display: flex;
      >div{
        display: block;
        width:100%;
      }
      >span{
        margin-right:4px;
      }
    }}
  }
  .recognition{
    margin-top:40px;
    width: 100%;
    text-align:center;
    padding:0 15px;
    .textRecognition{
      font-family: Rubik;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      color:white;
      padding-right:5px;
    }
    .boldRecognition{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      color:white;
      padding-right:5px;
    }
    >svg{
      position: relative;
      bottom: 2px;
      height:24px;
      width:24px;
    }
  }
  }

}
`;

const InputTextAreaComment = styled.div`
  position: relative;
  padding: ${({ padding }) => padding};
  width:300px !important;
  >textarea{
    width: 100%;
    display: flex;
    resize: none;
    border:none;
    border-bottom: 1px solid #005C874D;
    padding-top: ${({ border }) => border && '2px'};
    height: 36px;
    font-size: 16px;
    color: #005C8799 !important;
    padding-top:5px;
    padding-left:10px;
    &:focus, &:active, &:hover, &:focus-visible {
      border:none;
      border-bottom: 1px solid #005C874D;
      outline: none !important;
    }
    
    &::placeholder {
      color: #005C8799 !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #005C8799 !important;
    }

    &::-ms-input-placeholder {
      color: #005C8799 !important;
    }
  }
`;

const CoreValueSideBarContainer = styled.div`
  width: ${({ width }) => width?width: 'auto'};
  max-width: 320px;
  float: left;
`;

const MainWrapper = styled.div`
  width:100%;
  padding: 25px;
  height:auto;
  // min-height:650px;
  height:1600px;
  ${'' /* overflow: scroll; */}
  display: flex;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  .mainDiv{
    width: 100%;
    max-width: 1246px;
    margin: auto;
    display: flex;
    justify-content:space-between;
  }
`;

const RecognitonNameWrapper = styled.div`
width:100%;
display:flex;
margin-bottom:25px;
.icon{
  width:100px;
  height:100px;
  background: #005C870D;
  border-radius: 6px;
  margin-right:30px;
  display:flex;
  >img{
    height:50px;
    width:50px;
    display:flex;
    margin:auto;
  }
}
.nameWrapper{
  width:calc(100% - 130px);
  display:block;
  margin: auto;
  .name{
    width:100%;
    font-family: Rubik-Medium;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color:#005c87;
  }
  .title{
    width:100%;
    font-family: Rubik-Medium;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color:#005c87;
    border: 1px solid #005C8799;
    background: #9C9C9C0D;
    border-radius:6px;
    padding:15px;
    margin-top:15px;
  }
}
`;

const RecognitonDescription = styled.div`
  width:100%;
  display:flex;
  flex-wrap:wrap;
  .title{
    font-family: Rubik-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color:#005c87;
    margin-bottom:5px;
  }
  .description{
    width:100%;
    height:145px;
    background:#005C870D;
    padding: 15px;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color:#005c87;
    border-radius:6px;
  }
  .noData{
    width:100%;
    display:flex;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #005C8799;
    height:145px;
    margin-top:10px;
  }

`;

const ActivityDropdown = styled(DropdownButton)`
  // padding: ${({paddingValue}) => paddingValue? paddingValue : '0px 20px'};
  width: ${({widthValue}) =>widthValue? widthValue : '100%'};
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: ${({height}) =>height? height : '60px'};
  color: ${({notActive}) =>notActive? '#005C8799' : '#005C87'};
  font-size:16px;
  font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik-medium'};
  border: 1px solid #005C874D;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background:rgba(156,156,156,.04);
  border-radius:6px;
  >button{
    border: none;
    background: white;
    color: #005c87;
    width: 135px;
    text-align: left;
    ::after{
      display:none;
    }
    &:hover{
      background: white;
      text-transform: capitalize;
      height: ${({height}) =>height? height : '60px'};
      color: ${({notActive}) =>notActive? '#005C8799' : '#005C87'};
      font-size:16px;
      font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik-medium'};
    }
  }
  .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    background:white;
    text-transform: capitalize;
    height: ${({height}) =>height? height : '60px'};
    color: ${({notActive}) =>notActive? '#005C8799' : '#005C87'};
    font-size:16px;
    font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik-medium'};
  }
  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled){
    text-transform: capitalize;
  }
  >span{
    display:none
  }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #005C87;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #005C874D;
  }
  &:hover{
    background:rgba(156,156,156,.04);
    color: #005C87;
    border:1px solid #005C874D;
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    border: 1px solid #005C874D;
  }
  .btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    color:#005C87;
  }
`;

const CustomMenuItem = styled(DropdownItem)`
  width: 100% !important;
  text-transform: capitalize;
  padding: 5px 0;
  border-bottom: 1px solid #005C871A;
  cursor:${({ disabled }) => disabled ? "not-allowed" : 'pointer'};
  &:last-child{
    border:none;
  }
  > a {
    width:100%;
    display: flex;
    align-items: center;
    display: flex;
    padding-left: 15px;
    color:#005C87;
    >img{
      width:16px;
      height:16px;
      display: flex;
      margin: auto;
      margin-right: 15px;
      display:none;
    }
    &:active, &:hover, &:focus {
      outline: none;
      color:#005C87!important;
      background-color:#005C870D!important;
      border-radius:6px !important;
      font-family:"Rubik-Medium"!important;
      font-size:14px;
      line-height:20px;
      >img{
        width:16px;
        height:16px;
        display: flex;
        margin: auto;
        margin-right: 15px;
      }
    }
  }
`;

const TitleContainer = styled.div`
  float: left;
  width: ${({ width }) => width ? width : '100%'};
  padding: ${({ padding }) => padding ? padding : '0px 0px 0px 0px'};
  margin: ${({ margin }) => margin};
  position: relative;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background:white;
  border-radius:6px;
  .dropdown-item{
    color: #005c87;
    background:white;
    &:hover, &:active, &:focus {
      color: #005c87;
      background:white;
    }
  }
  .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    background:#F7F5EB80;
    color:#005C87;
    border: 1px solid #005C874D;
    background:rgba(156,156,156,.04);
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    background:#F7F5EB80;
    border: 1px solid #005C874D;
  }
  > div {
    float: left;
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }
  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    display: ${({ display }) => display ? display : ""};
    width:${({ fullWidth, widthValue }) => widthValue ? widthValue : fullWidth ? "100%" : "50%"}
    height: ${({ height }) => height ? height : '200px'};
    overflow-y: auto;
    position:absolute;
    position: ${({ position }) => position ? "relative" : "absolute"};
    padding: 0 15px;
    border: 1px solid #005C874D;
    padding:5px 15px;
    ::-webkit-scrollbar {
      width: 7px;
      height: 45px;
    }
    ::-webkit-scrollbar-thumb {
      background: #005C8799;
      cursor: pointer;
      border-radius: 2.5px;
    }
  
    ::-webkit-scrollbar-track {
      background: #005C870D;
      border-radius: 2.5px;
    }
    >li
      >a{
      display:flex;
      color: #005C8799;
      font-family:"Rubik";
      font-size:14px;
      line-height:20px;
      padding: 3px 0 3px 15px;
      }
      >.active>a:hover {
        color: #005C87;
        font-family:"Rubik-medium";
        font-size:14px;
        line-height:20px;
      }
    }

  .dropdown-menu>.active>a{
    background:#005C870D;
    color: #005C87;
    font-family:"Rubik-medium";
    font-size:14px;
    line-height:20px;
    padding: 3px 0 3px 15px;
    >img{
      width:16px;
      height:16px;
      display: flex;
      margin: auto;
      margin-right: 15px;
    }
  }
  .dropdown-menu>.active>a:hover {
    color: #005C87;
    font-family:"Rubik-medium";
    font-size:14px;
    line-height:20px;
    background:#F7F5EB80;
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;

const NoData = styled.div`
  display:flex;
  width:100%;
  color: #005C87;
  font-family:"Rubik-medium";
  font-size:14px;
  line-height:20px;
`;

const FeedTopCard = styled.div`
  display:block;
  width:100%;
  background:white;
  border-radius:6px;
  padding: 20px;
  .firstContainer{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    margin-bottom:24px;
  .iconsDiv{
    width: 48px;
    height: 48px;
    border-radius:50%;
    margin-right:12px;
    >img{
      width: 100%;
      height: 100%;
      border-radius:50%;
    }
  }
  .textField{
    border: 1px solid rgba(0, 122, 177, 0.30);
    width:calc(100% - 60px);
    display:flex;
    border-radius: 40px;
    color: rgba(0, 92, 135, 0.30);
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 14px 12px;
    cursor:pointer;
  }
}
.iconsWrapper{
  width:100%;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  .postCard{
    input{
      opacity: 0;
      z-index: 0;
      position: absolute !important;
      width:150px !important;
      height:20px !important;
      display:block !important;
      cursor:pointer;
    }
    display:flex;
    .icon{
      width:24px;
      height:24px;
      margin-right:12px;
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .text{
      color: #007AB1;
      font-family: Rubik-Medium;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display:flex;
      align-items: center;
      cursor:pointer;
      &:focus, &:active, &:hover, &:focus-visible {
        color: #005C87;
      }
    }
  }
}
`;

const MyProfileCard = styled.div`
  width:100%;
  display:flex;
  border-radius: 4px;
  background: #FFF;
  margin-bottom:20px;
  flex-wrap: wrap;
  .heading{
    color: #007AB1;
    font-family: Rubik-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid rgba(0, 92, 135, 0.10);
    padding: 12px;
    width:100%;
    cursor:pointer;
    &:focus, &:active, &:hover, &:focus-visible {
      color: #005C87;
    }
  }
  .user-details{
    width:100%;
    padding: 12px;
    display:flex;
    flex-wrap: wrap;
    .image-container{
      width:100%;
      display:flex;
      justify-content:center;
      margin-bottom: 12px;
      .image{
        width: 80px;
        height: 80px;
        flex-shrink: 0;
        border-radius:50%;
        >img{
          width: 100%;
          height: 100%;
          border-radius:50%;
        }
      }
    }
    .name{
      width:100%;
      display:flex;
      justify-content:center;
      color: #007AB1;
      text-align: center;
      font-family: Rubik-Medium;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      cursor:pointer;
      &:focus, &:active, &:hover, &:focus-visible {
        color: #005C87;
      }
    }
    .place{
      width:100%;
      display:flex;
      justify-content:center;
      color: #005C87;
      text-align: center;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top:8px;
    }
  }
`;

const FeedCardWrapper = styled.div`
display:flex;
width:100%;
border-radius:6px;
background:white;
flex-wrap: wrap;
margin-top: ${({ margin }) => margin ? margin : "0px"};
max-height: ${({ height }) => height ? height : ""};
.heading{
  padding:12px;
  width:100%;
  display:flex;
  border-bottom: 1px solid rgba(0, 92, 135, 0.10);
  justify-content:space-between;
  .title{
    color: #007AB1;
    font-family: Rubik-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display:flex;
    align-items:center;
    cursor:pointer;
    &:focus, &:active, &:hover, &:focus-visible {
      color: #005C87;
    }
  }
  .title-updated{
    color: #007AB1;
    font-family: Rubik-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display:flex;
    align-items:center;
  }
  .icon{
    width:24px;
    height:24px;
    cursor:pointer;
    >img{
      width:24px;
      height:24px;
    }
  }
}
.wrapper{
  width:100%;
  display:flex;
  flex-wrap:wrap;
  padding: ${({ padding }) => padding ? padding : "12px"};
}
`;

const RecognitionCard = styled.div`
  display:flex;
  width:100%;
  border:1px solid rgba(0, 122, 177, 0.30);
  padding: 12px;
  flex-wrap:wrap;
  margin-top:8px;
  border-radius:4px;
  .recognitionImage{
    width:60px;
    height:60px;
    border-radius:3px;
    display:flex;
    background: ${({ background }) => background ? background : "#005c87"};
    >img{
      width:36px;
      height:36px;
      display:flex;
      margin:auto;
    }
  }
  .name{
    color: #005C87;
    font-family: Rubik-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display:flex;
    margin:auto auto auto 12px;
  }
  .count{
    color:#005C87;
    text-align: right;
    font-family: Rubik-Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display:flex;
    margin:auto 0 auto auto;
  }
`;

const FriendCard = styled.div`
display:flex;
width:100%;
padding: 12px;
flex-wrap:wrap;
margin-top:4px;
background: rgba(0, 92, 135, 0.05);
border-radius:6px;
.image{
  width: 68px;
  height:68px;
  border-radius:50%;
  >img{
    width:100%;
    height:100%;
    border-radius:50%;
  }
}
.friend-details{
  margin-left:12px;
  display:block;
  width: calc(100% - 80px);
  .name{
    color: #005C87;
    font-family: Rubik-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display:flex;
    align-items:center;
    margin-bottom: 4px
  }
  .department{
    color: #005C87;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display:flex;
    align-items:center;
  }
  .wellnessInterest{
    display:flex;
    gap:8px;
  }
}
`;

const InterestCard = styled.div`
width: 32px;
height:32px;
display:flex;
border-radius:6px;
background: #005C87;
margin-top:8px;
>img{
  width:20px;
  height:20px;
  display:flex;
  margin:auto;
}
`;

const PhotoCard = styled.div`
width:100%;
height:184px;
border-radius:6px;
>img{
  width:100%;
  height:100%;
  border-radius:6px;
  object-fit: cover;
}
`;

const PostTopSection = styled.div`
width:100%;
display:flex;
padding:20px;
.image{
  width: 48px;
  height: 48px;
  border-radius:50%;
  >img{
    width:100%;
    height:100%;
    border-radius:50%;
  }
  >div{
    width: 48px;
    height: 48px;
    border-radius:50%;
    >img{
      width:100%;
      height:100%;
      border-radius:50%;
    }
  }
}
.userDetails{
  display:block;
  margin-left:12px;
  flex-wrap:wrap;
  display:flex;
  .name{
    color: #005C87;
    font-family: Rubik-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 100%;
    align-items: center;
    display: flex;
  }
  .city{
    color: #005C87;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    align-items: center;
    display: flex;
  }
}
.buttonWrapper{
  display:flex;
  margin:0 0 auto auto;
}
`;

const AddButton = styled.div`
  display:flex;
  .icons{
    width:24px;
    height:24px;
    >img{
      width:100%;
      height:100%;
    }
  }
  .name{
    margin-left:8px;
    color: #007AB1;
    text-align: center;
    font-family: Rubik-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-decoration-line: underline;
    cursor:pointer;
  }
`;

const LikeCommentButtonSection = styled.div`
width:100%;
display:flex;
padding: ${({ commentpopup }) => commentpopup ? "16px 0" : "16px"};
border-top: ${({ commentpopup }) => commentpopup ? "none" : "1px solid rgba(0, 92, 135, 0.10)"};
border-radius: 0 0 6px 6px;
.commentSection{
  width:50%;
  display:flex;
  justify-content:flex-start;
  padding-left:50px;
}
.likeSection{
  width:50%;
  display:flex;
  justify-content:flex-end;
  padding-right:50px;
}
`;

const LikeCommentButton = styled.div`
display:flex;
cursor:pointer;
.icon{
  width:24px;
  height:24px;
  >img{
    width:100%;
    height:100%;
  }
}
.text{
  color: #007AB1;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display:flex;
  align-items:center;
  margin-left:8px;
}
`;

const LikeCommentCount = styled.div`
width:100%;
display:flex;
justify-content:space-between;
border-top: ${({ commentpopup }) => commentpopup ? "1px solid rgba(0, 92, 135, 0.10)" : "none"};
border-bottom: ${({ commentpopup }) => commentpopup ? "1px solid rgba(0, 92, 135, 0.10)" : "none"};
padding: ${({ commentpopup }) => commentpopup ? "13px 0px" : "12px 20px"};
margin-top: ${({ commentpopup }) => commentpopup ? "13px" : "0px"};
>div{
  display:flex;
.simpletext{
  color: #005C87;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display:flex;
  align-items:center;
}
.boldText{
  color: #005C87;
  font-family: Rubik-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display:flex;
  align-items:center;
  cursor:pointer;
}
.commentText{
  color: #005C87;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display:flex;
  align-items:center;
  cursor:pointer;
}
}
`;

const BirthdayCardUpdate = styled.div`
width:100%;
display:block;
.images{
  display:flex;
  justify-content:center;
  .image{
    width: 80px;
    height: 80px;
    padding:2px;
    border-radius: 50%;
    background: white;
    >img{
      width:100%;
      height:100%;
      border-radius: 50%;
    }
  }
  .imageLeft{
    width: 80px;
    height: 80px;
    padding:2px;
    border-radius: 50%;
    margin-left:-30px;
    background: white;
    >img{
      width:100%;
      height:100%;
      border-radius: 50%;
    }
  }
}
.button{
  width:100%;
  display: flex;
  height: 48px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #007AB1;
  border-radius: 4px;
  cursor:pointer;
  color: #FFF;
  text-align: center;
  font-family: Rubik-Medium;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
  justify-content:center;
  align-items:center;
  margin-top:20px;
}
`;

const RecognitionBirthdayAnniversary = styled.div`
  width:100%;
  background-image: ${({ image }) => image ? `url(${ImageUrl}/${image})` :  `url(${ImageUrl}/${image})`};
  height: ${({height}) => height ? height : '650px'};
  display:flex;
  flex-wrap:wrap;
  margin-top: ${({margin}) => margin ? "0px" : '20px'};
  background-size:  ${({background}) => background ? "100% 600px" : '100% 568px'};
  border-radius: ${({radius}) => radius ? "6px 0 0 6px" : '0px'};
  .mainContainer{
    width:100%;
    height: ${({height}) => height ? height : '650px'};
    display:flex;
    flex-wrap:wrap;
    cursor:pointer;
    padding: 40px 0;
  .image{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    margin:44px auto 68px auto;
    .imageWidth{
      width:240px;
      height:240px;
      display:flex;
      background:#FFF;
      border-radius:50%;
      margin:0 auto;
    >img{
      width:100%;
      height:100%;
      display:flex;
      margin:auto;
      border-radius:50%;
    }
  }}
  .wish{
    color: ${({color}) => color ? "#6781C3" : '#000'};
    text-align: center;
    font-family: "Abhaya Libre";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    width:100%;
    justify-content:center;
    align-items:center;
  }
  .wish-name{
    color: ${({color}) => color ? "#6781C3" : '#6781C3'};
    text-align: center;
    font-family: "Abhaya Libre";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    width:100%;
    justify-content:center;
    align-items:center;
  }
  .message{
    color: #000;
    text-align: center;
    font-family: "Abhaya Libre";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    width:100%;
    justify-content:center;
    align-items:center;
  }

}
`;

const MultiphotoWrapper = styled.div`
display:flex;
gap:2px;
flex-wrap: wrap;
.image-wrap{
  display:flex;
  width:100%;
  gap:2px;
}
`;

const Multiphoto = styled.div`
display:flex;
height: ${({height}) => height ? height : '100%'};
width: ${({width}) => width ? width : '100%'};
position: relative;
background: ${({background}) => background ? "linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60))" : 'none'};
>img{
  width:100%;
  height:100%;
  cursor:pointer;
  object-fit: cover;
}
.backdrop{
  position: absolute;
  width:188px;
  height:188px;
  background: ${({background}) => background ? "linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60))" : 'none'};
  color: #FFF;
  text-align: center;
  font-family: Rubik;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  object-fit: cover;
}
`;

const UserInitials = styled.div`
width:100%;
height:100%;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
color: ${({color}) => color ? color : '#005c87'};
text-align: center;
font-family: Rubik;
font-size: 80px;
font-style: normal;
font-weight: 400;
line-height: 88px;
border: 1px solid #F7F5EB;
border-radius:50%;
`;

const UserInitialsMain = styled.div`
width:100%;
height:100%;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
color: ${({color}) => color ? color : '#005c87'};
text-align: center;
font-family: Rubik;
font-size: ${({font, size}) => size?size: font ? "20px" : '16px'};
font-style: normal;
font-weight: 400;
line-height: ${({font, size}) => size?size: font ? "28px" : '20px'};
border-radius:50%;
background: ${({background}) => background ? "rgba(0, 92, 135, 0.10)" : 'rgba(0, 92, 135, 0.10)'};
`;

const DownAngleV2 = styled.i`
  float: right;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 45px;
  cursor: pointer;
  margin-left: 20px;
  .dropdown{
    >button{
      background:none !important;
    }}
  .fas {
    margin-right: 4px;
    color: rgb(153,153,153);
  }
  .fa {
    margin-right: 4px;
    color: rgb(153,153,153);
  }  
  @media only screen and (max-width: 767px) {
    position: relative;
    right: 0px;
    margin-left: 38px;
    .btn {
      padding: 0px;
    }
  }
  .dropdown-toggle {
    border: none !important;
    background-color: ${({ background, backgroundColor }) => backgroundColor?"transparent !important": background ? '#F4FAFF !important' : 'white !important'};
    box-shadow: none !important;
    padding: 0px;
    .caret {
      display: none;
    }
    > i {
      color: #3A3A3A;
      transform: rotate(90deg);
    }
  }   
  .dropdown-toggle::after{
    border-right:none !important;
    border-left:none !important;
  }
  .dropdown-item{
    color: #007AB1;
    font-family: Rubik-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    padding:0px;
    margin:auto;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    &:focus, &:active, &:hover, &:focus-visible {
      background: none;
      outline: none !important;
      color: white;
    }
  }
  .dropdown-menu {
    margin-left: ${({ width, margin, feed, marginL }) => marginL?marginL: feed?"-95px": margin?"-120px": width ? '-100px' : '-98px'};
    width: ${({ width }) => width ? 'auto' : '120px !important'};
    border: none;
    margin-top: ${({ margin, feed, marginT }) => marginT?marginT: feed?"-5px": margin ? '0px' : '-10px'};
    border: 1px solid rgba(0, 92, 135, 0.30);
    padding: 0px !important;
    min-width: 120px;
    height:44px;
    &:focus, &:active, &:hover, &:focus-visible {
      color: white;
      background:#005c87;
    }
    @media(max-width: 600px) {
      margin-top: -7px;
      margin-left: -86px;
    }
    > li {
      padding:0px;
      border-bottom:1px solid #005C871A;
      :last-child{
        border-bottom:none;
      }
      > a {
        color: #007AB1;
        font-family: Rubik-Medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        padding:0px;
        &:focus, &:active, &:hover, &:focus-visible {
          border: none !important;
          outline: none !important;
        }
      }
    }
  }
`;

const RecogntionContainer = styled.div`
width:100%;
display:flex;
justify-content:center;
.recogntionvalue{
  border-radius: 40px;
  border: ${({border}) => border ? `2px solid ${border}` : '2px solid #005c87'};
  padding: 8px 16px;
  display:flex;
  background:white;
  margin-top:-25px;
  .image{
    width:24px;
    height:24px;
    margin-right:8px;
    >img{
      width:100%;
      height:100%;
    }
  }
  .name{
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #005C87;
    display:flex;
    align-items:center;
  }
}
`;

const DailyTip = styled.div`
width:100%;
height:100%;
display:flex;
flex-wrap:wrap;
.category{
  width:100%;
  display:flex;
  justify-content:center;
  margin-bottom:12px;
  .icon{
    width:32px;
    height:32px;
    >img{
      width:100%;
      height:100%;
    }
  }
  .name{
    color: #007AB1;
    font-family: Rubik-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display:flex;
    align-items:center;
    margin-left:12px;
  }
}
.tip{
  width:100%;
  display:flex;
  color: #007AB1;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  justify-content:center;
  text-align: center;
}
`;

const CommentTextPopup = styled.div`
  display:flex;
  width:calc(100% - 60px);
  padding: 12px;
  border: ${({ border }) => border ? '1px solid #F4AAA9' : '1px solid rgba(0, 122, 177, 0.30)'};
  border-radius:20px;
  flex-wrap:wrap;
>div{
  width: ${({ width }) => width ? width : '100%'};
  display: flex;
  align-items: center;
  >div{
    >div{
      >textarea{
        border:none !important;
        // height:100% !important;
        padding:0px !important;
        width:100% !important;
        color:  #005C87;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        resize: none;
        .scroll-container {
          overflow-y: auto; /* allow vertical scrolling */
          scrollbar-width: none; /* hide scrollbar in Firefox */
          -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
        }
        
        .scroll-container::-webkit-scrollbar {
          display: none; /* hide scrollbar in Chrome, Safari, and Opera */
        }
        &:hover, &:active, &:focus {
          border:none !important;
          outline:none !important;
        }
      }

      >textarea::-webkit-input-placeholder {
        color: #005C874D !important
      }
      
      >textarea:-moz-placeholder {
        color: #005C874D !important
        opacity: 1;
      }
      
      >textarea::-moz-placeholder {
        color: #005C874D !important
        opacity: 1;
      }
      
      >textarea:-ms-input-placeholder {
        color: #005C874D !important
      }
      
      >textarea::placeholder {
        color: #005C874D !important
      }

    }
  }
}
`;

const ReplyTextPopup = styled.div`
  display:flex;
  width:calc(100% - 60px);
  padding: 12px;
  border: ${({ border }) => border ? '1px solid #F4AAA9' : '1px solid rgba(0, 122, 177, 0.30)'};
  border-radius:20px;
  flex-wrap:wrap;
>div{
  width: ${({ width }) => width ? width : '80%'};
  display: flex;
  align-items: center;
  border:none !important;
  >div{
    >div{
      >textarea{
        border:none !important;
        // height:100% !important;
        padding:0px !important;
        width:100% !important;
        color:  #005C87;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        resize: none;
        .scroll-container {
          overflow-y: auto; /* allow vertical scrolling */
          scrollbar-width: none; /* hide scrollbar in Firefox */
          -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
        }
        
        .scroll-container::-webkit-scrollbar {
          display: none; /* hide scrollbar in Chrome, Safari, and Opera */
        }
        &:hover, &:active, &:focus {
          border:none !important;
          outline:none !important;
        }
      }
      >textarea::-webkit-input-placeholder {
        color: #005C874D !important
      }
      
      >textarea:-moz-placeholder {
        color: #005C874D !important
        opacity: 1;
      }
      
      >textarea::-moz-placeholder {
        color: #005C874D !important
        opacity: 1;
      }
      
      >textarea:-ms-input-placeholder {
        color: #005C874D !important
      }
      
      >textarea::placeholder {
        color: #005C874D !important
      }
    }
  }
}
`;

const CommentBoxWrap = styled.div`
display:flex;
width: ${({ width }) => width ? width : '100%'};
.profile-picture{
  width:48px;
  height:48px;
  margin-right:12px;
  >img{
    width:100%;
    height:100%;
    border-radius:50%;
  }
}
`;

const RectBox = styled.div`
  width: 100%;
  width: 100%;
  padding: ${({ padding }) => padding ? "12px" : '14px'};
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  display:flex;
  border: 1px solid rgba(0, 122, 177, 0.30);
  >div{
    >textarea{
      border: none !important;
      outline: none !important;
    }
  }
`;

const CustomInputTextArea = styled.div`
  position: relative;
  height: 100%;
  width: ${({width}) => width};
  padding: ${({padding}) => padding};
  display:flex;
  flex-wrap:wrap;
  align-items: center;
  gap:12px;
  >textarea{
    width: ${({widthTextArea}) => widthTextArea};
    display: flex;
    resize: none;
    border: ${({border}) => border};
    font-size: 16px;
    color: #005c87;
    overflow:hidden;
    height:20px !important;
    // max-width:100px !important;
    &:focus, &:active, &:hover, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
    
    &::placeholder {
      opacity: 1;
      color: #005C874D !important;
    }

    &:-ms-input-placeholder {
      color: #005C874D !important;
    }

    &::-ms-input-placeholder {
      color: #005C874D !important;
    }
  }
`;

const TagUserCard = styled.div`
  display:flex;
  padding: 4px;
  border-radius: 40px;
  border: 1px solid rgba(0, 92, 135, 0.60);
  .profile{
    width:24px;
    height:24px;
    display:flex;
    border-radius: 50%;
    margin-right:4px;
    >img{
      width:24px;
      height:24px;
      display:flex;
      border-radius: 50%;
    }
  }
  .name{
    color: #005C87;
    text-align: center;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display:flex;
    margin-right:4px;
    align-items:center;
  }
  >img{
    width:24px;
    height:24px;
    cursor:pointer;
  }
`;

const TextArea = styled.div`
  position: relative;
  // height: ${({ isCommentOrReply, height, peer }) => peer ? '100%' : height ? '100px' : isCommentOrReply ? '50px' : '100px'};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  display:flex;
  align-items:center;
  >div{
    display:flex;
    width:100%;
    >textarea{
      width: 100%;
      display: flex;
      resize: none;
      border: ${({ border }) => border};
      padding-top: ${({ border }) => border && '2px'};
      height: ${({ border }) => border && '20px'};
      font-size: 16px;
      color: #005c87;
      resize:none;
      border: none;
      width: 100%;
      font-size: 16px;
      line-height: 20px;
      color: #005c87;
      resize: none;
      &:focus, &:active, &:hover, &:focus-visible {
        border: none !important;
        outline: none !important;
      }
      
      &::placeholder {
        color: #005C874D !important;
        opacity: 1;
      }
  
      &:-ms-input-placeholder {
        color: #005C874D !important;
      }
  
      &::-ms-input-placeholder {
        color: #005C874D !important;
      }
    }
  }

`;

const WishCard = styled.div`
display:flex;
width:calc(100% / 2 - 12px);
padding:12px;
border-radius: 4px;
background: rgba(0, 92, 135, 0.05);
flex-wrap: wrap;
gap:12px;
.wrapper{
  width:100%;
  display:flex;
  flex-wrap:wrap;
  gap:12px;
.profile{
  width:68px;
  height:68px;
  >img{
    width:100%;
    height:100%;
    border-radius:50%;
  }
}
.details{
  display:flex;
  width: calc(100% - 80px);
  flex-wrap:wrap;
  .name{
    width:100%;
    display:flex;
    color: #005C87;
    font-family: Rubik-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .location{
    color: #005C87;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width:100%;
    display:flex;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
  }
  .date{
    color: #005C87;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width:100%;
    display:flex;
  }
}}
`;

const WishButton = styled.div`
width: 100%;
display: flex;
display: flex;
width: 340px;
height: 48px;
padding: 12px 20px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 4px;
color:white;
background: #007AB1;
text-align: center;
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px;
cursor: ${({ disable }) => disable ? "not-allowed" : 'pointer'};
opacity: ${({ disable }) => disable ? "30%" : ''};
`;

const PreviousButton = styled.div`
width: 100%;
display: flex;
display: flex;
width: 340px;
height: 48px;
padding: 12px 20px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 4px;
color:#007AB1;
background: transparent;
text-align: center;
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px;
border: 1px solid #007AB1;
cursor: ${({ disable }) => disable ? "not-allowed" : 'pointer'};
opacity: ${({ disable }) => disable ? "30%" : ''};
`;

const MaxText = styled.div`
width: 100%;
display:flex;
color: #F4AAA9;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px;
margin-top:12px;
`;

const NewActivityDropdownCommon = styled(Dropdown)`
  button{
    display:flex;
    align-items:center;
    width: 100%;
    padding: ${({padding}) =>padding? padding : '0px 20px 0px 12px'};
    width: 100%;
    text-align: left;
    margin-top: 0px;
    text-transform: capitalize;
    ${'' /* height: 50px;
     */}
     height: ${({height}) =>height? height : '50px'};
    color: ${({notActive}) =>notActive? '#649bb3' : '#005c87'};
    font-size: 16px;
    font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik'};
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    border: 1px solid rgba(0, 122, 177, 0.30);
    background: white;
    ::after{
      display: none;
    }
    &:hover, &:active, &:active:focus{
      border: 1px solid rgba(0, 122, 177, 0.30);
      background: white;
      color: rgb(0, 92, 135);
      font-size: 16px;
      font-family: rubik-regular;
    }
    &:focus{
      background-color: ${ ({createEventUI}) => createEventUI && '#e6e6e6'};
      box-shadow: ${ ({createEventUI}) => createEventUI && 'inset 0 3px 5px rgba(0,0,0,.125)'};
      color: ${ ({createEventUI}) => createEventUI && '#333'};
      border-color: ${ ({createEventUI}) => createEventUI && '#adadad'};
      font-size: ${ ({createEventUI}) => createEventUI && '16px'};
      font-family: ${ ({createEventUI}) => createEventUI && 'rubik-medium'};
      height: ${ ({createEventUI}) => createEventUI && '50px'};
    }
  }
  button.show{
    color: rgb(0, 92, 135);
    background-color: white;;
    border-color: rgba(0, 122, 177, 0.30);
  }
  .dropdown-menu{
    width: 70%;
    flex-direction: column;
    top: 100% !important;
    left: 0 !important;
    right: auto !important;
    bottom: auto !important;
    position: absolute !important;
    z-index: 15 !important;
    transform: none !important;
    
    .dropdown-item{
      color: #007AB1;
      font-family: Rubik-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border-bottom:1px solid rgba(0, 92, 135, 0.10);
      border-spacing:10px;
      padding:8px !important;
      margin: ${({margin}) =>margin? margin : '0px 12px'};
      ${'' /* padding: 3px 0px 3px 15px; */}
      background: none;
      text-transform:capitalize;
      &:hover{
        color: rgb(0, 92, 135);
        font-family: Rubik-medium;
        background: #f0f9fe;
      }
      &:active{
        color: rgba(0, 92, 135, 0.6);
        font-family: Rubik-medium;
        background: rgba(0, 92, 135, 0.05);
      }
    }
    .dropdown-item .active{
      background: rgba(0, 92, 135, 0.05) !important;
    }
    .dropdown-item > img{
      width: 16px;
      height: 16px;
      float: right;
      margin: auto 15px auto auto;
      display: none;
    }
    .dropdown-item:hover > img{
      display: flex;
    }
    .dropdown-item:active > img{
      display: flex;
    }
    .dropdown-item.active{
      background: rgba(0, 122, 177, 0.05);
    }
  }
  .dropdown-menu.show{
    display:${ ({disabled}) => disabled && 'none'};
    width: 100%;
    margin-top: 4px;
    margin-left: ${({marginleft}) =>marginleft? marginleft : ''};
    width: ${({widthdropdown}) =>widthdropdown? "auto" : '100%'};
    border: 1px solid rgba(0, 122, 177, 0.30);
    ${'' /* inset:100% auto auto 0px; */}
    ${'' /* position: unset !important; */}
    ${'' /* transform: unset !important; */}
    ${'' /* top: 100%; 
    left: 0;
    right: auto;
    bottom: auto; 
    position: absolute;  */}
    .dropdown-item{
      background-color: ${ ({createEventUI}) => createEventUI && '#fff' };
      min-width: ${ ({createEventUI}) => createEventUI && '160px'};
      padding: ${ ({createEventUI}) => createEventUI && '3px 20px'};
      margin: ${ ({createEventUI}) => createEventUI && '2px 0 0'};
      font-size: ${ ({createEventUI}) => createEventUI && '14px'};
      text-align: ${ ({createEventUI}) => createEventUI && 'left'};
      list-style: ${ ({createEventUI}) => createEventUI && 'none'};
      border-radius: ${ ({createEventUI}) => createEventUI && '4px'};
      &:hover{
        background-color: ${ ({createEventUI}) => createEventUI && '#f0f9fe'};
      }
    }

    .dropdown-item:hover {
      ${'' /* color: #262626; */}
      text-decoration: none;
      font-family: 'Rubik-Regular';
    }
    
    .dropdown-item.active{
      ${'' /* background: rgb(13, 66, 112); */}
      ${'' /* color: #fff; */}
      text-decoration: none;
      outline: 0;
      border-radius: 0px;
    }
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  padding: 16px;
  border: 1px solid rgba(254, 254, 254, 0.60);
  border-radius: 4px;
  background-color: #fff;
`;
const Header = styled.div`
  font-size: 16px;
  font-family: 'Rubik-Medium';
  font-weight: 500;
  color: #007AB1;
  margin-bottom: 8px;
`;

export {Container,Header,
  ShoutoutImage, SingleFeedDescription, ShoutoutDiv, ShoutImage, Cross, ShowTaggedUser, DropDownStyledImage, InputTextArea, UserContainer, UserDropdownContainer, ListDropdown, ProfilePicture, IconContainer, ShoutoutContainer, SocialFeedsContainer, CustomTextAreaField, ContentContainer, InputFieldContainer, InputImage, FeedsContainer, HeaderContainer, UserInformation,
  Description, FeedInformation, FeedFeatures, CommentCounts, LikeCounts, CommentButton, StyledTabContainer, StyledTitle, StyledInput, ImageContainer,
  ButtonContainer, SideBarContainer, TileContainer, TitleContent, SideBarContent, StyledImage, NotificationDescription, WellnessExpertImage,
  WellnessExpertName, FeaturedEventsContainer, DownAngle, NoComments, Layout, Profile, NoFriendRequests, NoActivityFeeds,
  CommentSection, ScrollableFeedsContainer, AssessmentContainer, TipInformation, InformationIcon, ProfileContainer, ImageWrapper, WrapImage,
  StyledModal, EmojiContainer, ImageWrap, Text, RecognitionSection, AddBuddyContainer, RemoveBuddyContainer, RemoveButtonContainer, PendingButtonContainer,
  TimeOfPost, EmojiContainerV2, ButtonWrapper, PostWrapper, PhotoWrapper, RecognitionWrapper, LinkContainer, Link, LinkTab, PictureDiv, MainSend, BottomSection, LeftContent, ProfileImg
  , LikedUser, Text2, SelectedImage, FeedTab, LikeButton, CommentTabButton, RecognitionButton, FieldContainer, Option, RecognitionText, ShowTaggedUser2, ConnectDevice, PendingButton, CommentDiv, CommentContent, CommentSectionNew, InputFieldContainerNew, PictureDivNew, InputTextAreaNew, ButtonWrapperNew, PostWrapperNew, PhotoWrapperNew, RecognitionWrapperNew, LikeButtonew, Border, MainSendNew, FeedTabNew, FirstCommentButton, CoreValueMain, CoreValue, BirthdayImage, BirthdayWrapper, BirthdayCard, BirthdayFeed, DailyTipCard, SendButton, ShowLikeComment, PostCommentsWrapper, SliderContainer,
  RecognitionImage, InputTextAreaComment, CoreValueSideBarContainer, MainWrapper, RecognitonNameWrapper, RecognitonDescription, ActivityDropdown, CustomMenuItem, TitleContainer, NoData, FeedTopCard, MyProfileCard, FeedCardWrapper, RecognitionCard, FriendCard, PhotoCard,
  PostTopSection, AddButton, LikeCommentButtonSection, LikeCommentButton, LikeCommentCount, InterestCard, BirthdayCardUpdate, RecognitionBirthdayAnniversary, MultiphotoWrapper, Multiphoto, UserInitials, UserInitialsMain, DownAngleV2, RecogntionContainer, DailyTip, CommentTextPopup, CommentBoxWrap, ReplyTextPopup, RectBox, CustomInputTextArea, TagUserCard, TextArea, WishCard, WishButton, MaxText, PreviousButton, NewActivityDropdownCommon
};
