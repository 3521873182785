/* eslint-disable no-console,no-undef, camelcase */
/* eslint-disable react/no-unknown-property */
import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import _, { isNull, isUndefined } from 'lodash';
import { FieldContainer } from '../EventDetails/styles';
import { LayoutNew, CommentContentContainer, CommentInnerContainer, ChallengeGrid, ChallengeInfoGridNew, CommentGridNew, ChatGroup, ChatUsers, ChatTextArea, ChatCommentContent, IconContainer, InputImage, StyledTabsNew, Main, EventsButton, ButtonContainer, IconContainerNew, InputImageNew, /*CompletedChallengeCard,*/ FormContainer, TopContainer, TabContainer, TabView, IconWrapper, HoverIconWrapper } from './styles';
import { NoComments } from '../SocialFeeds/styles';
import CommentsV2 from '../CommentsV2';
import BehaviorChallengeComments from '../CommentsV2/behaviorChallengeComments'
import Waiting from '../Waiting';
import DetailedChallengeV2 from './DetailedChallengeV2'
import GroupChallengeLeaderboardV2 from './GroupTeamGoal/newGroupChallenge';
import Loading from '../Loading';
import GroupOverViewScreen from './OverViewScreen/groupOverview'
import MyTaskTabV2 from "./MyTaskTab/myTaskTab";
// const StartChallengePopUp = React.lazy(() => import("./StartChallengePopUp"));
import {
  getChallengeDetails, addCommentOnChallenge, getCommentsOnChallenge, getChallengeCompetitors,
  addCommentReplyOnChallenge, getUserProfileImage, getUserId, getTeamChallenge, updateDailyTask, changeWeekDashboard, getUserListOfChallenges, getUserLastSync, getUserOverviewDetails, getChallengeCsvData, emptyChallengeDetails, toggleDummyChallenges, setChallengeInviteStatus,
  joinOrLeaveChallenge, deleteChallengeById, getInviteAmigosList, UpdateUserLiveChallengeStatus
} from '../../redux/actions';
import Image from '../Image';
import { OverlayTrigger, Popover, Tab } from "react-bootstrap";
import MyProgressTab from "./MyProgressTab/myProgressTabV2";
import { ProfileImage } from './OverViewScreen/styles';
import { withRouter } from "react-router-dom";
import moment from "moment";
import { resetOrientation, getOrientation, checkImage, getPermissionStatus, convertDateInTimezone, getUnit, commaSeperator } from '../../utils/methods';
import { imageErrorMessage, ImageUrl } from '../../utils/constants';
import BehaviorChallengeDetail from "./BehaviorChallengeNew";
const DemoPopup = React.lazy(() => import('../WhyAmigoCommon/ChallengePopup'));
import RightSideSection from '../ChallengeDetailsV2/MyTaskTab/behaviorChallengeRightSide'
import { AboutContainer, CommentContent, PostButton } from '../EventDetails/styles';
import { ImageWrapper } from '../SocialFeeds/styles';
import { RightContainer } from './MyTaskTab/styles';
import GroupRightSection from './GroupTeamGoal/groupRightSection';
import ClassicRightSection from './GroupTeamGoal/classicChallengeRight';
import ClassicChallengeLeaderboard from './GroupTeamGoal/classicLeaderboard';
import SurvivorRightSection from './survivorChallengeRight';
import SurvivorOverViewScreen from './OverViewScreen/survivorOverview';
import SurvivorLeaderboard from './ChallengeLeaderboardV2/survivorLeaderboard';
import { withTranslation } from 'react-i18next';
import SocialFeedsEmojiPicker from '../SocialFeedsEmojiPicker';
import TeamChallengeRight from './TeamChallenge/teamChallengeRight';
import TeamOverview from './OverViewScreen/teamOverview';
import TeamLeaderboard from './TeamChallenge/teamLeaderboard';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';
import ChallengeDetailTop from './challengeTopViewCommon';
import { DeleteIcon, /*LeaveChallengeIcon,*/ InviteBuddyIcon, editIcon, DownloadIconNew } from '../../utils/icons';
import HoverButton from '../common/HoverButtonV2';
import DeleteAndLeavePopup from './challengeLeavePopup';
const InviteAmigoPopUp = lazy(() => import("./InviteAmigoPopUp/inviteChallengePopup"));
import CSVList from "../AttendeesModal/CSVList";
import parse from 'react-html-parser';

let csvHeaders = [];
class ChallengeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      isUpdatedImage: false,
      selectedTab: 1,
      objIndex: 0,
      taskUpdated: 0,
      selectedDate: moment(moment()._d, 'MM DD YYYY').format('MM-DD-YYYY'),
      refreshFileReader: false,
      imgSrc: [],
      showVideo: false,
      joinOrLeave: 1,
      displayEmoji: false,
      description: '',
      position: {start:0, end:0},
      isButtonDisabled: false,
      tabValue:1,
      deleteChallengeId:null,
      showDeletePopup: false,
      showLeavePopup: false,
      leaveChallengeId: null,
      showInviteAmigoPopup: false,
      printChallengeCSV: false,
      updatedChallengeCSV: {}
    }
    this.emojiref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleEmojiClick);
    const { location: { pathname, search }, getChallengeDetails, getCommentsOnChallenge, fetchUserProfileImage, fetchUserId, match, getUserListOfChallenges } = this.props;
    const StringArray = pathname.split('/');
    if (StringArray[(StringArray.length - 1)] != '') {
      this.props.toggleDummyChallenges(search.includes('ACME=true'));
      getChallengeDetails(parseInt(match.params.id,10));
      getCommentsOnChallenge(parseInt(match.params.id,10));
      getUserListOfChallenges(parseInt(match.params.id,10));

    } else {
      getChallengeDetails(StringArray[(StringArray.length - 2)]);
      getCommentsOnChallenge(StringArray[(StringArray.length - 2)]);
      
    }
    if (!this.props.syncTime) {
      this.props.getUserLastSync();
    }
    fetchUserId();
    if (!this.props.profileImage) fetchUserProfileImage();
    this.checkBase64(this.props.profileImage);
  }
  componentDidUpdate(prevProps) {
    const { location: { pathname, search }, getChallengeDetails, getCommentsOnChallenge, match, getUserListOfChallenges } = this.props;
    const StringArray = pathname.split('/');
    if (pathname !== prevProps.location.pathname) {
      if (StringArray[(StringArray.length - 1)] != '') {
        this.props.toggleDummyChallenges(search.includes('ACME=true'));
        getChallengeDetails(parseInt(match.params.id,10));
        getCommentsOnChallenge(parseInt(match.params.id,10));
        prevProps.challengeAttendees != this.props.challengeAttendees && getUserListOfChallenges(parseInt(match.params.id,10));
      } else {
        getChallengeDetails(StringArray[(StringArray.length - 2)]);
        getCommentsOnChallenge(StringArray[(StringArray.length - 2)]);
      }
    }
  }

  updateDownloadCSV = (bool) =>{
    this.setState({ printChallengeCSV: bool })
  }

  navigateEdit = () => {
    const { location: { pathname }, history } = this.props;
    const StringArray = pathname.split('/');
    if (StringArray[StringArray.length - 1] != '') {
      history.push(`/challenges/${StringArray[StringArray.length - 1]}/edit`);
    } else {
      history.push(`/challenges/${StringArray[StringArray.length - 2]}/edit`);
    }
  }

  showEmojiPicker = () => {
    this.setState((prev) => ({
      displayEmoji: !prev.displayEmoji
    }))
  }

  hideEmojiPicker = () => {
    this.setState({
      displayEmoji: false
    })
  }

  handleEmojiClick = (event) => {
    if (!isNull(this.emojiref) && !isUndefined(this.emojiref) && !(this.emojiref && this.emojiref.current && this.emojiref.current.contains(event.target))) {
      // this.setState({displayEmoji: false});
    }
  };

  toCodePoints = function (string) {
    let chars = "";
    for (let i = 0; i < string.length; i++) {
      let c1 = string.charCodeAt(i);
      let b1 = string.charAt(i);
      if (c1 >= 0xD800 && c1 < 0xDC00 && i + 1 < string.length) {
        let c2 = string.charCodeAt(i + 1);
        if (c2 >= 0xDC00 && c2 < 0xE000) {
          chars = chars + ("&#" + ((+0x10000 + ((c1 - 0xD800) << 10) + (c2 - 0xDC00))) + ";");
          i++;
          continue;
        }
      }
      else {
        chars = chars + b1;
      }
    }
    return chars;
  }

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      }
      else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }
  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleEmojiClick);
    this.props.emptyChallengeDetails();
    this.props.toggleDummyChallenges(false);
  }

  showProfileImage = () => {
    const { profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (isUpdatedImage) {
      return (<img src={profileImage} alt="avatar" style={{borderRadius:'50%'}}/>)
    }
    else {
      return (<Image image={profileImage} alt="avatar" style={{borderRadius:'50%'}}/>)
    }
  };

  onChange = (e) => {
    let position = this.getPosition(e.target);
    e.target.value !== ' ' ?
      this.setState({
        comment: e.target.value,
        position: position
      }) : null;
  };

  onChangeImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({
            imgSrc: [reader.result],
            refreshFileReader: true
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: [baseImage],
                refreshFileReader: false
              });
            });
          });
        };

        document.getElementById('uploadPhoto').value = '';
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('uploadPhoto').value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('uploadPhoto').value = '';
    }
  };

  removeImage = () => {
    this.setState({
      imgSrc: []
    });
  };

  postComment = (id) => {
    const { addCommentOnChallenge } = this.props;
    const { comment, imgSrc } = this.state;
    let html = this.toCodePoints(comment)
    addCommentOnChallenge({ challenge_id: id, comment: html, image: imgSrc.length >= 1 ? imgSrc[0] : '' });
    this.setState({
      comment: '',
      imgSrc: []
    })
  };

  setActiveTab = (active) => {
    this.setState({ selectedTab: active })
  }
  getListOfUser = () => {
    const { challengeAttendees } = this.props;
    return <div>
      {
        challengeAttendees && challengeAttendees.length ? challengeAttendees.map((user, index) => (
          (index < 3) ? <ProfileImage chatHeader={1} key={index}>
            <Image image={user.profile_image} alt="avatar" />
          </ProfileImage> : null
        )
        ) : null
      }
      {
        challengeAttendees && challengeAttendees.length > 3 ?
          <ProfileImage chatHeader={1}>
            +{challengeAttendees && challengeAttendees.length - 3}
          </ProfileImage> : null
      }
    </div>
  };
  setObjIndex = (index, taskUpdated = 0, selectedDate = '') => {
    this.setState({ objIndex: index, taskUpdated: taskUpdated, selectedDate: selectedDate })
  }

  changeModal = () => {
    this.setState((prevState) => ({
      showVideo: !prevState.showVideo
    }));
  }

  onEmojiSelect = (emoji, id, native, skin) => {
    this.setState({
      emojiData: emoji,
      emojiId: id,
      emojiNative: native,
      emojiSkin: skin
    })
    this.addInspirationV2(native);
  }

  popover = (value) => (
    <Popover
      id="popover-basic"
      style={{
        width: "auto",
        padding: "9px 14px",
        background: "#FFFFFF",
        border: "1px solid #005c87",
        boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
      }}
    > 
      <div
        style={{
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "20px",
          color: "#005c87",
          textAlign: "center",
        }}
      >
        {value}
      </div>
    </Popover>)

  getPosition(el) {
    let start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end
    };
  }

  addInspirationV2 = (e) => {
    let newText = this.state.comment.slice(0, this.state.position.start) + e + this.state.comment.slice(this.state.position.end);
    this.setState({
      position: { start: this.state.position.start + e.length, end: this.state.position.end + e.length },
      comment: newText,
    });
  };

  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
    this.setState({ isButtonDisabled: true });
    window.setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, 2000);

  };

  updateTabValue = (value) => {
    this.setState({ tabValue: value });
  }

  schedule = () =>(
    <svg
      width="20px"
      height="auto"
      viewBox="-5 0 25 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ui-gambling-website-lined-icnos-casinoshunter"
          transform="translate(-1913.000000, -158.000000)"
          fill="#0D4270"
          fillRule="nonzero"
        >
          <g id="1" transform="translate(1350.000000, 120.000000)">
            <path
              d="M566.453517,38.569249 L577.302459,48.9938158 L577.39261,49.0748802 C577.75534,49.423454 577.968159,49.8870461 578,50.4382227 L577.998135,50.6228229 C577.968159,51.1129539 577.75534,51.576546 577.333675,51.9774469 L577.339095,51.9689832 L566.453517,62.430751 C565.663694,63.1897497 564.399001,63.1897497 563.609178,62.430751 C562.796941,61.650213 562.796941,60.3675924 563.609432,59.5868106 L573.012324,50.5572471 L563.609178,41.4129456 C562.796941,40.6324076 562.796941,39.349787 563.609178,38.569249 C564.399001,37.8102503 565.663694,37.8102503 566.453517,38.569249 Z"
              id="left"
              transform="translate(570.500000, 50.500000) scale(-1, 1) translate(-570.500000, -50.500000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  ) 

  showHideInviteAmigoPopup = () => {
    this.setState((prevState) => ({
      showInviteAmigoPopup: !prevState.showInviteAmigoPopup
    }), () => this.props.getInviteAmigosList(this.props.userId, this.props.challenge.id))
  };

  updateLiveStatus = () => {
    const { UpdateUserLiveChallengeStatus,history, challenge, isAdminChallenge } = this.props;
    UpdateUserLiveChallengeStatus();
    if(challenge.is_completed){
      history.goBack()
    }
    else if(isAdminChallenge){
      history.push("/company/challenges")
    }
    else{
      history.push("/challenges")
    }
    
  };
  
  editChallengeRedirection = (challengeId) => {
    const { history } = this.props;
    if(history.location.pathname.includes("/company")){
      history.push(`/company/challenges/edit/${challengeId}`)
    }
    else{
      history.push(`/challenges/edit/${challengeId}`)
    }
  }

  headerView = () =>{
    const { tabValue, /*isButtonDisabled*/ } = this.state;
    const { challenge, role, userId, challengeCSVStatus, userPermissions, challengeCsvData } = this.props;
    const challengeId = challenge && challenge.id;
    const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    let currentDate = moment();
    const showEditButton= (role==="ADMIN"|| ((role==="WELLNESS_ADMIN"||role==="CONTENT_CREATOR")&&challenge.is_global_challenge!==1))&& moment(convertDateInTimezone(challenge.start_date)._d).format('MM-DD-YYYY')>=moment(currentDate).format('MM-DD-YYYY')&&challenge.is_user_challenge!==1?true:false;
    const startChallengeEditButton=(role==="ADMIN"|| ((role==="WELLNESS_ADMIN"||role==="CONTENT_CREATOR")&&challenge.is_global_challenge!==1))&& moment(convertDateInTimezone(challenge.start_date)._d).format('MM-DD-YYYY')<=moment(currentDate).format('MM-DD-YYYY')&&challenge.is_user_challenge!==1&&challenge.challenge_status!="over"&&!challenge.is_global_challenge?true:false;
    return(
      <TabContainer marginBottom="25px" paddingRight="15px">
        <IconWrapper margin="auto 0px auto 0">
          <HoverButton
            title={this.props.t("Back")}
            width="20px"
            height="24px"
            svgPath={this.schedule()} 
            onClick={() => this.updateLiveStatus() }
            backgroundColor={"#005C870D"}
            backgroundColorActive={"#005C87"}
          />
        </IconWrapper>
        <TabView 
          width
          padding
          active={tabValue===1}
          onClick={() => this.updateTabValue(1)}
        >
          {this.props.t("Overview")}
        </TabView>
        <TabView
          width
          padding
          active={tabValue===2}
          onClick={() => this.updateTabValue(2)}
        >
          {this.props.t("Leaderboard")}
        </TabView>
        <TabView
          width
          padding
          active={tabValue===3}
          onClick={() => this.updateTabValue(3)}
        >
          {this.props.t("Chat")}
        </TabView>
        <HoverIconWrapper>
          {(showEditButton||startChallengeEditButton) &&challenge.challenge_status!="over"&&<IconWrapper margin={"auto 0 auto 0"}>
            <HoverButton
              title={this.props.t("Edit Challenge")}
              width="23px"
              height="22px"
              svgPath={editIcon()} 
              onClick={() => this.editChallengeRedirection(challengeId)}
              backgroundColor={"#005C870D"}
              backgroundColorActive={"#005C87"}
            />
          </IconWrapper>}
          {(!_.isEmpty(challengeCSVStatus) && (challenge.is_global_challenge && role === "ADMIN" || getPermissionStatus("Download challenge CSV", userPermissions)) ) && (challenge.challenge_status == 'over' || !valid) && (challengeCsvData && challengeCsvData.length > 0) &&<IconWrapper margin={"auto 0 auto 0"}>
            <HoverButton
              title={this.props.t("Download CSV")}
              width="40px"
              height="40px"
              svgPath={<DownloadIconNew/>} 
              onClick={() => this.printAction(true)}
              backgroundColor={"#005C870D"}
              backgroundColorActive={"#005C87"}
            />
          </IconWrapper>}
          {(role==="ADMIN"|| role==="WELLNESS_ADMIN"|| challenge.created_by === userId) && challenge.challenge_status!="over" &&<IconWrapper 
            margin={"auto 0 auto 0"}
          >
            <HoverButton
              title={this.props.t("Delete Challenge")}
              width="22px"
              height="22px"
              svgPath={<DeleteIcon/>} 
              onClick={() => this.showDeletePopup(challengeId)}
              backgroundColor={"#005C870D"}
              backgroundColorActive={"#005C87"}
            />
          </IconWrapper>}
          {((role==="ADMIN" || role==="WELLNESS_ADMIN"|| challenge.created_by === userId) && challenge.is_user_challenge===1) && challenge.challenge_status !== 'over'&& challenge.challenge_status === 'joined' &&<IconWrapper margin={"auto 0 auto 0"}>
            <HoverButton
              title={this.props.t("Invite Buddies")}
              width="20px"
              height="20px"
              svgPath={<InviteBuddyIcon/>} 
              onClick={() => this.showHideInviteAmigoPopup()}
              backgroundColor={"#005C870D"}
              backgroundColorActive={"#005C87"}
            />
          </IconWrapper>}
        </HoverIconWrapper>
        {/* {challenge.challenge_status === 'joined'?
          <IconWrapper margin={role==="ADMIN"|| role==="WELLNESS_ADMIN"|| challenge.created_by === userId?"auto 15px auto 0":"auto 15px auto auto"}>
            <HoverButton
              title="Leave Challenge"
              width="20px"
              height="24px"
              svgPath={<LeaveChallengeIcon/>} 
              onClick={isButtonDisabled?null :() => this.showLeavePopup(challengeId)}
              backgroundColor={"#005C870D"}
              backgroundColorActive={"#005C87"}
            />
          </IconWrapper>:null}   */}
      </TabContainer>
    )
  }

  showDeletePopup = (id) => {
    this.setState({ showDeletePopup: true, deleteChallengeId:id });
  };

  showLeavePopup = (id) => {
    this.setState({ showLeavePopup: true, leaveChallengeId:id });
  };

  hideDeletePopup = () => {
    this.setState({ showDeletePopup: false });
  };

  hideLeavePopup = () => {
    this.setState({ showLeavePopup: false });
  };

  deleteTeam = (id) => {
    const { deleteChallengeById } = this.props;
    this.setState(
      {
        deleteChallengeId:null, showDeletePopup: false
      },
      () => {
        deleteChallengeById(id);
      }
    );
  };

  leaveTeam = (id) => {
    this.setState(
      {
        leaveChallengeId:null, showLeavePopup: false
      },
      () => {
        this.joinChallenge(id, 0)
      }
    );
  };

  printAction = (bool) => {
    const { challengeCsvData, challenge} = this.props;
    const ChallengeType = challenge && challenge['event_type'].split(' ')[1];
    let updatedChallengeCSV = challengeCsvData && challengeCsvData.length > 0 && challenge.challenge_type === 'new_group' ?
      challengeCsvData.map((competitor) => {
        let obj = {};
        obj['FIRST NAME'] = competitor['fname'];
        obj['LAST NAME'] = competitor['lname'];
        obj['RANK'] = competitor['user_rank'];
        obj['EMAIL'] = competitor['EMAIL'];
        obj[`Total ${ChallengeType.charAt(0).toUpperCase()}${ChallengeType.slice(1)}`] = _.isNull(competitor[ChallengeType.toLowerCase()]) ? 0 : ChallengeType === 'Distance' ? `${getUnit(competitor[`${ChallengeType.toLowerCase()}`])} ${'miles'}` : commaSeperator(parseInt(competitor[`${ChallengeType.toLowerCase()}`],10));
        return obj;
      })
      : challengeCsvData.map((competitor, index) => {
        let obj = {};
        obj['FIRST NAME'] = competitor['fname'];
        obj['LAST NAME'] = competitor['lname'];
        obj['RANK'] = index + 1;
        obj[`Total ${ChallengeType.charAt(0).toUpperCase()}${ChallengeType.slice(1)}`] = _.isNull(competitor[ChallengeType.toLowerCase()]) ? 0 : ChallengeType === 'Distance' ? `${getUnit(competitor[`${ChallengeType.toLowerCase()}`])} ${'miles'}` : commaSeperator(parseInt(competitor[`${ChallengeType.toLowerCase()}`],10));
        obj['Daily Average'] = challenge['challenge_type'] === 'group' ? competitor['daily_average'] : competitor['challenge_average'];
        obj['Weekly Average'] = _.isNull(competitor[ChallengeType.toLowerCase()]) ? 0 : commaSeperator(Math.round(competitor['weekly_average']));
        obj['No of Weeks Survived'] = challenge && challenge['challenge_type'] === 'survivor' && challenge['challenge_mode'] === 'Solo' && (!_.isNull(competitor['weekly_marks']) || !_.isUndefined(competitor['weekly_marks'])) ? competitor['weekly_marks'].filter((item) => item !== 0).length : 0;
        obj['EMAIL'] = competitor['EMAIL'];
        obj['company_name'] = competitor['company_name'];
        obj['location'] = competitor['location'];
        return obj;
      });
    this.setState({ printChallengeCSV: bool, updatedChallengeCSV: updatedChallengeCSV })
  };

  getCSVHeaders = () => {
    const { challenge } = this.props;
    const ActivityType = challenge && challenge.event_type.split(' ')[1];
    const challengeType = challenge && challenge.challenge_type;
    if (challengeType === 'new_group') {
      csvHeaders = [
        { label: "First Name", key: "FIRST NAME" },
        { label: "Last Name", key: "LAST NAME" },
        { label: "Email", key: "EMAIL" },
        { label: "Rank", key: "RANK" },
      ];
    } else {
      csvHeaders = [
        { label: "First Name", key: "FIRST NAME" },
        { label: "Last Name", key: "LAST NAME" },
        { label: "Email", key: "EMAIL" },
        { label: "Location", key: "location" },
        { label: "Company Name", key: "company_name" },
        { label: "Rank", key: "RANK" },
        { label: "Daily Average", key: "Daily Average" },
        { label: "Weekly Average", key: "Weekly Average" },
      ];
    }
    csvHeaders.push({ label: `Total ${ActivityType}`, key: `Total ${ActivityType}` });
    challengeType === 'survivor' && challenge.challenge_mode === 'Solo' && csvHeaders.push({ label: "No of Weeks Survived", key: `No of Weeks Survived` });
    return csvHeaders;
  };

  render() {
    const { challenge, isLoading, comments, networkError, fetchChallengeCompetitors, location,
      postReplyOnComment, replySuccess, profileImage, userId, fetchTeamChallenge, role, updateDailyTask, syncTime, getUserOverviewDetails, getChallengeCsvData, challengeAttendees, userPermissions, user, teamChallenge } = this.props;
    const { comment, objIndex, taskUpdated, selectedDate, imgSrc, showVideo, joinOrLeave, isButtonDisabled, tabValue, deleteChallengeId, showDeletePopup, showLeavePopup, leaveChallengeId, printChallengeCSV, updatedChallengeCSV } = this.state;
    if ((_.isNull(challenge) || _.isUndefined(challenge) || Object.keys(challenge).length === 0 || _.isNull(challenge['challenge_type']) || _.isUndefined(challenge['challenge_type']) || _.isNull(profileImage) || _.isNull(userId) || _.isNull(challengeAttendees)) && !networkError) {
      return <Waiting />
    }else if(challenge?.is_team_challenge){
      if (( _.isNull(teamChallenge) || _.isUndefined(teamChallenge['team_challenge_details']) || _.isUndefined(teamChallenge) || Object.keys(teamChallenge).length === 0 ) && !networkError) {
        return <Waiting />
      }

    }
    // const DistanceUnit = localStorage.getItem('DISTANCE');
    const fileTitle = challenge && challenge.title.replace(/ /g, "_");
    return (
      <React.Fragment>
        {(_.isNull(challenge) || _.isUndefined(challenge)) ? null :
          <Main>
            <LayoutNew display={(challenge.challenge_mode === 'Team' && challenge['challenge_type'] == 'survivor') ? "block" : "flex"}>
              <ChallengeGrid wrap="wrap">
                {challenge && challenge.challenge_type !== 'behavior'?
                  this.headerView():null}
                <ChallengeInfoGridNew width={challenge['challenge_type'] == 'behavior' ? 1 : 0}>
                  {challenge && challenge.challenge_type !== 'behavior' && challenge.challenge_type !== 'new_group' && challenge.challenge_type !== 'group' && challenge.challenge_type !== 'survivor' ? <DetailedChallengeV2 role={role} userId={userId} isAdmin={role != 'USER'} challenge={challenge} navigateEdit={this.navigateEdit} syncTime={syncTime} userPermissions={userPermissions} /> :  challenge && challenge.challenge_type == 'behavior'?  <BehaviorChallengeDetail challenge={challenge} />: tabValue===1 && <ChallengeDetailTop challenge={challenge} />}
                  {
                    challenge && challenge.challenge_type === 'survivor' ?
                      tabValue === 1 ?
                        <SurvivorOverViewScreen
                          challenge={challenge}
                          userId={userId}
                          profileImage={profileImage}
                          getUserOverviewDetails={getUserOverviewDetails}
                          location={location}
                          fetchTeamChallenge={fetchTeamChallenge}
                          getChallengeCsvData={getChallengeCsvData}
                          role={role}
                          userPermissions={userPermissions}
                          history={this.props.history}
                          // updateDownloadCSV={this.updateDownloadCSV}
                          printChallengeCSV={printChallengeCSV}
                        /> :
                        tabValue === 2 ?
                          <SurvivorLeaderboard
                            challenge={challenge}
                            fetchChallengeCompetitors={fetchChallengeCompetitors}
                            location={location}
                            networkError={networkError}
                            userId={userId}
                            profileImage={profileImage}
                            fetchTeamChallenge={fetchTeamChallenge}
                          /> : 
                          tabValue === 3?
                            <FormContainer editChallenge={1} margin={1} marginTop={1} background={challenge.is_joined === 1&&challenge.challenge_status!="over"?0:1}>
                              <TopContainer border={challenge.is_joined === 1 && challenge.challenge_status!="over" ?0:1}>
                                <h3>{this.props.t("Chat Group")}</h3>
                                <div>
                                  {challengeAttendees.length > 0 && <img src={`${ImageUrl}/${challengeAttendees[0].profile_image}`} />}
                                  {challengeAttendees.length > 1 && <img src={`${ImageUrl}/${challengeAttendees[1].profile_image}`} />}
                                  {challengeAttendees.length > 2 && <img src={`${ImageUrl}/${challengeAttendees[2].profile_image}`} />}
                                  {challengeAttendees.length > 3 && <img src={`${ImageUrl}/${challengeAttendees[3].profile_image}`} />}
                                  {challengeAttendees.length > 4 && <img src={`${ImageUrl}/${challengeAttendees[4].profile_image}`} />}
                                  {challengeAttendees.length > 5 && <div>{challengeAttendees.length - 5}+</div>}
                                </div>
                              </TopContainer>
                              <CommentContentContainer>
                                {challenge.is_joined === 1&& challenge.challenge_status!="over" &&<FieldContainer>
                                  <CommentContent>
                                    <ImageWrapper isCommentSection borderRadius={"3px"} style={{borderRadius:'50%'}}>
                                      {this.showProfileImage()}
                                    </ImageWrapper>
                                    <CommentInnerContainer style={{border:'none'}}>
                                      <div style={{width:'530px',background:"#faf9f4",border:"1px solid #afcbd3",color:"#005c87", borderRadius:"20px"}}>
                                        {imgSrc && imgSrc.length >= 1 &&
                                        <InputImage className="InputImageSection">
                                          {imgSrc && imgSrc.length >= 1 ? <img src={imgSrc} alt="uploadable" /> : ''}
                                          {imgSrc && imgSrc.length >= 1 ? <button onClick={() => this.removeImage()}><span className="InputImageSpan">X</span></button> : ''}
                                        </InputImage>}

                                        <CommonTextArea
                                          placeholder={this.props.t("Write your comment here...")}
                                          onChange={(e) => this.onChange(e)}
                                          value={comment}
                                          rows={comment.length >= 120 ? "5" : "2"}
                                          data-gramm_editor="false"
                                          showEmojiPicker={this.showEmojiPicker}
                                          onClick={(e) => this.onChange(e)}
                                          style={{marginTop:"0px",display:'flex',alignItems:"center",background:"#faf9f4",border:"none",borderRadius:"20px",color:"#005c87",float:"inherit",padding:"14px 15px",paddingTop:comment.length == "" ? "20px" : "10px",paddingBottom:comment.length == "" ? '5px': '10px',placeholderColor:"#649bb3"}}
                                        />
                                      </div>
                                      <div style={{marginLeft:'15px',position:'relative'}}>
                                        {this.state.displayEmoji ? <SocialFeedsEmojiPicker selectEmoji={this.onEmojiSelect} emojiRef={this.state.emojiRef} hidePicker={this.hideEmojiPicker}/> : null}
                                        <IconContainerNew cameraIcon style={{marginLeft:'15px',display:'flex',alignItems:'center',marginBottom:'4px'}}>
                                          <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t("Emoji"))}>
                                            <img style={{ marginLeft: "15px",marginTop:"10px",cursor: "pointer",width:"46px", height:"46px" }} onClick={this.showEmojiPicker} src={ImageUrl+"/images/new_emoji_icon.png"}></img>                                       
                                          </OverlayTrigger>
                                          <InputImageNew htmlFor="uploadPhoto" noMarginTop style={{background:"none",padding:'0px'}}>
                                            <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t("Attach photo"))}>
                                              <img src={ImageUrl+"/images/new_camera_icon.png"} style={{ marginLeft: "10px", cursor: "pointer",width:"46px", height:"46px" }} />
                                            </OverlayTrigger>
                                            <input
                                              id="uploadPhoto"
                                              type="file"
                                              name="image"
                                              accept=".jpeg, .png, .jpg*"
                                              multiple={false}
                                              onChange={(e) => this.onChangeImage(e)}
                                              onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                                            />
                                          </InputImageNew>
                                        </IconContainerNew>
                                      </div>
                                    </CommentInnerContainer>
                                  </CommentContent>
                                  {(comment || imgSrc.length >= 1) ? <PostButton color="#159fc9" style={{background:"#005c87",display:'flex',justifyContent:"center",alignItems:'center'}} onClick={() => this.postComment(challenge.id)}>{this.props.t("Post")} <img src='/public/images/SendPost.svg' /></PostButton> : null}

                                </FieldContainer>}
                                {(isLoading ? <Loading isHorizontal /> :
                                  (_.isEmpty(comments) ? <NoComments>{this.props.t("No comments to display")}</NoComments> :
                                    <BehaviorChallengeComments
                                      comments={comments}
                                      postReply={postReplyOnComment}
                                      id={challenge.id}
                                      replySuccess={replySuccess}
                                      history={this.props.history}
                                      isChallengeOrEvent={true}
                                    />
                                  )
                                )}
                              </CommentContentContainer>
                            </FormContainer>:null: 
                      challenge.challenge_type === 'new_group' ?
                        tabValue === 1 ?
                          <GroupOverViewScreen
                            challenge={challenge}
                            userId={userId}
                            profileImage={profileImage}
                            getUserOverviewDetails={getUserOverviewDetails}
                            location={location}
                            fetchTeamChallenge={fetchTeamChallenge}
                            getChallengeCsvData={getChallengeCsvData}
                            role={role}
                            userPermissions={userPermissions}
                            history={this.props.history}
                          />:
                          tabValue === 2 ?
                            <GroupChallengeLeaderboardV2
                              challenge={challenge}
                              location={location}
                              networkError={networkError}
                            />:
                            tabValue === 3 ?        
                              <FormContainer editChallenge={1} margin={1} marginTop={1} background={challenge.is_joined === 1 && challenge.challenge_status!="over"?0:1}>
                                <TopContainer border={challenge.is_joined === 1&&challenge.challenge_status!="over"?0:1}>
                                  <h3>{this.props.t("Chat Group")}</h3>
                                  <div>
                                    {challengeAttendees.length > 0 && <img src={`${ImageUrl}/${challengeAttendees[0].profile_image}`} />}
                                    {challengeAttendees.length > 1 && <img src={`${ImageUrl}/${challengeAttendees[1].profile_image}`} />}
                                    {challengeAttendees.length > 2 && <img src={`${ImageUrl}/${challengeAttendees[2].profile_image}`} />}
                                    {challengeAttendees.length > 3 && <img src={`${ImageUrl}/${challengeAttendees[3].profile_image}`} />}
                                    {challengeAttendees.length > 4 && <img src={`${ImageUrl}/${challengeAttendees[4].profile_image}`} />}
                                    {challengeAttendees.length > 5 && <div>{challengeAttendees.length - 5}+</div>}
                                  </div>
                                </TopContainer>
                                <CommentContentContainer>
                                  {challenge.is_joined === 1 &&challenge.challenge_status!="over"&&<FieldContainer>
                                    <CommentContent>
                                      <ImageWrapper isCommentSection borderRadius={"3px"} style={{borderRadius:'50%'}}>
                                        {this.showProfileImage()}
                                      </ImageWrapper>
                                      <CommentInnerContainer style={{border:'none'}}>
                                        <div style={{width:'530px',background:"#faf9f4",border:"1px solid #afcbd3",color:"#005c87", borderRadius:"20px"}}>
                                          {imgSrc && imgSrc.length >= 1 &&
                                        <InputImage className="InputImageSection">
                                          {imgSrc && imgSrc.length >= 1 ? <img src={imgSrc} alt="uploadable" /> : ''}
                                          {imgSrc && imgSrc.length >= 1 ? <button onClick={() => this.removeImage()}><span className="InputImageSpan">X</span></button> : ''}
                                        </InputImage>}

                                          <CommonTextArea
                                            placeholder={this.props.t("Write your comment here...")}
                                            onChange={(e) => this.onChange(e)}
                                            value={comment}
                                            rows={comment.length >= 120 ? "5" : "2"}
                                            data-gramm_editor="false"
                                            showEmojiPicker={this.showEmojiPicker}
                                            onClick={(e) => this.onChange(e)}
                                            style={{marginTop:"0px",display:'flex',alignItems:"center",background:"#faf9f4",border:"none",borderRadius:"20px",color:"#005c87",float:"inherit",padding:"14px 15px",paddingTop:comment.length == "" ? "20px" : "10px",paddingBottom:comment.length == "" ? '5px': '10px',placeholderColor:"#649bb3"}}
                                          />
                                        </div>
                                        <div style={{marginLeft:'15px',position:'relative'}}>
                                          {this.state.displayEmoji ? <SocialFeedsEmojiPicker selectEmoji={this.onEmojiSelect} emojiRef={this.state.emojiRef} hidePicker={this.hideEmojiPicker}/> : null}
                                          <IconContainerNew cameraIcon style={{marginLeft:'15px',display:'flex',alignItems:'center',marginBottom:'4px'}}>
                                            <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t("Emoji"))}>
                                              <img style={{ marginLeft: "15px",marginTop:"10px",cursor: "pointer",width:"46px", height:"46px" }} onClick={this.showEmojiPicker} src={ImageUrl+"/images/new_emoji_icon.png"}></img>                                       
                                            </OverlayTrigger>                                          
                                            <InputImageNew htmlFor="uploadPhoto" noMarginTop style={{background:"none",padding:'0px'}}>
                                              <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t("Attach photo"))}>
                                                <img src={ImageUrl+"/images/new_camera_icon.png"} style={{ marginLeft: "10px", cursor: "pointer",width:"46px", height:"46px" }} />
                                              </OverlayTrigger>
                                              <input
                                                id="uploadPhoto"
                                                type="file"
                                                name="image"
                                                accept=".jpeg, .png, .jpg*"
                                                multiple={false}
                                                onChange={(e) => this.onChangeImage(e)}
                                                onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                                              />
                                            </InputImageNew>
                                          </IconContainerNew>
                                        </div>
                                      </CommentInnerContainer>
                                    </CommentContent>
                                    {(comment || imgSrc.length >= 1) ? <PostButton color="#159fc9" style={{background:"#005c87",display:'flex',justifyContent:"center",alignItems:'center'}} onClick={() => this.postComment(challenge.id)}>{this.props.t("Post")} <img src='/public/images/SendPost.svg' /></PostButton> : null}
                                  </FieldContainer>}
                                  {(isLoading ? <Loading isHorizontal /> :
                                    (_.isEmpty(comments) ? <NoComments>{this.props.t("No comments to display")}</NoComments> :
                                      <BehaviorChallengeComments
                                        comments={comments}
                                        postReply={postReplyOnComment}
                                        id={challenge.id}
                                        replySuccess={replySuccess}
                                        history={this.props.history}
                                        isChallengeOrEvent={true}
                                      />
                                    )
                                  )}
                                </CommentContentContainer>
                              </FormContainer>:null  :    
                        challenge && challenge.challenge_type == 'group' && challenge.is_team_challenge !== 1 ?
                          tabValue === 1 ?
                            <GroupOverViewScreen
                              challenge={challenge}
                              userId={userId}
                              profileImage={profileImage}
                              getUserOverviewDetails={getUserOverviewDetails}
                              location={location}
                              fetchTeamChallenge={fetchTeamChallenge}
                              getChallengeCsvData={getChallengeCsvData}
                              role={role}
                              userPermissions={userPermissions}
                              history={this.props.history}
                            />:
                            tabValue === 2 ?
                              <ClassicChallengeLeaderboard
                                challenge={challenge}
                                fetchChallengeCompetitors={fetchChallengeCompetitors}
                                location={location}
                                networkError={networkError}
                                userId={userId}
                                profileImage={profileImage}
                                fetchTeamChallenge={fetchTeamChallenge}
                              />:
                              tabValue === 3?
                                <FormContainer editChallenge={1} margin={1} marginTop={1} background={challenge.is_joined === 1&&challenge.challenge_status!="over"?0:1}>
                                  <TopContainer border={challenge.is_joined === 1&&challenge.challenge_status!="over"?0:1}>
                                    <h3>{this.props.t("Chat Group")}</h3>
                                    <div>
                                      {challengeAttendees.length > 0 && <img src={`${ImageUrl}/${challengeAttendees[0].profile_image}`} />}
                                      {challengeAttendees.length > 1 && <img src={`${ImageUrl}/${challengeAttendees[1].profile_image}`} />}
                                      {challengeAttendees.length > 2 && <img src={`${ImageUrl}/${challengeAttendees[2].profile_image}`} />}
                                      {challengeAttendees.length > 3 && <img src={`${ImageUrl}/${challengeAttendees[3].profile_image}`} />}
                                      {challengeAttendees.length > 4 && <img src={`${ImageUrl}/${challengeAttendees[4].profile_image}`} />}
                                      {challengeAttendees.length > 5 && <div>{challengeAttendees.length - 5}+</div>}
                                    </div>

                                  </TopContainer>
                                  <CommentContentContainer>
                                    { challenge.is_joined === 1 && challenge.challenge_status!="over"&&<FieldContainer>
                                      <CommentContent>
                                        <ImageWrapper isCommentSection borderRadius={"3px"}>
                                          {this.showProfileImage()}
                                        </ImageWrapper>
                                        <CommentInnerContainer style={{border:'none'}}>
                                          <div style={{width:'530px',background:"#faf9f4",border:"1px solid #afcbd3",color:"#005c87", borderRadius:"20px"}}>
                                            {imgSrc && imgSrc.length >= 1 &&
                                            <InputImage className="InputImageSection">
                                              {imgSrc && imgSrc.length >= 1 ? <img src={imgSrc} alt="uploadable" /> : ''}
                                              {imgSrc && imgSrc.length >= 1 ? <button onClick={() => this.removeImage()}><span className="InputImageSpan">X</span></button> : ''}
                                            </InputImage>}

                                            <CommonTextArea
                                              placeholder={this.props.t("Write your comment here...")}
                                              onChange={(e) => this.onChange(e)}
                                              value={comment}
                                              rows={comment.length >= 120 ? "5" : "2"}
                                              data-gramm_editor="false"
                                              showEmojiPicker={this.showEmojiPicker}
                                              onClick={(e) => this.onChange(e)}
                                              style={{marginTop:"0px",display:'flex',alignItems:"center",background:"#faf9f4",border:"none",borderRadius:"20px",color:"#005c87",float:"inherit",padding:"14px 15px",paddingTop:comment.length == "" ? "20px" : "10px",paddingBottom:comment.length == "" ? '5px': '10px',placeholderColor:"#649bb3"}}
                                            />
                                          </div>
                                          <div style={{marginLeft:'15px',position:'relative'}}>
                                            {this.state.displayEmoji ? <SocialFeedsEmojiPicker selectEmoji={this.onEmojiSelect} emojiRef={this.state.emojiRef} hidePicker={this.hideEmojiPicker}/> : null}
                                            <IconContainerNew cameraIcon style={{marginLeft:'15px',display:'flex',alignItems:'center',marginBottom:'4px'}}>
                                              <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t("Emoji"))}>
                                                <img style={{ marginLeft: "15px",marginTop:"10px", cursor: "pointer",width:"46px", height:"46px" }} onClick={this.showEmojiPicker} src={ImageUrl+"/images/new_emoji_icon.png"}></img>
                                              </OverlayTrigger>                                             
                                              <InputImageNew htmlFor="uploadPhoto" noMarginTop style={{background:"none",padding:'0px'}}>
                                                <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t("Attach photo"))}>
                                                  <img src={ImageUrl+"/images/new_camera_icon.png"} style={{ marginLeft: "10px", cursor: "pointer",width:"46px", height:"46px" }}/>
                                                </OverlayTrigger>  
                                                <input
                                                  id="uploadPhoto"
                                                  type="file"
                                                  name="image"
                                                  accept=".jpeg, .png, .jpg*"
                                                  multiple={false}
                                                  onChange={(e) => this.onChangeImage(e)}
                                                  onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                                                />
                                              </InputImageNew>
                                            </IconContainerNew>
                                          </div>
                                        </CommentInnerContainer>
                                      </CommentContent>
                                      {(comment || imgSrc.length >= 1) ? <PostButton  style={{background:"#005c87",display:'flex',justifyContent:"center",alignItems:'center'}} color="#159fc9" onClick={() => this.postComment(challenge.id)}>{this.props.t("Post")} <img src='/public/images/SendPost.svg' /></PostButton> : null}
                                    </FieldContainer> }
                                    {(isLoading ? <Loading isHorizontal /> :
                                      (_.isEmpty(comments) ? <NoComments>{this.props.t("No comments to display")}</NoComments> :
                                        <BehaviorChallengeComments
                                          comments={comments}
                                          postReply={postReplyOnComment}
                                          id={challenge.id}
                                          replySuccess={replySuccess}
                                          history={this.props.history}
                                          isChallengeOrEvent={true}
                                        />
                                      )
                                    )}
                                  </CommentContentContainer>
                                </FormContainer>:null                  
                          : 
                          challenge && challenge.challenge_type == 'behavior' ?
                            <StyledTabsNew defaultActiveKey={1} id="styledTabs" style={{ display: "flex", background:"white", padding:"10px 15px" }}>
                              <Tab eventKey={1} title={this.props.t("Overview")} tabClassName="first-tab">
                                {
                                  <MyTaskTabV2
                                    challenge={challenge}
                                    userId={userId}
                                    profileImage={profileImage}
                                    location={location}
                                    role={role}
                                    history={this.props.history}
                                    updateDailyTask={updateDailyTask}
                                    setObjIndex={this.setObjIndex}
                                    objIndex={objIndex}
                                    taskUpdated={taskUpdated}
                                    selectedDate={selectedDate}
                                    userPermissions={userPermissions}
                                  />
                                }
                              </Tab>
                              {
                                <Tab eventKey={2} title={this.props.t("Progress")} tabClassName="second-tab">
                                  {
                                    <MyProgressTab
                                      challenge={challenge}
                                      location={location}
                                      networkError={networkError}
                                      userId={userId}
                                      profileImage={profileImage}
                                    />
                                  }
                                </Tab>
                              }
                              {challenge && challenge['challenge_type'] == 'behavior' && (challenge.challenge_status === 'joined'||challenge.challenge_status === 'over') &&
                                <Tab eventKey={3} title={this.props.t("Chat")} tabClassName="third-tab">
                                  {<FormContainer editChallenge={1} margin={1} marginTop={1}>
                                    <TopContainer>
                                      <h3>{this.props.t("Chat Group")}</h3>
                                      <div>
                                        {challengeAttendees.length > 0 && <img src={`${ImageUrl}/${challengeAttendees[0].profile_image}`} />}
                                        {challengeAttendees.length > 1 && <img src={`${ImageUrl}/${challengeAttendees[1].profile_image}`} />}
                                        {challengeAttendees.length > 2 && <img src={`${ImageUrl}/${challengeAttendees[2].profile_image}`} />}
                                        {challengeAttendees.length > 3 && <img src={`${ImageUrl}/${challengeAttendees[3].profile_image}`} />}
                                        {challengeAttendees.length > 4 && <img src={`${ImageUrl}/${challengeAttendees[4].profile_image}`} />}
                                        {challengeAttendees.length > 5 && <div>{challengeAttendees.length - 5}+</div>}
                                      </div>

                                    </TopContainer>
                                    <CommentContentContainer>
                                      <FieldContainer>
                                        <CommentContent>
                                          <ImageWrapper isCommentSection borderRadius={"3px"}>
                                            {this.showProfileImage()}
                                          </ImageWrapper>
                                          <CommentInnerContainer style={{border:'none'}}>
                                            <div style={{width:'530px',background:"#faf9f4",border:"1px solid #afcbd3",color:"#005c87", borderRadius:"20px"}}>
                                              {imgSrc && imgSrc.length >= 1 &&
                                              <InputImage className="InputImageSection">
                                                {imgSrc && imgSrc.length >= 1 ? <img src={imgSrc} alt="uploadable" /> : ''}
                                                {imgSrc && imgSrc.length >= 1 ? <button onClick={() => this.removeImage()}><span className="InputImageSpan">X</span></button> : ''}
                                              </InputImage>}

                                              <CommonTextArea
                                                placeholder={this.props.t("Write your comment here...")}
                                                onChange={challenge.challenge_status === 'over'?null: (e) => this.onChange(e)}
                                                value={comment}
                                                rows={comment.length >= 120 ? "5" : "2"}
                                                data-gramm_editor="false"
                                                showEmojiPicker={this.showEmojiPicker}
                                                onClick={(e) => this.onChange(e)}
                                                style={{marginTop:"0px",display:'flex',alignItems:"center",background:"#faf9f4",border:"none",borderRadius:"20px",color:"#005c87",float:"inherit",padding:"12px",placeholderColor:"#649bb3", cursor:challenge.challenge_status === 'over'?"not-allowed":"pointer"}}
                                              />
                                            </div>
                                            <div style={{marginLeft:'15px',position:'relative'}}>
                                              {this.state.displayEmoji ? <SocialFeedsEmojiPicker selectEmoji={this.onEmojiSelect} emojiRef={this.state.emojiRef} hidePicker={this.hideEmojiPicker}/> : null}

                                              <IconContainerNew cameraIcon style={{marginLeft:'15px',display:'flex',alignItems:'center',marginBottom:'4px'}}>
                                                <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t("Emoji"))}>
                                                  <img style={{ marginLeft: "15px",marginTop:"10px",cursor: "pointer",width:"46px", height:"46px" }} onClick={challenge.challenge_status === 'over'?null:()=>this.showEmojiPicker()} src={ImageUrl+"/images/new_emoji_icon.png"}></img>
                                                </OverlayTrigger>
                                                <InputImageNew htmlFor="uploadPhoto" noMarginTop style={{background:"none",padding:'0px'}}>
                                                  <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t("Attach photo"))}>
                                                    <img src={ImageUrl+"/images/new_camera_icon.png"} style={{ marginLeft: "10px", cursor: "pointer",width:"46px", height:"46px" }} />
                                                  </OverlayTrigger>
                                                  <input
                                                    id="uploadPhoto"
                                                    type="file"
                                                    name="image"
                                                    accept=".jpeg, .png, .jpg*"
                                                    multiple={false}
                                                    onChange={challenge.challenge_status === 'over'?null:(e) => this.onChangeImage(e)}
                                                    onClick={challenge.challenge_status === 'over'?null:(e) => e.target.files[0] && this.onChangeImage(e)}
                                                  />
                                                </InputImageNew>
                                              </IconContainerNew>
                                            </div>
                                          </CommentInnerContainer>
                                        </CommentContent>
                                        {(comment || imgSrc.length >= 1) ? <PostButton color="#159fc9" style={{background:"#005c87",display:'flex',justifyContent:"center",alignItems:'center'}} onClick={() => this.postComment(challenge.id)}>{this.props.t("Post")} <img src='/public/images/SendPost.svg' /></PostButton> : null}
                                      </FieldContainer>
                                      {(isLoading ? <Loading isHorizontal /> :
                                        (_.isEmpty(comments) ? <NoComments>{this.props.t("No comments to display")}</NoComments> :
                                          <BehaviorChallengeComments
                                            comments={comments}
                                            postReply={postReplyOnComment}
                                            id={challenge.id}
                                            replySuccess={replySuccess}
                                            history={this.props.history}
                                            isChallengeOrEvent={true}
                                          />
                                        )
                                      )}
                                    </CommentContentContainer>
                                  </FormContainer>
                                  }
                                </Tab>
                              }
                            </StyledTabsNew> :
                            //Team Challenge detail tabs starts
                            challenge && challenge.challenge_type == 'group' && challenge.is_team_challenge === 1 ?
                              tabValue === 1 ?
                                <TeamOverview
                                  challenge={challenge}
                                  teamChallenge={teamChallenge}
                                  userId={userId}
                                  profileImage={profileImage}
                                  getUserOverviewDetails={getUserOverviewDetails}
                                  location={location}
                                  fetchTeamChallenge={fetchTeamChallenge}
                                  fetchChallengeCompetitors={fetchChallengeCompetitors}
                                  getChallengeCsvData={getChallengeCsvData}
                                  role={role}
                                  userPermissions={userPermissions}
                                  history={this.props.history}
                                  user={user}
                                />:
                                tabValue === 2 ?
                                  <TeamLeaderboard
                                    challenge={challenge}
                                    teamChallenge={teamChallenge}
                                    fetchChallengeCompetitors={fetchChallengeCompetitors}
                                    location={location}
                                    networkError={networkError}
                                    userId={userId}
                                    profileImage={profileImage}
                                    fetchTeamChallenge={fetchTeamChallenge}
                                  />:
                                  tabValue === 3 ?
                                    <FormContainer editChallenge={1} margin={1} marginTop={1} background={challenge.is_joined === 1&&challenge.challenge_status!="over"?0:1}>
                                      <TopContainer border={challenge.is_joined === 1&&challenge.challenge_status!="over"?0:1}>
                                        <h3>{this.props.t("Chat Group")}</h3>
                                        <div>
                                          {challengeAttendees.length > 0 && <img src={`${ImageUrl}/${challengeAttendees[0].profile_image}`} />}
                                          {challengeAttendees.length > 1 && <img src={`${ImageUrl}/${challengeAttendees[1].profile_image}`} />}
                                          {challengeAttendees.length > 2 && <img src={`${ImageUrl}/${challengeAttendees[2].profile_image}`} />}
                                          {challengeAttendees.length > 3 && <img src={`${ImageUrl}/${challengeAttendees[3].profile_image}`} />}
                                          {challengeAttendees.length > 4 && <img src={`${ImageUrl}/${challengeAttendees[4].profile_image}`} />}
                                          {challengeAttendees.length > 5 && <div>{challengeAttendees.length - 5}+</div>}
                                        </div>
                                      </TopContainer>
                                      <CommentContentContainer>
                                        {challenge.is_joined === 1 &&challenge.challenge_status!="over"&&<FieldContainer>
                                          {this.state.displayEmoji ? <SocialFeedsEmojiPicker selectEmoji={this.onEmojiSelect} emojiRef={this.state.emojiRef} hidePicker={this.hideEmojiPicker}/> : null}
                                          <CommentContent>
                                            <ImageWrapper isCommentSection borderRadius={"3px"}>
                                              {this.showProfileImage()}
                                            </ImageWrapper>
                                            <CommentInnerContainer style={{border:'none'}}>
                                              <div style={{width:'530px',background:"#faf9f4",border:"1px solid #afcbd3",color:"#005c87", borderRadius:"20px"}}>
                                                {imgSrc && imgSrc.length >= 1 &&
                                            <InputImage className="InputImageSection">
                                              {imgSrc && imgSrc.length >= 1 ? <img src={imgSrc} alt="uploadable" /> : ''}
                                              {imgSrc && imgSrc.length >= 1 ? <button onClick={() => this.removeImage()}><span className="InputImageSpan">X</span></button> : ''}
                                            </InputImage>}

                                                <CommonTextArea
                                                  placeholder={this.props.t("Write your comment here...")}
                                                  onChange={(e) => this.onChange(e)}
                                                  value={comment}
                                                  rows={comment.length >= 120 ? "5" : "2"}
                                                  data-gramm_editor="false"
                                                  showEmojiPicker={this.showEmojiPicker}
                                                  onClick={(e) => this.onChange(e)}
                                                  style={{marginTop:"0px",display:'flex',alignItems:"center",background:"#faf9f4",border:"none",borderRadius:"20px",color:"#005c87",float:"inherit",padding:"14px 15px",paddingTop:comment.length == "" ? "20px" : "10px",paddingBottom:comment.length == "" ? '5px': '10px',placeholderColor:"#649bb3"}}
                                                />
                                              </div>
                                              <div style={{marginLeft:'15px',position:'relative'}}>
                                                <IconContainerNew cameraIcon style={{marginLeft:'15px',display:'flex',alignItems:'center',marginBottom:'4px'}}>
                                                  <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t("Emoji"))}>
                                                    <img style={{ marginLeft: "15px",marginTop:"10px",cursor: "pointer",width:"46px", height:"46px" }} onClick={this.showEmojiPicker} src={ImageUrl+"/images/new_emoji_icon.png"}></img>                                       
                                                  </OverlayTrigger>                                           
                                                  <InputImageNew htmlFor="uploadPhoto" noMarginTop style={{background:"none",padding:'0px'}}>
                                                    <OverlayTrigger placement="bottom" overlay={this.popover(this.props.t("Attach photo"))}>
                                                      <img src={ImageUrl+"/images/new_camera_icon.png"} style={{ marginLeft: "10px", cursor: "pointer",width:"46px", height:"46px" }} />
                                                    </OverlayTrigger>
                                                    <input
                                                      id="uploadPhoto"
                                                      type="file"
                                                      name="image"
                                                      accept=".jpeg, .png, .jpg*"
                                                      multiple={false}
                                                      onChange={(e) => this.onChangeImage(e)}
                                                      onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                                                    />
                                                  </InputImageNew>
                                                </IconContainerNew>
                                              </div>
                                            </CommentInnerContainer>
                                          </CommentContent>
                                          {(comment || imgSrc.length >= 1) ? <PostButton color="#159fc9" style={{background:"#005c87",display:'flex',justifyContent:"center",alignItems:'center'}} onClick={() => this.postComment(challenge.id)}>{this.props.t("Post")} <img src='/public/images/SendPost.svg' /></PostButton> : null}
                                        </FieldContainer>}
                                        {(isLoading ? <Loading isHorizontal /> :
                                          (_.isEmpty(comments) ? <NoComments>{this.props.t("No comments to display")}</NoComments> :
                                            <BehaviorChallengeComments
                                              comments={comments}
                                              postReply={postReplyOnComment}
                                              id={challenge.id}
                                              replySuccess={replySuccess}
                                              history={this.props.history}
                                              isChallengeOrEvent={true}
                                            />
                                          )
                                        )}
                                      </CommentContentContainer>
                                    </FormContainer>:null:null}
                </ChallengeInfoGridNew>
                {
                  challenge ? <RightContainer margin={(challenge['challenge_type'] === 'behavior' || challenge['challenge_type'] === 'new_group' || challenge['challenge_type'] === 'group' || challenge['challenge_type'] === 'survivor') ? "0px" : "0px"}>
                    {challenge && challenge['challenge_type'] == 'behavior' &&
                      <RightSideSection
                        challenge={challenge}
                        userId={userId}
                        profileImage={profileImage}
                        location={location}
                        role={role}
                        history={this.props.history}
                        updateDailyTask={updateDailyTask}
                        setObjIndex={this.setObjIndex}
                        objIndex={objIndex}
                        taskUpdated={taskUpdated}
                        selectedDate={selectedDate}
                        userPermissions={userPermissions}
                      />}
                    <CommentGridNew width={challenge && challenge['challenge_type'] == 'behavior' ? 1 : 0}>
                      {<div className="contents-top">
                        {challenge.challenge_type === 'behavior' && challenge.challenge_status !== 'over' ?
                          <ButtonContainer>
                            {challenge.is_joined === 1 ?null:
                              <EventsButton backgroundColor={"#005C87"} color={"white"} onClick={isButtonDisabled?null:() => this.joinChallenge(challenge.id, joinOrLeave)} padding={'15px 8px 15px 8px'} font={"18px"} margin={"25px"} boxShadow="0px 8px 24px 0px #005C8780">
                                {this.props.t("Join Challenge")}
                              </EventsButton>}
                            {!_.isNull(challenge.introduction_video) &&
                              <EventsButton backgroundColor={"linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)"} onClick={this.changeModal} padding={'15px 8px 15px 8px'} font={"18px"}>
                                {this.props.t("Play Intro Video")}
                              </EventsButton>}
                          </ButtonContainer> : null}
                        {challenge && challenge.challenge_status === 'over' && challenge.challenge_type === 'behavior' &&
                          <EventsButton backgroundColor={'#f6dfd8'} color={"#005C87"} padding={'15px 8px 15px 8px'} font={"18px"} cursor={"1"}>
                            {this.props.t("Challenge Completed")}
                          </EventsButton>}
                        {/* {challenge && challenge.challenge_status === 'over' && challenge['challenge_type'] !== 'behavior' && (DistanceUnit === "KILOMETER" ? <CompletedChallengeCard dangerouslySetInnerHTML={{ __html: challenge.metric_message }} /> : <CompletedChallengeCard dangerouslySetInnerHTML={{ __html: challenge.message }} />)} */}
                        {challenge && (challenge['challenge_type'] == 'behavior') &&
                          <AboutContainer marginTop={'0px 0px 15px 0px'} color={"#0D4270"}>
                            <div>{this.props.t("About The Challenge")}</div>
                            <div >{parse(challenge.body)}</div>
                          </AboutContainer>}
                        {/* {challenge.is_joined === 1 && challenge.challenge_type === 'behavior'?
                          <EventsButton backgroundColor={"#005C870D"} color={"#005C87"} padding={'15px 8px 15px 8px'} font={"18px"} cursor={"1"} margin={"25px"}>
                            {"You Joined This Challenge"}
                          </EventsButton>:null}   */}
                        {showVideo && !_.isNull(challenge.introduction_video) && !_.isNull(challenge.video_title) && <DemoPopup showModal={showVideo} onClose={this.changeModal} videoUrl={challenge.introduction_video} VideoTitle={challenge.video_title} />}
                        {challenge && challenge['challenge_type'] == 'behavior' || challenge['challenge_type'] === 'new_group' || challenge['challenge_type'] === 'group' || challenge['challenge_type'] === 'survivor' ? null :
                          <CommentContentContainer>
                            <ChatGroup width={challenge && challenge['challenge_type'] == 'behavior' ? "100%" : "65%"}>
                              <div>
                                <span>{this.props.t("Chat Group")}</span>
                                <div>{challenge.is_dummy_challenge === 1 ? '6320' : challengeAttendees.length} <span>{challengeAttendees.length > 1 ? 'Members' : 'Member'}</span></div>
                              </div>
                              <ChatUsers>
                                {this.getListOfUser()}
                              </ChatUsers>
                            </ChatGroup>
                            <FieldContainer commentsSection border={1}>
                              <ChatCommentContent>
                                <ChatTextArea
                                  placeholder={this.props.t("Write your comment here...")}
                                  rows={1}
                                  onChange={(e) => this.onChange(e)}
                                  value={comment}
                                  data-gramm_editor="false"
                                  showEmojiPicker={this.showEmojiPicker}
                                  onClick={(e) => this.onChange(e)}
                                  style={{paddingRight:"100px"}}
                                />
                                {this.state.displayEmoji ? <SocialFeedsEmojiPicker selectEmoji={this.onEmojiSelect} emojiRef={this.state.emojiRef} hidePicker={this.hideEmojiPicker}/> : null}
                                <div className="InputImageSection">
                                  {imgSrc && imgSrc.length >= 1 ? <img src={imgSrc} alt="uploadable" /> : ''}
                                  {imgSrc && imgSrc.length >= 1 ? <button onClick={() => this.removeImage()}>X</button> : ''}
                                </div>
                                <IconContainer cameraIcon>
                                  <img style={{ marginTop: '8px', cursor: 'pointer'}} onClick={this.showEmojiPicker} src='/public/images/emoji_icon.svg'></img>                                  
                                  <InputImage htmlFor="uploadPhoto" noMarginTop>
                                    <i className="fas fa-camera" />
                                    <input
                                      id="uploadPhoto"
                                      type="file"
                                      name="image"
                                      accept=".jpeg, .png, .jpg*"
                                      multiple={false}
                                      onChange={(e) => this.onChangeImage(e)}
                                      onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                                    />
                                  </InputImage>
                                </IconContainer>
                                {comment || imgSrc.length >= 1 ? <span onClick={() => this.postComment(challenge.id)} disabled={!comment.trim()}>
                                  <img src={'/public/images/ClassicChallengeV2/send-button.png'} alt={'post'} />
                                </span> : ''}
                              </ChatCommentContent>
                            </FieldContainer>
                            {(isLoading ? <Loading isHorizontal /> :
                              (_.isEmpty(comments) ? <NoComments>{this.props.t("No comments to display")}</NoComments> :
                                <CommentsV2
                                  comments={comments}
                                  postReply={postReplyOnComment}
                                  id={challenge.id}
                                  replySuccess={replySuccess}
                                  history={this.props.history}
                                  isChallengeOrEvent={true}
                                />
                              )
                            )}
                          </CommentContentContainer>}
                        {challenge && (challenge['challenge_type'] === 'survivor') &&
                          <SurvivorRightSection
                            challenge={challenge}
                            userId={userId}
                            profileImage={profileImage}
                            getUserOverviewDetails={getUserOverviewDetails}
                            location={location}
                            networkError={networkError}
                            history={this.props.history}
                            role={role}
                          />}
                        {challenge && (challenge['challenge_type'] === 'new_group') &&
                          <GroupRightSection
                            challenge={challenge}
                            userId={userId}
                            profileImage={profileImage}
                            getUserOverviewDetails={getUserOverviewDetails}
                            location={location}
                            networkError={networkError}
                            history={this.props.history}
                            role={role}
                          />}
                        {challenge && (challenge['challenge_type'] === 'group')&& challenge.is_team_challenge !== 1 && 
                          <ClassicRightSection
                            challenge={challenge}
                            userId={userId}
                            profileImage={profileImage}
                            getUserOverviewDetails={getUserOverviewDetails}
                            location={location}
                            networkError={networkError}
                            history={this.props.history}
                            role={role}
                            fetchChallengeCompetitors={fetchChallengeCompetitors}
                          />}
                        {challenge && (challenge['challenge_type'] === 'group') && challenge.is_team_challenge === 1 &&
                          <TeamChallengeRight
                            challenge={challenge}
                            teamChallenge={teamChallenge}
                            userId={userId}
                            profileImage={profileImage}
                            getUserOverviewDetails={getUserOverviewDetails}
                            location={location}
                            networkError={networkError}
                            history={this.props.history}
                            role={role}
                            fetchChallengeCompetitors={fetchChallengeCompetitors}
                            user={user}
                          />}
                      </div>}
                    </CommentGridNew></RightContainer> : null
                }
              </ChallengeGrid>
              {/* <StartChallengePopUp onClose={this.props.setChallengeInviteStatus} showModal={this.props.inviteAmigoStatus} userName={user['fname']} challenge={challenge} history={this.props.history} inviteAmigoStatus={1} /> */}
              {showDeletePopup&& 
                <DeleteAndLeavePopup
                  leaveId={leaveChallengeId}
                  deleteId={deleteChallengeId}
                  showConfirmPopupDelete={showDeletePopup}
                  showConfirmPopup={showLeavePopup}
                  showHideDeletePopup={this.hideDeletePopup}
                  showHideLeavePopup={this.hideLeavePopup}
                  deleteData={this.deleteTeam}
                  leaveData={this.leaveTeam}
                  userName={user && user.fname}
                  showDelete={true}
                />}
              {showLeavePopup&& 
                <DeleteAndLeavePopup
                  leaveId={leaveChallengeId}
                  showConfirmPopup={showLeavePopup}
                  showHideLeavePopup={this.hideLeavePopup}
                  leaveData={this.leaveTeam}
                  userName={user && user.fname}
                />}
              <Suspense fallback={<Waiting/>}><InviteAmigoPopUp userId={userId} history={this.props.history} showModal={this.state.showInviteAmigoPopup} hideInviteAmigoPopup={this.showHideInviteAmigoPopup} onHide={this.showHideInviteAmigoPopup} challengeId={challenge.id} plusAmigoButton={1}/></Suspense>  
              {printChallengeCSV && <CSVList Title={`${fileTitle}_${challenge && challenge.id}`} removeCSVData={this.printAction} headers={this.getCSVHeaders()} data={updatedChallengeCSV} />}
            </LayoutNew>
          </Main>
        }
      </React.Fragment>
    )
  }
}

ChallengeDetails.propTypes = {
  location: PropTypes.object.isRequired,
  challenge: PropTypes.object,
  getChallengeDetails: PropTypes.func.isRequired,
  addCommentOnChallenge: PropTypes.func.isRequired,
  getCommentsOnChallenge: PropTypes.func.isRequired,
  comments: PropTypes.array,
  networkError: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  fetchChallengeCompetitors: PropTypes.func.isRequired,
  postReplyOnComment: PropTypes.func.isRequired,
  replySuccess: PropTypes.string,
  fetchUserProfileImage: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  fetchUserId: PropTypes.func.isRequired,
  userId: PropTypes.number,
  fetchTeamChallenge: PropTypes.func.isRequired,
  history: PropTypes.object,
  role: PropTypes.string,
  updateDailyTask: PropTypes.func,
  weekDashboard: PropTypes.number,
  changeWeekDashboard: PropTypes.func,
  match: PropTypes.object.isRequired,
  challengeAttendees: PropTypes.array,
  getUserListOfChallenges: PropTypes.func,
  getUserLastSync: PropTypes.func,
  syncTime: PropTypes.string,
  getUserOverviewDetails: PropTypes.func,
  getChallengeCsvData: PropTypes.func,
  emptyChallengeDetails: PropTypes.func,
  toggleDummyChallenges: PropTypes.func,
  userPermissions: PropTypes.array,
  inviteAmigoStatus: PropTypes.bool,
  setChallengeInviteStatus: PropTypes.func,
  user: PropTypes.object,
  joinOrLeaveChallenge: PropTypes.func,
  teamChallenge:PropTypes.object,
  deleteChallengeById: PropTypes.func,
  getInviteAmigosList: PropTypes.func,
  challengeCSVStatus: PropTypes.object,
  challengeCsvData: PropTypes.array,
  UpdateUserLiveChallengeStatus: PropTypes.func,
  t: PropTypes.func,
  isAdminChallenge: PropTypes.bool
};

const mapStateToProps = (state) => ({
  challenge: state.challenges.challengeDetails,
  teamChallenge:state.challenges.teamChallengeDetails,
  comments: state.challenges.comments,
  isLoading: state.challenges.isLoading,
  networkError: state.challenges.networkError,
  replySuccess: state.challenges.replySuccess,
  profileImage: state.profileData.profileImage,
  userId: state.profileData.userId,
  role: state.auth.role,
  weekDashboard: state.challenges.weekDashboard,
  challengeAttendees: state.challenges.challengeAttendees,
  syncTime: state.challenges.syncTime,
  userPermissions: state.profileData.userPermissions,
  inviteAmigoStatus: state.challenges.inviteAmigoStatus,
  user: state.profileData.user,
  challengeCSVStatus: state.challenges.challengeCSVStatus,
  challengeCsvData: state.challenges.challengeCsvData,
});

const mapDispatchToProps = (dispatch) => ({
  getChallengeDetails: (id) => dispatch(getChallengeDetails(id)),
  addCommentOnChallenge: (commentData) => dispatch(addCommentOnChallenge(commentData)),
  getCommentsOnChallenge: (id) => dispatch(getCommentsOnChallenge(id)),
  fetchChallengeCompetitors: (challengeId, challengeType, pageLimit, pageNumber, selectedOption, cb) => dispatch(getChallengeCompetitors(challengeId, challengeType, pageLimit, pageNumber, selectedOption, cb)),
  getUserOverviewDetails: (challengeId, challengeType, userId) => dispatch(getUserOverviewDetails(challengeId, challengeType, userId)),
  postReplyOnComment: (commentId, reply, challengeId) => dispatch(addCommentReplyOnChallenge(commentId, reply, challengeId)),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  fetchUserId: () => dispatch(getUserId()),
  fetchTeamChallenge: (challengeId, challengeType) => dispatch(getTeamChallenge(challengeId, challengeType)),
  updateDailyTask: (obj) => dispatch(updateDailyTask(obj)),
  changeWeekDashboard: (num) => dispatch(changeWeekDashboard(num)),
  getUserListOfChallenges: (challengeId) => dispatch(getUserListOfChallenges(challengeId)),
  getUserLastSync: () => dispatch(getUserLastSync()),
  getChallengeCsvData: (challengeId, challengeType, selectedOption) => dispatch(getChallengeCsvData(challengeId, challengeType, selectedOption)),
  emptyChallengeDetails: () => dispatch(emptyChallengeDetails()),
  toggleDummyChallenges: (bool) => dispatch(toggleDummyChallenges(bool)),
  setChallengeInviteStatus: (bool, challengeId) => dispatch(setChallengeInviteStatus(bool, challengeId)),
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
  deleteChallengeById: (id) => dispatch(deleteChallengeById(id)),
  getInviteAmigosList: (userID, challengeId) => dispatch(getInviteAmigosList(userID, challengeId)),
  UpdateUserLiveChallengeStatus: () => dispatch(UpdateUserLiveChallengeStatus()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChallengeDetails)));
