import React, {Component} from 'react';
import {BottomLabelDonutContainerV1, GoodCholesterolContainer, SmallBoxHeaderV1, NoData, BorderBottom} from "./styles";
import DonutChart from "../DonutChartV2";
import PropTypes from "prop-types";
import DOMPurify from 'dompurify';
import styled from 'styled-components';

const SanitizedHeaderTitle = styled.div``;

export default class Glucose extends Component{
  constructor(){
    super();
    this.headerTitleRef = React.createRef();
    this.state = {
      options: ["2019","2018","2017"],
      selectedIndex: 0,
      selectedValue: 2019
    }
  }

  componentDidMount(){
    this.setSanitizedHeaderTitle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.headerTitle !== this.props?.headerTitle) {
      this.setSanitizedHeaderTitle();
    }
  }

  setSanitizedHeaderTitle() {
    if (this.headerTitleRef.current) {
      const sanitizedHeaderTitle = DOMPurify.sanitize(this.props?.headerTitle);
      const fragment = document.createRange().createContextualFragment(sanitizedHeaderTitle);
      while (this.headerTitleRef.current.firstChild) {
        this.headerTitleRef.current.removeChild(this.headerTitleRef.current.firstChild);
      }
      this.headerTitleRef.current.appendChild(fragment);
    }
  }

  toggleRadioBtn = (index) =>{
    this.setState({
      selectedIndex: index,
      selectedValue: this.state.options[index],
      options: this.state.options
    });
  };

  // renderDonutChart = (chartData) =>{
  //   const total = chartData.data.reduce((acc, item) => (acc + item.y
  //   ), 0)
    
  //   return total  === 0 ? <NoData>No Glucose Data</NoData> : <DonutChart data={chartData.data} container={chartData.container} legendWidth={100}/>
  // };

  renderDonutChart = (chartData) => 
    <DonutChart data={chartData.data} container={chartData.container} legendWidth={true} isHealthData={true} />;


  render(){
   
    const {marginLeft, marginRight,  childNumber, /* headerTitle, */ payload, chartData, isHealthData, isGlucose} = this.props;
    const total = chartData.data.reduce((acc, item) => acc + item.y, 0);

    return(
      <GoodCholesterolContainer isHeight={ isHealthData ? '415px' : '288px'} marginLeft={marginLeft} marginRight={marginRight} childNumber={childNumber} isShadow={"none"}>
        <SmallBoxHeaderV1 isBackground="white">
          <SanitizedHeaderTitle ref={this.headerTitleRef} />
          {/* <div dangerouslySetInnerHTML={{ __html: headerTitle }}/> */}
        </SmallBoxHeaderV1>
        <BorderBottom/>
        <div style={{ display: 'flex', flexDirection: 'row', height: isGlucose && '100%' }}>
          {total === 0 ? (
            <NoData isHeight={'200px'} >No Data</NoData>
          ) : (
            <div style={{width:'100%', display:'flex', flexDirection:'row', height: isGlucose && '100%' }}>
              <BottomLabelDonutContainerV1 Triglycerides={isGlucose}>
                {payload.map((item, index) => (
                  <div key={index}>
                    <span style={{ backgroundColor: item.color }} />
                    <div>
                      <div>{item.name}</div>
                      <div style={{color:'rgba(0, 92, 135, 0.30)'}}>{item.range}</div>
                    </div>
                  </div>
                ))}
              </BottomLabelDonutContainerV1>
              {this.renderDonutChart(chartData)}
            </div>
          )}
        </div>
      </GoodCholesterolContainer>
    )
  }
}

Glucose.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  marginLeft: PropTypes.string.isRequired,
  marginRight: PropTypes.string.isRequired,
  childNumber: PropTypes.string.isRequired,
  payload: PropTypes.array.isRequired,
  chartData: PropTypes.object.isRequired,
  isHealthData: PropTypes.bool,
  isGlucose: PropTypes.bool,
  averagePoint: PropTypes.string
};