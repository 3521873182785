import styled from "styled-components";

const BoldFont = "Rubik-Medium";
const RegularFont = "Rubik-Regular";
const LightFont = "Rubik-Light";
const CommonFont = "Rubik";

const Layout = styled.div`
  width: 100%;
  padding: ${({ padding }) =>
    padding ? padding : "10px 37px 30px 32px"}; // 10px 37px 30px 32px;
  margin-bottom: 100px;
  float: left;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 300px;
  margin-top: ${(props) => (props.isMargin ? props.isMargin : "")};
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const BodyMassContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: #fff;
  border-radius: 5px;
  ${"" /* margin-right: 16px; */}
  ${'' /* box-shadow: 0px 4px 5px 0px rgba(204,204,204,1); */}
  flex-direction: column;
  @media (max-width: 1200px) {
    margin-right: 0px;
    margin-bottom: 10px;
  }
`;

const BloodPressureContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: #fff;
  border-radius: 5px;
  margin-right: ${(props) => (props.marginRight ? "28px" : "0")};
  ${'' /* box-shadow: 0px 4px 5px 0px rgba(204, 204, 204, 1); */}
  flex-direction: column;
  @media (max-width: 1200px) {
    margin-left: 0px;
    margin-top: 10px;
    margin-right: 0;
  }
`;

const BoxHeader = styled.div`
  width: 100%;
  background-color: #ffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 15px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  .average{
    color: #005C87;
    text-align: right;
    font-family: Rubik !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }
  > div {
    font-size: 18px;
    color: #005c87;
    font-family: ${CommonFont};
    font-weight: bold;
    display: flex;
    align-items: center;

    > span {
      font-family: ${RegularFont};
      margin-right: 20px;
    }
    img {
      width: 18px;
      margin-bottom: 4px;
      margin-right: 10px;
    }
    i {
      font-size: 22px;
    }
  }
`;

const BorderBottom = styled.div`
  border-bottom: 1px solid rgba(0, 92, 135, 0.1);
  margin-left: 12px;
  margin-right: 12px;
`;

const TopHeader = styled.div`
  display: flex;
  ${'' /* justify-content: space-between; */}
  padding: 0px 12px 0px 0px;
  margin: 12px 12px 8px 12px;
  fontsize: 16px;
  fontfamily: rubik;
  color: rgba(0, 92, 135, 0.6);
`;

const HealthCardContainer = styled.div`
  display: flex;
  height: 84px;
  justify-content: space-between;
  border: 1px solid rgba(0, 122, 177, 0.3);
  border-radius: 4px;
  align-items: center;
  padding: 10px 12px;
  margin: 8px 12px;
  position: relative;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;

    > div {
      width:290px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
    }
  }

  .data {
    height: 38px;
    color: #005c87;
    font-family: rubik-medium;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  
  .diseaseData {
    width: 130px;
    justify-content: flex-start;
  }

  .peopleData {
    width: 100px;
    justify-content: flex-end;
    font-size: 24px;
  }
`;

const HoverBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  z-index: 2;
  .view-button {
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    min-width: 160px;
    width: 140px;
    height: 48px;
    background: #007ab1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    color: white;
    text-align: center;
    z-index: 3;
    padding: 12px 20px 12px 20px;
      :hover{
  background:#005C87;
  color:#ffff;
}
  }
`;

const ChartBarContainer = styled.div`
  width: 100%;
  min-height: ${(props) => (props.minHeight ? "292px" : "330px")};
  padding: ${(props) =>
    props.bloodPressure
      ? "0px"
      : props.bodyMassIndex
        ? " 10px 20px 20px 15px"
        : "15px 25px"};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: ${(props) =>
    props.bloodPressure ? "flex-start" : "space-between"};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  .averageBlood {
    flex: 1;
    font-size: 14px;
    color: #000;
    font-family: ${RegularFont};
    margin-top: 15px;
  }
  .sliderContainer {
    flex: 4;
  }
  .sliderAverageContainer {
    display: flex;
    padding: ${(props) =>
    props.bloodPressure ? "0px 25px 25px 15px" : " 0px 25px 25px 25px;"};
    ${"" /* padding: 0px 25px 25px 25px; */}
    border-bottom: 1px solid #F5F5F7;
  }
  .sliderTextContainer {
    display: flex;
    flex: 1;
    background-color: #fafafb;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 25px;
    > div {
      flex: 1;
      > p {
        font-size: 10px;
        padding: 10px 10px 10px 0px;
        color: #2a2e39;
        font-family: ${BoldFont};
        text-align: left;
      }
    }
  }

  .chartbar-header p {
    font-size: 10px;
    padding: 15px 25px 0 25px;
    color: #2a2e39;
    font-family: ${BoldFont};
  }

  @media (max-width: 620px) {
    .sliderTextContainer,
    .sliderAverageContainer {
      flex-direction: column;
    }
    .averageBlood {
      text-align: left;
      margin-bottom: 15px;
    }
  }
`;

const ActivityContainer = styled.div`
  width: 100%;
  font-size: 15px;
  margin: ${({ bloodPressure, isMargin }) =>
    bloodPressure ? bloodPressure : isMargin ? isMargin : "15px 0px"};
  text-align: left;
  .chartTitle {
    color: ${(props) => (props.color ? props.color : "#000")};
    font-family: ${(props) =>
    props.small ? `${LightFont}` : `${RegularFont}`};
    display: inline-block;
    margin-right: 5px;
    line-height: 16px;
  }
  .chartSubTitle {
    color: #c9cacc;
    font-family: ${LightFont};
    display: inline-block;
  }
  .chartSubTitle1 {
    color: rgba(0, 92, 135, 0.3);
    font-family: ${RegularFont};
    display: inline-block;
    font-size: 14px;
    line-height: 16px;
  }
`;

const ActivityBar = styled.div`
  width: 100%;
  height: ${(props) => (props.barHeight ? props.barHeight : "20px")};
  border-radius: ${(props) => (props.isRadius ? props.isRadius : "20px")};
  background-color: ${(props) =>
    props.backgroundFill ? props.backgroundFill : "rgba(86, 192, 36, 0.3)"};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px;
  > span {
    color: #fff;
    font-size: 11px;
    font-family: ${RegularFont};
    z-index: 1;
    padding: 0px 20px;
  }
  .endPercent {
    color: ${(props) => (props.fillColor ? props.fillColor : "#000")};
  }
`;

const ActivityBarFill = styled.div`
    width: ${(props) => (props.width ? props.width : "0%")};
    height: 100%;
    border-radius: ${(props) => (props.isRadius ? props.isRadius : "20px")};
    background-color: ${(props) =>
    props.fillColor ? props.fillColor : "#000"};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(props) => (props.isPadding ? props.isPadding : " 0px 10px")};
    ${'' /* padding: 0px 10px; */}
    ${"" /* position: absolute; */}
    position: ${(props) => (props.isPosition ? props.isPosition : "absolute")};
    top: 0px;
    > span {
       cursor : pointer
       color: #fff;
       font-size: 11px;
       font-family: ${RegularFont};
       display: inline-block;
    }
   
`;

// const PercentageContainer = styled.div`
//     margin-left: 5px; */}
//     color: #005C87;
//     font-family: Rubik;
//     font-size: 16px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 20px;
//     margin-left: 25px; 
//     position: absolute;
//     left: ${(props) => (props.left ? props.left : "100%")};
//     transform: translateX(5%);
// `;


const PercentageContainer = styled.div`
  color: #005C87;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: ${(props) => (props.isMargin)};
  position: absolute;
  left: ${(props) => (props.left ? props.left : "100%")};
  transform: translateX(5%);
  z-index: 1; // Ensure the percentage text is above other elements

  .icon-container {
    display: flex;
    align-items: center;
    position:relative;
    z-index: 2; // Ensure the icon container is above other elements
  }
`;


const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${"" /* min-height: 365px; */}
  min-height: ${(props) => (props.isHeight ? props.isHeight : "365px")};
  ${"" /* margin-top: 16px; */}
  margin-top: ${(props) => (props.isMargin ? props.isMargin : "16px")};
  flex-wrap: wrap;
`;

const GoodCholesterolContainer = styled.div`
  display: flex;
  flex: 1;
  ${"" /* height:280px; */}
  height: ${(props) => (props.isHeight ? props.isHeight : "")};
  background-color: #fff;
  border-radius: 5px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "0px")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
  ${'' /* box-shadow: 0px 4px 5px 0px rgba(204, 204, 204, 1); */}
  box-shadow: ${(props) => (props.isShadow ? props.isShadow : " 0px 4px 5px 0px rgba(204, 204, 204, 1)")};
  flex-direction: column;
  flex-wrap: ${(props) =>
    props.childNumber === "thirdChild" || props.childNumber === "fourthChild"
      ? "unset"
      : "wrap"};
  @media (max-width: 1200px) {
    flex: 1 0 48%;
    margin-bottom: ${(props) =>
    props.childNumber === "firstChild" || props.childNumber === "secondChild"
      ? "8px"
      : "0px"};
    margin-top: ${(props) =>
    props.childNumber === "thirdChild" || props.childNumber === "fourthChild"
      ? "8px"
      : "0px"};
    margin-right: ${(props) =>
    props.childNumber === "secondChild" || props.childNumber === "fourthChild"
      ? "0px"
      : "8px"};
    margin-left: ${(props) =>
    props.childNumber === "firstChild" || props.childNumber === "thirdChild"
      ? "0px"
      : "8px"};
  }
  @media (max-width: 600px) {
    flex: 1 0 100%;
    margin: 10px 0px;
    min-height: 350px;
  }
`;

const SmallBoxHeader = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.isBackground ? props.isBackground : "#1C4667"};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  > div {
    font-size: 14px;
    color: #fff;
    font-family: ${BoldFont};
    display: flex;
    align-items: center;
    text-transform: uppercase;
    > span {
      font-family: ${BoldFont};
      margin: 0px 3px;
    }
    > i {
      font-size: 18px;
      transform: rotate(90deg);
      display: none;
    }
    > div {
      margin-right: 10px;
      display: none;
    }
  }

  @media (max-width: 600px) {
    padding: 5px 25px;
    > div {
      font-size: 12px;
      > i {
        font-size: 22px;
        display: none;
      }
    }
  }
`;

const SmallBoxHeaderV1 = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.isBackground ? props.isBackground : "#1C4667"};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 12px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  > div {
    font-size: 18px;
    color: #005c87;
    font-family: ${BoldFont};
    display: flex;
    align-items: center;
    ${"" /* text-transform: uppercase; */}
    > span {
      font-family: ${CommonFont};
      margin: 0px 3px;
      font-size: 18px;
    }
    > i {
      font-size: 18px;
      transform: rotate(90deg);
      display: none;
    }
    > div {
      margin-right: 10px;
      display: none;
    }
  }

  @media (max-width: 600px) {
    padding: 5px 25px;
    > div {
      font-size: 12px;
      > i {
        font-size: 22px;
        display: none;
      }
    }
  }
`;

const BiometricContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 300px;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const CholesterolContainer = styled.div`
    width: 100%;
    padding: 15px 25px
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
`;

const BottomLabelDonutContainer = styled.div`
  width: 100%;
  display: flex;
  ${
  "" /* flex-direction: ${(props) => props.isDirection ? props.isDirection : 'row'}; */
}
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.Triglycerides ? "center" : "space-between"};
  padding: ${(props) => (props.container ? "0 25px 23px 25px" : "20px 25px")};
  text-align: ${(props) => (props.Triglycerides ? "center" : "left")};
  > div {
    > span {
      display: inline-block;
      width: 25px;
      height: 10px;
      border-radius: 5px;
    }
    > div {
      font-family: ${BoldFont};
      color: #282c37;
      font-size: 13px;
      min-height: 36px;
    }
  }
`;

const BottomLabelDonutContainerV1 = styled.div`
  width: ${ ({Triglycerides}) => Triglycerides ? '51%' : '50%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) =>
    props.Triglycerides ? "center" : "space-between"};
  padding: ${(props) => (props.container ? "0 25px 23px 25px" : "20px 13px")};
  text-align: ${(props) => (props.Triglycerides ? "center" : "left")};
  background-color: #fff;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    > span {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 15px;
      background-color: ${(props) => props.color};
      margin-right: 10px;
    }
    > div {
      font-family: ${BoldFont};
      color: #282c37;
      font-size: 14px;
      min-height: 36px;
      color: rgba(0, 92, 135, 0.6);
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: ${ ({Triglycerides}) => Triglycerides && 'flex-start'};
    }
  }
`;

const Triglycerides = styled.div`
  display: flex;
  flex: 0 0 49.4%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 5px 0px rgba(204, 204, 204, 1);
  flex-direction: column;
  flex-wrap: wrap;
  .triglyceridesCardContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    .triglyceridesCard {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
  @media (max-width: 1200px) {
    flex: 1 0 100%;
    margin-right: 0px;
  }
  @media (max-width: 450px) {
    .triglyceridesCardContainer {
      flex-direction: column;
    }
  }
`;

const BiometricTileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 450px;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

const BiometricTile = styled.div`
  display: flex;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "0px")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: ${(props) =>
    props.childNumber === 0 ||
    props.childNumber === 3 ||
    props.childNumber === 4 ||
    props.childNumber === 7
      ? "calc(25% - 8px)"
      : "calc(25% - 16px)"};
  margin-top: 8px;
  margin-bottom: 8px;
  flex-direction: column;
  @media (max-width: 1000px){
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(50% - 8px)
    margin-right: ${(props) =>
    props.childNumber === 0 ||
      props.childNumber === 2 ||
      props.childNumber === 4 ||
      props.childNumber === 6
      ? "8px"
      : "0px"};
    margin-left: ${(props) =>
    props.childNumber === 1 ||
      props.childNumber === 3 ||
      props.childNumber === 5 ||
      props.childNumber === 7
      ? "8px"
      : "0px"};
  }
  
  @media (max-width: 600px){
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }
`;

const TileCenterContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  box-shadow: 0px 4px 5px 0px rgba(204,204,204,1);
  border-radius: 5px;
  background-color: #fff;
  > div {
    width: 100%;
    background-color: #3171B4;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 30px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    min-height: 55px;
    >span{
      font-size: 14px;
      font-family: ${BoldFont}
      color: #fff;
      letter-spacing: 0.3px;
      line-height: 14px;
    }
  }
  .tileMainContainer {
    min-height: unset;
    background: transparent;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    height: calc(100% - 55px);
    padding: 0px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
`;

const RoundBoxContainer = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #fff;
    margin-bottom: -25px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 35px;
    }
  }
`;

const ButtonTextContainer = styled.div`
  padding: 8px 35px;
  border-radius: 20px;
  background-color: ${(props) =>
    props.status === "Normal"
      ? "#2199C1"
      : props.status === "Optimal"
        ? "#60BF79"
        : "#FA3F19"};
  color: #fff;
  font-size: 11px;
  font-family: ${RegularFont};
`;

const BiometricValue = styled.div`
  font-size: 55px;
  color: #282c37;
  font-family: ${BoldFont};
  font-weight: 500;
  > span {
    color: #747b83;
    font-size: 15px;
    font-family: ${LightFont};
  }
`;

const BiometricSubTitle = styled.div`
  color: #81878e;
  font-size: 12px;
  padding: 10px 0px;
  font-family: ${RegularFont};
`;

const NoData = styled.div`
  width: 100%;
  padding: 20px 0;
  text-align: center;
  ${"" /* min-height: 300px; */}
  min-height: ${(props) => (props.isHeight ? props.isHeight : "300px")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${RegularFont};
  font-size: 18px;
  color: #005c87;
`;


const TopCardContainer = styled.div`
  display: flex;
  row-gap: 15px;
  column-gap: 32px;
`;

const ScoreCard = styled.div`
 width: 288px;
 height: 180px;
 padding: 15px;
 border-radius: 4px;
 position: relative ;
  background:  #FFFFFF;
`;



const ScoreCardHeader = styled.div`
width: 250px;
display: flex;
align-items: center;
gap: 15px;
margin-bottom: 20px;

.scoreTitile{
  width: 185px;
  color: #005c87;
  font-family: rubik-medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom:0;
}
`;


const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  display:flex;
  justify-content:center;
  align-items: center;
  background-color: ${(props) =>props.isBackground ? props.isBackground : "#000"};
`;

const PercentageCard = styled.div`
  display: flex;
  align-items: center;
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 92, 135, 0.6);

  > span {
    font-family: Rubik-Medium;
    font-size: 24px;
    line-height: 24px;
    ${'' /* color: #85C0EA; */}
    ${'' /* color: ${(props) =>props.isBackground}; */}
    margin-right: 15px;

  }
`;


const BottomText = styled.div`
  display: flex;
  align-items: center;
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 92, 135, 0.6);

  > span {
    font-family: Rubik-Medium;
    font-size: 14px;
    line-height: 24px;
    color: #85C0EA;
    margin-right: 4px;

  }
`;

const AveragePoint = styled.div`
color: #005C87;
text-align: right;
font-family: Rubik !important;
font-size: 18px;
font-style: normal;
font-weight: 300;
line-height: 24px;
`;

export {
  Layout,
  Container,
  BodyMassContainer,
  BloodPressureContainer,
  BoxHeader,
  BorderBottom,
  ChartBarContainer,
  ActivityBar,
  ActivityBarFill,
  ActivityContainer,
  GoodCholesterolContainer,
  CardContainer,
  SmallBoxHeader,
  CholesterolContainer,
  BottomLabelDonutContainer,
  Triglycerides,
  BiometricTileContainer,
  BiometricTile,
  TileCenterContainer,
  RoundBoxContainer,
  ButtonTextContainer,
  BiometricValue,
  BiometricSubTitle,
  NoData,
  PercentageContainer,
  SmallBoxHeaderV1,
  BiometricContainer,
  BottomLabelDonutContainerV1,
  TopHeader,
  HoverBackground,
  HealthCardContainer,
  TopCardContainer,
  ScoreCard,
  ScoreCardHeader,
  IconContainer,
  PercentageCard,
  BottomText,
  AveragePoint
};