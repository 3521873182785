/* eslint-disable camelcase*/
/* eslint-disable react/no-unknown-property */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import _, { isNull, isUndefined } from "lodash";
import moment from "moment";
import { CustomCheckbox, CheckMark } from "../CreateEvent/styles";
import {
  ContentWrapper,
  RsvpButton,
  // LayoutNew,
  IconContainer,
  InputImage,
  CommentsContainerNew,
  FormContainer,
  FieldContainer,
  CommentContent,
  PostButton,
  CommentInnerContainer,
  InputImage2,
  TopContainer,
  EventWrapperNewUI,
  AboutEventDiv,
  EventRightSectionRowMainDiv,
} from "./styles";
import { NoComments, ImageWrapper } from "../SocialFeeds/styles";
import AttendeesModal from "../AttendeesModal";
import Waiting from "../Waiting";
import Image from "../Image";
import Loading from "../Loading";
import { withRouter } from "react-router-dom";
import RemoveAttendeesModal from "../RemoveAttendeesModal";
import AddAttendeesModal from "../AddAttendeesModal";
import {
  SearchAttendeesForEvent,
  attendLunchAndLearnEvent,
  getEventDetailsById,
} from "../../redux/actions/eventActions";
import {
  resetOrientation,
  getOrientation,
  checkImage,
  // convertHtmlToText,
  convertMonthFromDate,
  timeConvertInHrsMins,
  fetchApi,
  // getTimeToShow
} from "../../utils/methods";
import { imageErrorMessage, ImageUrl, S3Url } from "../../utils/constants";
import { CommentContentContainer } from "../ChallengeDetailsV2/styles";
import EventsComments from "../Comments/eventsComment";
import SocialFeedsEmojiPicker from "../SocialFeedsEmojiPicker";
import {
  // EventDetailsContainer,
  ContainerSecond,
  EventsButton,
  ContainerFirst,
  EventsButtonWrapper,
  EventCompletePoint,
} from "./styles";
import { convertDateInTimezone } from "../../utils/methods";
import { withTranslation } from "react-i18next";
import { updateUserGlobalPoints } from "../../redux/actions";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ToolTipNew } from "../AdminTeamCreation/styles";
import { BuddyTabContainer, BuddyTab } from "../PeopleHomeV2/styles";
import { ReservedButton, StyledSvgNew } from "../MainEvents/styles";
import AddInCalendar from "../AddInCalendar/calendarPopup";
import {
  StyledModal,
  CalendarWrapper,
  CalendarHeader,
  ConfirmVisitText,
  ButtonContainer,
  ButtonV2,
} from "../PeopleHomeV2/ManageBuddies/styles";
import InviteAmigoPopUp from "../ChallengeDetailsV2/InviteAmigoPopUp/inviteEvents";
import EventImageCard from "./eventImageCard";
import CommonTextArea from "../common/CommonTextArea/CommonTextArea";
import {
  AdminEventsAddAttendes,
  AdminEventsAttendsIcon,
  AdminEventsCalendarIcon,
  AdminEventsEventFlyers,
  AdminEventsEyeIcon,
  AdminEventsLocationIcon,
  AdminEventsPencilIcon,
  AdminEventsRegistersUsersIcon,
  AdminEventsRewardsIcon,
  AdminEventsTimeIcon,
  DownloadFileIcon,
  NewPencilIcon,
  NewPlusIcon,
  RightIcon,
} from "../../utils/icons";
import CoachProfile from "./CoachProfile";
import Timer from "./timer";
import EventVideoCard from "./eventVideoCard";
import { DeleteEventById } from "../../redux/constants/apiConstants";
import parse from 'react-html-parser';

class EventDetails extends Component {
  constructor() {
    super();
    this.state = {
      showModal: "",
      comment: "",
      isAttending: 0,
      isUpdatedImage: false,
      refreshFileReader: false,
      imgSrc: [],
      messageLength: 0,
      showChatScreen: 0,
      displayEmoji: false,
      position: { start: 0, end: 0 },

      time: [],
      buttonStatus: 0,
      selectedTab: 1,
      eventEdit: false,
      eventDelete: false,
      showCalendar: false,
      showConfirmPopup: false,
      eventInvite: false,
      showInvitePopup: false,
      showBack: false,
      showSpinner: true,
      isButtonDisabled: false,
      addAttendees: false,
      editAttendees: false,
    };
    this.emojiref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleEmojiClick);
    const {
      history: {
        location: { pathname },
      },
      fetchEventDetailsById,
      fetchCommentsOnEvent,
      getEventAttendees,
    } = this.props;
    const StringArray = pathname.split("/");
    if (StringArray[StringArray.length - 1] != "") {
      fetchEventDetailsById(StringArray[StringArray.length - 1]);
      fetchCommentsOnEvent(StringArray[StringArray.length - 1]);
      getEventAttendees(StringArray[StringArray.length - 1]);
    } else {
      fetchEventDetailsById(StringArray[StringArray.length - 2]);
      fetchCommentsOnEvent(StringArray[StringArray.length - 2]);
      getEventAttendees(StringArray[StringArray.length - 2]);
    }

    this.checkBase64(this.props.profileImage);
    // this.interval = setInterval(() => {
    //   this.setState({
    //     time: getTimeToShow(this.props.eventDetail, "event"),
    //   });
    // }, 1000);
    if (this.props.location.state === "chat") {
      this.setState({ selectedTab: 2 });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchEventDetailsById,
      location,
      fetchCommentsOnEvent,
      getEventAttendees,
    } = this.props;

    if (!this.state.showSpinner) {
      window.setTimeout(() => {
        this.setState({ showSpinner: true });
      }, 3000);
    }

    const StringArray = location.pathname.split("/");
    if (location.pathname !== prevProps.location.pathname) {
      if (StringArray[StringArray.length - 1] != "") {
        fetchEventDetailsById(StringArray[StringArray.length - 1]);
        fetchCommentsOnEvent(StringArray[StringArray.length - 1]);
        getEventAttendees(StringArray[StringArray.length - 1]);
      }
    }
    if (
      prevProps.eventDetail !== this.props.eventDetail ||
      prevProps.attending !== this.props.attending
    ) {
      this.setState(
        {
          isAttending: this.props.attending,
        },
        () => {
          this.chatScreenState();
        }
      );
    }
    // if (prevProps.eventDetail !== this.props.eventDetail) {
    //   clearInterval(this.interval);
    //   this.interval = setInterval(() => {
    //     this.setState({
    //       time: getTimeToShow(this.props.eventDetail, "event"),
    //     });
    //   }, 1000);
    // }
  }

  toCodePoints = function (string) {
    let chars = "";
    for (let i = 0; i < string.length; i++) {
      let c1 = string.charCodeAt(i);
      let b1 = string.charAt(i);
      if (c1 >= 0xd800 && c1 < 0xdc00 && i + 1 < string.length) {
        let c2 = string.charCodeAt(i + 1);
        if (c2 >= 0xdc00 && c2 < 0xe000) {
          chars =
            chars +
            ("&#" + (+0x10000 + ((c1 - 0xd800) << 10) + (c2 - 0xdc00)) + ";");
          i++;
          continue;
        }
      } else {
        chars = chars + b1;
      }
    }
    return chars;
  };

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split("/");
      if (arr[0] !== "profile-Image") {
        this.setState({
          isUpdatedImage: true,
        });
      } else {
        this.setState({
          isUpdatedImage: false,
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage);
    }
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.handleEmojiClick);
    // clearInterval(this.interval);
  }

  showProfileImage = () => {
    const { profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (isUpdatedImage) {
      return (
        <img src={profileImage} alt="avatar" style={{ borderRadius: "50%" }} />
      );
    } else {
      return (
        <Image
          image={profileImage}
          alt="avatar"
          style={{ borderRadius: "50%" }}
        />
      );
    }
  };

  showAttendeesModal = (name) => {
    const { SearchAttendeesForEvent, eventDetail } = this.props;
    this.setState({
      showModal: name,
    });
    if (name === "addAttendees") {
      let obj = { event_id: eventDetail.id, name: "" };
      SearchAttendeesForEvent(obj);
    }
  };

  hideAttendeesModal = () => {
    this.setState({
      showModal: "",
    });
  };

  onChange = (e) => {
    let position = this.getPosition(e.target);
    e.target.value !== " "
      ? this.setState({
        comment: e.target.value,
        position: position,
      })
      : null;
  };

  // downloadFLyer = (fileUrl) => {
  //   window.console.log("file url :_ ", fileUrl);
  //   if(fileUrl){
  //     let fileInfo = fileUrl.split("/");
  //     window.console.log("file name :- ", fileInfo[fileInfo.length-1]);
  //     const downloadLink = document.createElement("a");
  //     downloadLink.download = ImageUrl+"/"+fileUrl;
  //     downloadLink.target = '_blank';
  //     downloadLink.href = fileUrl;
  //     window.console.log("download link :- ", ImageUrl+"/"+fileUrl);
  //     downloadLink.click();
  //   }
  // }

  // getSignedUrl = () => {
  //   const { bucketName, imagePath } = this.props;
  //   const params = {
  //     Bucket: bucketName,
  //     Key: imagePath,
  //     Expires: 60 // URL expiration time in seconds
  //   };
  //   const url = s3.getSignedUrl('getObject', params);
  //   this.downloadImage(url)
  //   // this.setState({ imageUrl: url });
  // }

  // downloadImage = async (imageUrl) => {

  //   try {
  //     const response = await fetch(imageUrl);
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'image.jpg'; // You can set the desired file name here
  //     document.body.appendChild(a);
  //     a.click();
  //     a.remove();
  //   } catch (error) {
  //     window.console.error('Error downloading the image', error);
  //   }
  // }

  downloadFLyer = async (imageUrl) => {
    try {
      const response = await fetch(S3Url + "/" + imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      window.console.log("url", url);
      a.download = "flyer"; // You can set the desired file name here
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      window.console.error("Error downloading the image", error);
    }
  };

  onChangeImage = (e) => {
    let file = e.target.files[0];
    this.setState({
      messageLength: e.target.value.length >= 1 ? "1" : "0",
    });
    if (checkImage(e.target.files)) {
      if (file?.size / 1000000 <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({
            imgSrc: [reader.result],
            refreshFileReader: true,
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: [baseImage],
                refreshFileReader: false,
              });
            });
          });
        };
      } else {
        toast.error("Please select image file less than 20MB");
        document.getElementById("uploadPhoto").value = "";
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById("uploadPhoto").value = "";
    }
  };

  removeImage = () => {
    this.setState({
      imgSrc: [],
      messageLength: 0,
    });
  };

  postComment = (e, id) => {
    const { postCommentOnEvent } = this.props;
    const { comment, imgSrc } = this.state;
    let html = this.toCodePoints(comment);
    postCommentOnEvent({
      event_id: id,
      comment: html,
      image: imgSrc.length >= 1 ? imgSrc[0] : "",
    });
    this.setState({
      comment: "",
      imgSrc: [],
      messageLength: 0,
    });
  };

  handleAttendEvent = () => {
    const { eventDetail, attendEvent } = this.props;
    const { isAttending } = this.state;
    attendEvent(eventDetail.id, isAttending, "eventDetail");
  };

  changeState = (value) => {
    this.setState({
      isAttending: value,
    });
  };

  showHideChatScreen = (status) => {
    this.setState({
      showChatScreen: status,
    });
  };

  renderAttendingView = () => {
    const { isAttending } = this.state;
    const { eventDetail } = this.props;
    const checkStartEvent =
      convertDateInTimezone(eventDetail.end_date)._d <= moment()._d ? 1 : 0;
    return (
      <ContentWrapper>
        <div>
          <span>Are you Attending?</span>
          <CustomCheckbox
            disabled={eventDetail.event_status === "over" || checkStartEvent}
          >
            {"I'm Attending"}
            <input
              type="checkbox"
              checked={isAttending === 1}
              onChange={() => this.changeState(1)}
              disabled={eventDetail.event_status === "over" || checkStartEvent}
            />
            <CheckMark checked={isAttending === 1} />
          </CustomCheckbox>
          <CustomCheckbox
            disabled={eventDetail.event_status === "over" || checkStartEvent}
          >
            Not Attending
            <input
              type="checkbox"
              checked={!isAttending}
              onChange={() => this.changeState(0)}
              disabled={eventDetail.event_status === "over" || checkStartEvent}
            />
            <CheckMark checked={!isAttending} />
          </CustomCheckbox>
          <RsvpButton
            disabled={eventDetail.event_status === "over" || checkStartEvent}
            onClick={() => this.handleAttendEvent()}
          >
            rsvp
          </RsvpButton>
        </div>
      </ContentWrapper>
    );
  };

  navigateEdit = () => {
    const { history, eventDetail } = this.props;
    const { pathname } = history.location;
    const StringArray = pathname.split("/");

    if(eventDetail?.is_lunch_and_learn_event){
      window.console.log("lunch and learn event",StringArray);
      history.push(`/company/events/edit-lunch-learns/${StringArray[StringArray.length - 1]}`);

    }else{
      if (StringArray[StringArray.length - 1] != "") {
        history.push(`/company/events/${StringArray[StringArray.length - 1]}/edit`);
      } else {
        history.push(`/company/events/${StringArray[StringArray.length - 2]}/edit`);
      }

    }

  };

  showEmojiPicker = () => {
    this.setState((prev) => ({
      displayEmoji: !prev.displayEmoji,
    }));
  };

  hideEmojiPicker = () => {
    this.setState({
      displayEmoji: false,
    });
  };

  handleEmojiClick = (event) => {
    if (
      !isNull(this.emojiref) &&
      !isUndefined(this.emojiref) &&
      !(
        this.emojiref &&
        this.emojiref.current &&
        this.emojiref.current.contains(event.target)
      )
    ) {
      // this.setState({displayEmoji: false});
    }
  };

  onEmojiSelect = (emoji, id, native, skin) => {
    this.setState({
      emojiData: emoji,
      emojiId: id,
      emojiNative: native,
      emojiSkin: skin,
    });
    this.addInspirationV2(native);
  };

  getPosition(el) {
    let start = 0,
      end = 0,
      normalizedValue,
      range,
      textInputRange,
      len,
      endRange;

    if (
      typeof el.selectionStart == "number" &&
      typeof el.selectionEnd == "number"
    ) {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }
    return {
      start: start,
      end: end,
    };
  }

  addInspirationV2 = (e) => {
    let newText =
      this.state.comment.slice(0, this.state.position.start) +
      e +
      this.state.comment.slice(this.state.position.end);
    this.setState({
      position: {
        start: this.state.position.start + e.length,
        end: this.state.position.end + e.length,
      },
      comment: newText,
    });
  };

  fallBack = (e) => {
    e.target.src = "/public/images/default.png";
  };

  getEventStatus = () => {
    const { EventDetailsById, eventDetail } = this.props;
    EventDetailsById(eventDetail.id);
  };

  getEventAttendeesStatus(event, eventAttendees) {
    if (
      event.event_status === "over" ||
      convertDateInTimezone(event.end_date)._d <= moment()._d
    ) {
      return `${eventAttendees.length}  `;
    } else if (convertDateInTimezone(event.start_date)._d <= moment()._d) {
      return `${eventAttendees.length}`;
    } else {
      return `${eventAttendees.length} `;
    }
  }

  chatScreenState = () => {
    if (this.state.selectedTab === 1) {
      this.showHideChatScreen(0);
    } else {
      this.showHideChatScreen(2);
    }
  };

  setActiveTab = (active) => {
    this.setState(
      {
        selectedTab: active,
      },
      () => {
        this.chatScreenState();
      }
    );
  };

  showButton = () => {
    const { eventDetail } = this.props;
    this.handleEventAttending(eventDetail.id, eventDetail.is_attending);
    this.getEventStatus();
    this.setState({
      buttonStatus: !this.state.buttonStatus,
    });
  };

  handleEventAttending = (eventId, isAttending) => {
    this.props.attendEvent(eventId, isAttending);
  };

  // navigateEdit = () => {
  //   const { history } = this.props;
  //   const { pathname } = history.location;
  //   const StringArray = pathname.split("/");
  //   if (StringArray[StringArray.length - 1] != "") {
  //     history.push(`/events/${StringArray[StringArray.length - 1]}/edit`);
  //   } else {
  //     history.push(`/events/${StringArray[StringArray.length - 2]}/edit`);
  //   }
  // };

  eventButtonStatusHover = (value) => {
    if (value === "delete")
      this.setState({
        eventDelete: true,
      });
    else if (value === "invite") {
      this.setState({
        eventInvite: true,
      });
    } else if (value === "back") {
      this.setState({
        showBack: true,
      });
    } else {
      this.setState({
        eventEdit: true,
      });
    }
  };

  eventButtonStatus = (value) => {
    if (value === "delete")
      this.setState({
        eventDelete: false,
      });
    else if (value === "invite") {
      this.setState({
        eventInvite: false,
      });
    } else if (value === "back") {
      this.setState({
        showBack: false,
      });
    } else {
      this.setState({
        eventEdit: false,
      });
    }
  };

  calculateMinuits = (targetDate) => {
    let parsedDate1 = new Date(targetDate);
    let parsedDate2 = new Date();
    let difference = (difference = Math.abs(parsedDate2 - parsedDate1));
    if (difference <= 0) {
      return "Target date has already passed";
    }
    let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    let showButton =
      days === 0 && hours === 0 && minutes > 0 && minutes < 31 ? true : false;
    return showButton;
  };

  showHideDeletePopup = () => {
    this.setState((prev) => ({ showConfirmPopup: !prev.showConfirmPopup }));
  };

  showHideInvitePopup = () => {
    this.setState((prev) => ({ showInvitePopup: !prev.showInvitePopup }));
  };

  deleteEvent = (id) => {
    this.setState(
      {
        showSpinner: false,
      },
      () => {
        this.deleteEventById(id);
      }
    );
  };

  deleteEventById = async(id) => {

    try {
      const res = await fetchApi(`${DeleteEventById}/${id}`, "DELETE");
      if(res.message) {
        toast.error(res.message);
      }
      else {
        toast.success(res[0]);
        if(window.location.pathname.includes('company')){
          this.props.history.push("/company/dashboard");
        }else{
          this.props.history.push("/events");
          window.location.reload();
        }
        
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  confirmationPopup = () => {
    const { showConfirmPopup } = this.state;
    const { eventDetail } = this.props;
    return (
      <StyledModal show={showConfirmPopup} width={"485px"}>
        <CalendarHeader>
          <div>Delete Event</div>
        </CalendarHeader>
        <CalendarWrapper padding="0 25px 25px 25px">
          {
            <ConfirmVisitText>
              Are you sure you want to delete this Event?
            </ConfirmVisitText>
          }
          <ButtonContainer>
            <ButtonV2
              background="#007AB1"
              onClick={() => this.deleteEvent(eventDetail.id)}
            >
              {"Delete"}
            </ButtonV2>
            <ButtonV2
              border="1"
              background="transparent"
              color="#669db7"
              onClick={() => this.showHideDeletePopup()}
              style={{ border: "1px solid #669db7" }}
            >
              {"Cancel"}
            </ButtonV2>
          </ButtonContainer>
        </CalendarWrapper>
      </StyledModal>
    );
  };

  headerView = () => {
    const { eventDelete, eventEdit, selectedTab, eventInvite, showBack } =
      this.state;
    const {
      role,
      /*addOrRemoveUserPermission,*/ eventDetail,
      userId /*isAdmin*/,
    } = this.props;
    let currentDate = new Date();
    let eventStartDate = new Date(eventDetail.start_date);
    let launchButton = false;
    if (currentDate > moment(convertDateInTimezone(eventStartDate)._d)) {
      launchButton = true;
    }
    let showLaunchButton =
      eventDetail && eventDetail.start_date
        ? this.calculateMinuits(
          moment(
            convertDateInTimezone(eventDetail && eventDetail.start_date)._d
          )
        )
        : null;
    // const allowAccess = isAdmin;
    const valid =
      Object.keys(eventDetail).indexOf("end_date") !== -1
        ? convertDateInTimezone(eventDetail.end_date)._d >= moment()._d
        : "";
    const popover = (value) => (
      <Popover
        id="popover-basic"
        style={{
          width: "auto",
          padding: "9px 14px",
          background: "#FFFFFF",
          border: "1px solid #005c87",
          boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
        }}
      >
        <div
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#005c87",
            textAlign: "center",
          }}
        >
          {value}
        </div>
      </Popover>
    );

    return (
      <BuddyTabContainer marginBottom="20px">
        <ToolTipNew
          margin={"auto 0px auto 0px"}
          active={showBack}
          onMouseEnter={() => this.eventButtonStatusHover("back")}
          onMouseLeave={() => this.eventButtonStatus("back")}
          height="40px"
          width="40px"
          padding
        >
          <OverlayTrigger
            placement="bottom"
            overlay={popover(this.props.t("Back"))}
          >
            <div
              className='="toolTip'
              onClick={
                eventDetail.event_status === "over"
                  ? () => {
                    if(this.props.isAdminEvent){
                      if(window.location.pathname.includes('company')){
                        this.props.history.push("/company/dashboard");
                      }else{
                        this.props.history.push({pathname:"/events",state:"updateData"});
                      }
                    }else{
                      this.props?.location?.state?.view === "admin"
                        ? this.props.history.push({
                          pathname: `/company/events/completed`,
                        })
                        : this.props.history.push(`/events/completed`);

                    }
                  }
                  : () =>{ if(this.props.isAdminEvent){
                    if(window.location.pathname.includes('company')){
                      this.props.history.push("/company/dashboard");
                    }else{
                      this.props.history.push({pathname:"/events",state:"updateData"});
                    }
                  }else{
                    this.props.history.push({pathname:"/events",state:"updateData"});
                  }
                  }
              }
            >
              <StyledSvgNew active={showBack} width="25px" height="25px">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <mask id="a" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                    <path fill="#007AB1" d="M.645 0h24v24h-24z"/>
                  </mask>
                  <g mask="url(#a)">
                    <path d="M6.395 12c0-.358.15-.717.45-.99l9.428-8.6a1.64 1.64 0 0 1 2.172 0c.6.547.6 1.434 0 1.981L10.102 12l8.343 7.609c.6.547.6 1.434 0 1.98a1.64 1.64 0 0 1-2.172 0l-9.43-8.599a1.34 1.34 0 0 1-.448-.99" fill="#007AB1"/>
                  </g>
                </svg>
              </StyledSvgNew>
            </div>
          </OverlayTrigger>
        </ToolTipNew>
        <BuddyTab
          width
          padding
          active={selectedTab === 1}
          onClick={() => this.setActiveTab(1)}
          // style={{color:'#005c87'}}
          margin={"10px 0px 10px 10px"}
        >
          {this.props.t("Event Brief")}
        </BuddyTab>
        {eventDetail.is_attending === 1 ||
        (eventDetail.is_global_event === 0 && role === "WELLNESS_ADMIN") ? (
            <BuddyTab
              width
              padding
              active={selectedTab === 2}
              onClick={() => this.setActiveTab(2)}
              // style={{ color: '#005c87' }}
              margin={"10px 0px"}
            >
              {" "}
              {this.props.t("Chat")}
            </BuddyTab>
          ) : eventDetail.is_attending === 1 ||
            (eventDetail.is_global_event === 1 && role === "ADMIN") ? (
              <BuddyTab
                width
                padding
                active={selectedTab === 2}
                onClick={() => this.setActiveTab(2)}
                // style={{ color: '#005c87' }}
                margin={"10px 0px"}
              >
                {" "}
                {this.props.t("Chat")}
              </BuddyTab>
            ) : null}
        {(role === "ADMIN" ||
          eventDetail.created_by === userId ||
          (eventDetail.is_global_event == "0" && role === "WELLNESS_ADMIN")) &&
          eventDetail.event_status !== "over" && (
          !eventDetail.is_lunch_and_learn_event || 
    (eventDetail.is_lunch_and_learn_event === 1 && role === "ADMIN")) && (
          <ToolTipNew
            margin={
              role === "ADMIN" ? "auto 0px auto auto" : "auto 0 auto auto"
            }
            width="40px"
            height="40px"
            active={eventInvite}
            onMouseEnter={() => this.eventButtonStatusHover("invite")}
            onMouseLeave={() => this.eventButtonStatus("invite")}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={popover(this.props.t("Add Attendees"))}
            >
              <div
                className='="toolTip'
                onClick={() => this.showHideInvitePopup()}
              >
                <StyledSvgNew active={eventInvite} height="24px" width="24px">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_9490_67061"
                      style={{
                        maskType: "alpha",
                      }}
                      maskUnits="userSpaceOnUse"
                      x={0}
                      y={0}
                      width={24}
                      height={24}
                    >
                      <rect width={24} height={24} fill="#9C9C9C" />
                    </mask>
                    <g mask="url(#mask0_9490_67061)">
                      <path
                        d="M23.3685 9.63105H21.158V7.42058C21.158 7.07167 20.8754 6.78906 20.5265 6.78906H19.895C19.546 6.78906 19.2634 7.07168 19.2634 7.42058V9.63105H17.053C16.7041 9.63105 16.4214 9.91366 16.4214 10.2626V10.8941C16.4214 11.243 16.7041 11.5256 17.053 11.5256H19.2634V13.7361C19.2634 14.085 19.546 14.3676 19.895 14.3676H20.5265C20.8754 14.3676 21.158 14.085 21.158 13.7361V11.5256H23.3685C23.7174 11.5256 24 11.243 24 10.8941V10.2626C24 9.91365 23.7174 9.63105 23.3685 9.63105Z"
                        fill="white"
                      />
                      <path
                        d="M5.63302 10.8218C6.32798 10.8218 6.96503 10.5006 7.4805 10.007C6.94016 9.04218 6.63131 7.95045 6.63131 6.90304C6.63131 6.01466 6.84674 5.17698 7.22561 4.43707C6.75577 4.15027 6.20663 3.98242 5.61735 3.98242C3.89545 3.98242 2.49933 5.39219 2.49933 7.13084C2.49889 8.86903 3.91062 10.8218 5.63297 10.8218L5.63302 10.8218Z"
                        fill="white"
                      />
                      <path
                        d="M7.73017 12.3166C7.42575 12.2512 7.111 12.2148 6.78692 12.2148H4.46807C2.00052 12.2153 0 14.213 0 16.677L0.010353 17.2048C0.010353 17.8931 0.569197 18.4515 1.25868 18.4515H3.54493C3.65749 15.7097 5.34572 13.3722 7.7302 12.3168L7.73017 12.3166Z"
                        fill="white"
                      />
                      <path
                        d="M12.0142 11.4811C14.1495 11.4811 15.8611 9.05965 15.8611 6.90377C15.8609 4.74795 14.1297 3 11.9944 3C9.85901 3 8.12787 4.74789 8.12787 6.90377C8.12809 9.06004 9.87886 11.4811 12.0142 11.4811H12.0142Z"
                        fill="white"
                      />
                      <path
                        d="M18.9852 18.7397C18.9852 15.684 16.5047 13.207 13.445 13.207H10.5698C7.50967 13.207 5.0296 15.6841 5.0296 18.7397L5.04282 19.394C5.04282 20.2475 5.73603 20.9396 6.59092 20.9396H17.4512C18.3061 20.9396 18.9993 20.2475 18.9993 19.394L18.9852 18.7397Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </StyledSvgNew>
              </div>
            </OverlayTrigger>
          </ToolTipNew>
        )}
        {(role === "ADMIN" ||
          eventDetail.created_by === userId ||
          (eventDetail.is_global_event === 0 && role === "WELLNESS_ADMIN")) && 
          valid &&
          eventDetail.event_status !== "over" &&
          !launchButton &&
          !showLaunchButton &&   (
          !eventDetail.is_lunch_and_learn_event || 
    (eventDetail.is_lunch_and_learn_event === 1 && role === "ADMIN")) &&   (
          <ToolTipNew
            margin={"auto 5px auto 5px"}
            width="40px"
            height="40px"
            active={eventEdit}
            onMouseEnter={() => this.eventButtonStatusHover("edit")}
            onMouseLeave={() => this.eventButtonStatus("edit")}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={popover(this.props.t("Edit Event"))}
            >
              <div className='="toolTip' onClick={this.navigateEdit}>
                {
                  <StyledSvgNew width="24px" height="24px" active={eventEdit}>
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.0015329 18.8539C0.0015329 19.1285 -0.0205087 19.1643 0.10257 19.4323C0.166607 19.5717 0.185811 19.5722 0.286848 19.6728C0.957494 20.3403 1.63359 19.7523 2.12649 19.5339L3.89643 18.67C4.11278 18.5705 4.27586 18.4703 4.48451 18.366L6.22961 17.5198C6.43362 17.4062 6.59125 17.308 6.8177 17.2158C7.35589 16.9967 8.26359 15.9683 8.67982 15.5523C8.84707 15.3851 15.53 8.75857 15.6798 8.53503L15.4551 8.24997C14.9879 7.73093 12.7911 5.64412 12.5295 5.31389L12.0151 4.76629C11.8452 4.59688 11.6604 4.37126 11.4734 4.24609L10.6549 5.04176C10.0945 5.5759 8.88251 6.89135 8.4276 7.27447L7.22423 8.49227C7.14254 8.58635 7.10088 8.59892 7.00075 8.69359L4.94648 10.7595C4.87686 10.8401 4.94891 10.7673 4.8666 10.8496L4.53804 11.1582C4.43299 11.2786 4.48408 11.2474 4.35376 11.3562C4.25824 11.4359 4.21604 11.4741 4.13267 11.5598L3.32605 12.3673C2.79464 12.8514 2.7862 13.1334 2.55198 13.5895C2.49484 13.7007 2.45249 13.7412 2.3946 13.8569L0.238974 18.242C0.126973 18.4286 0.0014875 18.5808 0.0014875 18.854L0.0015329 18.8539Z"
                        fill="#9C9C9C"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.876 2.84523C12.9485 2.9535 13.0244 3.01677 13.1242 3.10675L13.6605 3.63241C13.7652 3.7483 13.8149 3.81273 13.9287 3.91632L15.2439 5.23467C15.3616 5.35101 15.4235 5.37614 15.5287 5.50208L16.713 6.69649C16.8814 6.84827 16.9852 6.99827 17.1249 7.09175C17.2987 6.97536 18.5726 5.66597 18.835 5.40375C19.5509 4.68834 19.9716 4.04248 19.9716 2.9302C19.9716 2.14084 19.6154 1.47817 19.1323 0.923881C19.012 0.78583 18.889 0.708603 18.7493 0.584799C18.6167 0.467257 18.4747 0.391232 18.3006 0.311237C17.5885 -0.0159341 17.4465 0.000128409 16.7 0.000128409C16.0209 0.000128409 15.1824 0.472951 14.7332 0.922043C14.5923 1.06284 14.6623 1.04523 14.3882 1.29908L12.876 2.84526L12.876 2.84523Z"
                        fill="#9C9C9C"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.96582 18.9371C8.96582 19.242 9.12649 19.5585 9.29404 19.7132C9.49766 19.9011 9.7795 19.9987 10.1555 19.9987H18.7381C20.4259 19.9987 20.4208 17.918 18.7381 17.918H10.1555C9.78597 17.918 9.52667 18.0085 9.3171 18.1841C9.14474 18.3285 8.96582 18.645 8.96582 18.9371Z"
                        fill="#9C9C9C"
                      />
                    </svg>
                  </StyledSvgNew>
                }
              </div>
            </OverlayTrigger>
          </ToolTipNew>
        )}
        {(role === "ADMIN" ||
          eventDetail.created_by === userId ||
          (eventDetail.is_global_event == "0" && role === "WELLNESS_ADMIN")) &&
          eventDetail.event_status !== "over" && (
          !eventDetail.is_lunch_and_learn_event || 
    (eventDetail.is_lunch_and_learn_event === 1 && role === "ADMIN")) && (
          <ToolTipNew
            margin={
              role === "ADMIN" ? "auto 15px auto 0" : "auto 15px auto 0"
            }
            width="40px"
            height="40px"
            active={eventDelete}
            onMouseEnter={() => this.eventButtonStatusHover("delete")}
            onMouseLeave={() => this.eventButtonStatus("delete")}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={popover(this.props.t("Delete Event"))}
            >
              <div
                className='="toolTip'
                onClick={() => this.showHideDeletePopup()}
              >
                <StyledSvgNew active={eventDelete} height="24px" width="20px">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5625 2.5H13.125V1.875C13.125 0.839453 12.2855 0 11.25 0H8.75C7.71445 0 6.875 0.839453 6.875 1.875V2.5H3.4375C2.57457 2.5 1.875 3.19957 1.875 4.0625V5.3125C1.875 5.6577 2.1548 5.9375 2.5 5.9375H17.5C17.8452 5.9375 18.125 5.6577 18.125 5.3125V4.0625C18.125 3.19957 17.4254 2.5 16.5625 2.5ZM8.125 1.875C8.125 1.53047 8.40547 1.25 8.75 1.25H11.25C11.5945 1.25 11.875 1.53047 11.875 1.875V2.5H8.125V1.875Z"
                      fill="#9C9C9C"
                    />
                    <path
                      d="M3.06152 7.1875C2.94999 7.1875 2.86112 7.2807 2.86644 7.39211L3.38206 18.2141C3.42972 19.2156 4.25237 20 5.25472 20H14.7461C15.7485 20 16.5711 19.2156 16.6188 18.2141L17.1344 7.39211C17.1397 7.2807 17.0509 7.1875 16.9393 7.1875H3.06152ZM12.5004 8.75C12.5004 8.40469 12.7801 8.125 13.1254 8.125C13.4707 8.125 13.7504 8.40469 13.7504 8.75V16.875C13.7504 17.2203 13.4707 17.5 13.1254 17.5C12.7801 17.5 12.5004 17.2203 12.5004 16.875V8.75ZM9.37542 8.75C9.37542 8.40469 9.65511 8.125 10.0004 8.125C10.3457 8.125 10.6254 8.40469 10.6254 8.75V16.875C10.6254 17.2203 10.3457 17.5 10.0004 17.5C9.65511 17.5 9.37542 17.2203 9.37542 16.875V8.75ZM6.25042 8.75C6.25042 8.40469 6.53011 8.125 6.87542 8.125C7.22073 8.125 7.50042 8.40469 7.50042 8.75V16.875C7.50042 17.2203 7.22073 17.5 6.87542 17.5C6.53011 17.5 6.25042 17.2203 6.25042 16.875V8.75Z"
                      fill="#9C9C9C"
                    />
                  </svg>
                </StyledSvgNew>
              </div>
            </OverlayTrigger>
          </ToolTipNew>
        )}
      </BuddyTabContainer>
    );
  };

  popover = (value) => (
    <Popover
      id="popover-basic"
      style={{
        width: "auto",
        padding: "9px 14px",
        background: "#FFFFFF",
        border: "1px solid #005c87",
        boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
      }}
    >
      <div
        style={{
          fontFamily: "Rubik",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "20px",
          color: "#005c87",
          textAlign: "center",
        }}
      >
        {value}
      </div>
    </Popover>
  );

  showCalendarData = () => {
    this.setState((prev) => ({ showCalendar: !prev.showCalendar }));
  };

  hideCalendar = (id) => {
    this.setState(
      (prev) => ({ showCalendar: !prev.showCalendar, eventId: id }),
      () => {
        this.props.attendEvent(id, 0, 1);
      }
    );
  };


  showCalendar = (id, title, event) => {
    event.stopPropagation();
    this.setState((prev) => ({
      showCalendar: !prev.showCalendar,
      eventId: id,
      eventName: title,
    }));
  };

  hideCalendarAndReserve = (event) => {
    event.stopPropagation();
  };

  showHideCalendarLogic = () =>{
    this.setState((prev) => ({
      showCalendar: !prev.showCalendar,
    }));
  }

  showCalendarAndReserve = (id, isAttending) => {
    if (isAttending !== 1) {
      this.setState((prev) => ({
        showCalendar: !prev.showCalendar,
      }));
    }
    this.setState(
      () => ({ eventId: id, isButtonDisabled: true }),
      () => {
        this.props.attendEventLimitUser(id, isAttending, this.showHideCalendarLogic);
      }
    );
    window.setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, 2000);
  };

  updateUserPoints = () => {
    const { eventDetail, attendLunchAndLearnEvent } = this.props;
    // const { isAttending } = this.state;
    attendLunchAndLearnEvent(eventDetail.id, 1,1, "eventDetail");
  };

  showOverviewSection = () => {
    const { eventDetail, t } = this.props;
    const { selectedTab } = this.state;

    return (
      <ContainerFirst width="100%" padding="0">
        {selectedTab === 1 && (eventDetail.is_lunch_and_learn_event && eventDetail.event_link && moment(convertDateInTimezone(eventDetail.start_date)._d) <= new Date() ?               
          <EventVideoCard event={eventDetail} updateUserPoints={this.updateUserPoints} isLunchAndLearn={true}/>
          : <EventImageCard event={eventDetail} />)}
        {selectedTab === 1 && (
          <>
            <AboutEventDiv>
              <div className="aboutTitle">
                {t("About the Event")}
              </div>
              <div className="aboutDescription">
                {parse(eventDetail.body)}
              </div>
            </AboutEventDiv>
          </>
        )}
      </ContainerFirst>
    );
  };

  render() {
    const {
      // userId,
      eventDetail,
      networkError,
      isLoading,
      attendees,
      // attending,
      isAdmin,
      // addOrRemoveUserPermission,
      comments,
      replySuccess,
      postReplyOnComment,
      printEventCsvPermission,
      // history,
      t,
      role,
      // attendEvent,
      addOrRemoveUserPermission,
    } = this.props;
    const {
      comment,
      showModal,
      imgSrc,
      showChatScreen,
      messageLength,
      selectedTab,
      showCalendar,
      showInvitePopup,
      showSpinner,
      addAttendees,
      editAttendees,
      isButtonDisabled 
    } = this.state;
    if (
      ((_.isNull(eventDetail) ||
        _.isUndefined(eventDetail) ||
        _.isEmpty(eventDetail) ||
        _.isNull(attendees)) &&
        !networkError) ||
      !showSpinner
    ) {
      return <Waiting />;
    }
    const allowAccess = isAdmin;
    // let today = new Date();
    // let startDate = new Date(eventDetail.start_date);
    const lang = localStorage.getItem("lang");
    return (
      <>
        <div className="containerDiv" style={{marginTop:"40px"}}>
          {this.headerView()}
        </div>

        <div style={{display:"flex",width:"1248px",margin:"auto auto 40px auto", background:"white", padding:"20px", borderRadius:"4px"}}>
          <div className="leftContainer" style={{minWidth:"750px", maxWidth:"750px"}}>
            {selectedTab === 1 && this.showOverviewSection()}
            <AttendeesModal
              Title={t(eventDetail.title)}
              Attendees={attendees}
              showModal={showModal === "attendees"}
              onHide={this.hideAttendeesModal}
              isValidUser={printEventCsvPermission}
              role={role}
              eventType={eventDetail.is_global_event}
            />
            {allowAccess && (
              <RemoveAttendeesModal
                Attendees={attendees}
                showModal={showModal === "removeAttendees"}
                onHide={this.hideAttendeesModal}
                eventIdValue={eventDetail.id}
              />
            )}
            {allowAccess && (
              <AddAttendeesModal
                eventId={eventDetail.id}
                title={t("Add Attendees")}
                subContent="The searched users will be add to the attendees list"
                showModal={showModal === "addAttendees"}
                onHide={this.hideAttendeesModal}
              />
            )}
            {showChatScreen ? (
              <CommentsContainerNew>
                <FormContainer margin={1} editChallenge>
                  <TopContainer>
                    <h3 style={{ color: "#005c87" }}>{t("Chat Group")}</h3>
                    <div>
                      {attendees.length > 0 && (
                        <img
                          src={`${ImageUrl}/${attendees[0].profile_image}`}
                        />
                      )}
                      {attendees.length > 1 && (
                        <img
                          src={`${ImageUrl}/${attendees[1].profile_image}`}
                        />
                      )}
                      {attendees.length > 2 && (
                        <img
                          src={`${ImageUrl}/${attendees[2].profile_image}`}
                        />
                      )}
                      {attendees.length > 3 && (
                        <img
                          src={`${ImageUrl}/${attendees[3].profile_image}`}
                        />
                      )}
                      {attendees.length > 4 && (
                        <img
                          src={`${ImageUrl}/${attendees[4].profile_image}`}
                        />
                      )}
                      {attendees.length > 5 && (
                        <div>{attendees.length - 5}+</div>
                      )}
                    </div>
                  </TopContainer>
                  <CommentContentContainer>
                    {eventDetail.event_status === "over" ? null : (
                      <FieldContainer>
                        <CommentContent>
                          <ImageWrapper
                            isCommentSection
                            borderRadius={"3px"}
                          >
                            {this.showProfileImage()}
                          </ImageWrapper>
                          <CommentInnerContainer style={{ border: "none" }}>
                            <div
                              style={{
                                width: "470px",
                                background: "#faf9f4",
                                border: "1px solid #afcbd3",
                                color: "#005c87",
                                borderRadius: "20px",
                              }}
                            >
                              {imgSrc &&
                                imgSrc.length >= 1 &&
                                messageLength && (
                                <InputImage2 className="InputImageSection">
                                  <img src={imgSrc} alt="uploadable" />
                                  <button onClick={this.removeImage}>
                                    <span className="InputImageSpan">
                                      X
                                    </span>
                                  </button>
                                </InputImage2>
                              )}
                              <CommonTextArea
                                placeholder={t(
                                  "Write your comment here..."
                                )}
                                onChange={(e) => this.onChange(e)}
                                value={comment}
                                rows={comment.length >= 120 ? "5" : "2"}
                                data-gramm_editor="false"
                                showEmojiPicker={this.showEmojiPicker}
                                onClick={(e) => this.onChange(e)}
                                style={{
                                  marginTop: "0px",
                                  display: "flex",
                                  alignItems: "center",
                                  background: "#faf9f4",
                                  border: "none",
                                  borderRadius: "20px",
                                  color: "#005c87",
                                  float: "inherit",
                                  padding: "14px 15px",
                                  paddingTop:
                                    comment.length == "" ? "20px" : "10px",
                                  paddingBottom:
                                    comment.length == "" ? "5px" : "10px",
                                  placeholderColor: "#649bb3",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                marginLeft: "15px",
                                position: "relative",
                              }}
                            >
                              {this.state.displayEmoji ? (
                                <SocialFeedsEmojiPicker
                                  selectEmoji={this.onEmojiSelect}
                                  emojiRef={this.state.emojiRef}
                                  hidePicker={this.hideEmojiPicker}
                                />
                              ) : null}
                              <IconContainer
                                cameraIcon
                                style={{
                                  marginLeft: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "4px",
                                }}
                              >
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={this.popover(
                                    this.props.t("Emoji")
                                  )}
                                >
                                  <img
                                    style={{
                                      marginLeft: "15px",
                                      cursor: "pointer",
                                      width: "46px",
                                      height: "46px",
                                    }}
                                    onClick={this.showEmojiPicker}
                                    src={
                                      ImageUrl +
                                      "/images/new_emoji_icon.png"
                                    }
                                  ></img>
                                </OverlayTrigger>
                                <InputImage
                                  htmlFor="uploadPhoto"
                                  noMarginTop
                                  style={{
                                    background: "none",
                                    padding: "0px",
                                  }}
                                >
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={this.popover(
                                      this.props.t("Attach photo")
                                    )}
                                  >
                                    <img
                                      src={
                                        ImageUrl +
                                        "/images/new_camera_icon.png"
                                      }
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                        width: "46px",
                                        height: "46px",
                                      }}
                                    />
                                  </OverlayTrigger>
                                  <input
                                    id="uploadPhoto"
                                    type="file"
                                    name={t("image")}
                                    accept=".jpeg, .png, .jpg*"
                                    multiple={false}
                                    onChange={(e) => this.onChangeImage(e)}
                                    onClick={(e) =>
                                      e.target.files[0] &&
                                      this.onChangeImage(e)
                                    }
                                  />
                                </InputImage>
                              </IconContainer>
                            </div>
                          </CommentInnerContainer>
                        </CommentContent>
                        {comment || imgSrc.length >= 1 ? (
                          <PostButton
                            style={{ background: "#005c87" }}
                            color="#159fc9"
                            onClick={(e) =>
                              this.postComment(e, eventDetail.id)
                            }
                          >
                            {t("Post")}{" "}
                            <img src="/public/images/SendPost.svg" />
                          </PostButton>
                        ) : null}
                      </FieldContainer>
                    )}
                    {isLoading ? (
                      <Loading isHorizontal />
                    ) : _.isEmpty(comments) ? (
                      <NoComments isEventChatComment={true}>
                        {t("No comments to display")}
                      </NoComments>
                    ) : (
                      <EventsComments
                        comments={comments}
                        fixHeight
                        replySuccess={replySuccess}
                        postReply={postReplyOnComment}
                        id={eventDetail.id}
                        isChallengeOrEvent={true}
                      />
                    )}
                  </CommentContentContainer>
                </FormContainer>
              </CommentsContainerNew>
            ) : null}
          </div>
          <div className="rightContainer" style={{minWidth:"400px", marginLeft:"20px"}}>
            <ContainerSecond width="100%">
              {eventDetail.event_status === "notover" ? (
                <div>
                  {eventDetail.is_attended &&
                  eventDetail.event_status === "over" ? (
                      <EventCompletePoint
                        backgroundColor={"white"}
                        cursor={"1"}
                        color="#005c87"
                        style={{ marginBottom: "25px" }}
                      >
                        <div className="wrapper" style={{ color: "#005c87" }}>
                          <div className="text">You earned </div>&nbsp;
                          <div className="point" style={{ color: "#76AB78" }}>
                            +{eventDetail.event_points} Social Points{" "}
                          </div>
                          &nbsp; for
                        </div>
                        <div className="wrapper" style={{ color: "#005c87" }}>
                          participating!
                        </div>
                      </EventCompletePoint>
                    ) : null}
                  {eventDetail.is_attended ? (
                    <EventsButton
                      color={"#005c87"}
                      cursor={"1"}
                      shadow="none"
                      style={{
                        marginBottom: "25px",
                        border: "1px solid #005c87",
                      }}
                    >
                      {t("You Attended")}
                    </EventsButton>
                  ) : (
                    <EventsButton
                      color="#f4aaa9"
                      cursor={"1"}
                      style={{ marginBottom: "25px" }}
                      shadow="none"
                    >
                      {t("You did not attend")}
                    </EventsButton>
                  )}
                  {/* <EventsButton
											backgroundColor={"rgba(156, 156, 156, 0.1)"}
											color={"rgb(253, 113, 117)"}
											cursor={"1"}
											shadow="none"
										>
											{t("This Event Has Ended")}
										</EventsButton> */}
                  <EventsButtonWrapper>
                    {((role === "ADMIN" &&
                        eventDetail.event_status === "over") ||
                        (eventDetail.is_global_event == "0" &&
                          addOrRemoveUserPermission &&
                          eventDetail.event_status === "over")) && (
                      <EventsButton
                        width={"1"}
                        color={"#007AB1"}
                        style={{
                          border: "1px solid #007AB1",
                          background: addAttendees && "#007AB1",
                          color: addAttendees && "white",
                        }}
                        onClick={() =>
                          this.showAttendeesModal("addAttendees")
                        }
                        onMouseEnter={() =>
                          this.setState({ addAttendees: true })
                        }
                        onMouseLeave={() =>
                          this.setState({ addAttendees: false })
                        }
                      >
                        <span style={{ marginRight: "12px" }}>
                          {
                            <NewPlusIcon
                              fill={addAttendees ? "white" : "#007AB1"}
                            />
                          }
                        </span>{" "}
                        {t("Add Attendees")}
                      </EventsButton>
                    )}
                    {((role === "ADMIN" &&
                        eventDetail.event_status === "over") ||
                        (eventDetail.is_global_event == "0" &&
                          addOrRemoveUserPermission &&
                          eventDetail.event_status === "over")) && (
                      <EventsButton
                        width={"1"}
                        color={"#007AB1"}
                        style={{border:"1px solid #007AB1", background:editAttendees && "#007AB1", color:editAttendees && "white"}}
                        onClick={() =>
                          this.showAttendeesModal("removeAttendees")
                        }
                        onMouseEnter={() => this.setState({ editAttendees: true })}
                        onMouseLeave={() => this.setState({ editAttendees: false })}
                      >
                        <span style={{marginRight:"12px"}}>{<NewPencilIcon fill={editAttendees ? "white" : "#007AB1"}/>}</span>{t("Edit Attendees")}
                      </EventsButton>
                    )}
                  </EventsButtonWrapper>
 
                  <AboutEventDiv style={{background:"white",borderRadius:"6px",padding:"12px"}}>
                    <div className="aboutTitle" style={{marginTop:"0px",marginBottom:"16px"}}>
                      About the Event
                    </div>
                    <div className="aboutDescription" style={{width:"unset", fontFamily:"Rubik-Regular",lineHeight:"24px"}}>
                      {parse(eventDetail.body)}
                    </div>
                  </AboutEventDiv>
                </div>
              ) : (
                <div>
                  <div className="topLabelDiv">
                    <div className="label blueLabel" style={{minWidth:"160px"}}>
                      {eventDetail.is_lunch_and_learn_event === 1 ? this.props.t("Lunch and Learns")  : eventDetail.is_global_event=="1"?this.props.t("Woliba Live"):this.props.t("Company Live")}
                    </div>
                    { (eventDetail?.event_status != "over" && moment(convertDateInTimezone(eventDetail.start_date)._d) > new Date()) && (<div className="label blueLabel" style={{minWidth:"260px"}}>
                      <Timer data={moment(convertDateInTimezone(eventDetail.start_date)._d)}/>
                    </div>)}
                    {eventDetail?.is_attended && eventDetail?.event_status === "over"?<div className="label blueLabel">
                      <img src={ImageUrl + "/images/emoji/happy-emoji.svg"}/>&nbsp;You attended
                    </div>:
                      eventDetail?.event_status === "over"&&<div className="label redLabel">
                        <img src={ImageUrl + "/images/emoji/sad-emoji.svg"}/>You did not attend 
                      </div>}
                  </div>
                  {
                    selectedTab === 1 && (
                      <EventWrapperNewUI  isRow={true} >
                        {eventDetail.event_status != "over" && (<div className="buttonContainer" style={{display:"flex", borderTop:"1px solid rgba(0,92,135,0.10)",padding:"25px 0px"}}>
                          <ReservedButton active={!eventDetail?.is_attending} onClick={isButtonDisabled?null:(event) =>this.showCalendarAndReserve(eventDetail?.id,eventDetail?.is_attending,eventDetail?.title,event)} style={{marginRight:"12px",borderRadius:"4px"}}>
                            <div className="icon">
                              {!eventDetail?.is_attending?<img src={ImageUrl + "/event-section/right.png"}/>:
                                <img src={ImageUrl + "/event-section/cross.png"}/>}
                            </div>
                            {!eventDetail?.is_attending?t("Reserve Spot"):t("Cancel Reservation")}
                          </ReservedButton>
                          {!eventDetail?.is_attending && eventDetail?.add_to_calendar === 0 ? 
                            <ReservedButton
                              onClick={(event) =>
                                this.hideCalendarAndReserve(event)
                              }
                              active={!eventDetail?.is_attending}
                              style={{opacity:"50%", cursor:"not-allowed", borderRadius:"4px"}}
                            >
                              {<div className="icon"><img src={ImageUrl + "/event-section/calendarWhite.svg"}/></div>}
                              {t("Add to Calendar")}
                            </ReservedButton>:
                            eventDetail?.is_attending === 1 && eventDetail?.add_to_calendar === 0 ?
                              <ReservedButton active={1}  onClick={(event) =>this.showCalendar(eventDetail?.id,eventDetail?.title,event)} style={{borderRadius:"4px"}}>
                                {<div className="icon"><img src={ImageUrl + "/event-section/calendarWhite.svg"}/></div>}
                                {t("Add to Calendar")}
                              </ReservedButton>:
                              <ReservedButton active={1} onClick={(event) =>this.hideCalendarAndReserve(event)} style={{borderRadius:"4px", background:"rgba(0, 122, 177, 0.10)",color:"rgba(0, 122, 177, 0.30)"}}>
                                {<div className="icon"><RightIcon fillOpacity={0.3} fill={"#007AB1"}/></div>}
                                {t("Add to Calendar")}
                              </ReservedButton> 
                          }
                        </div>)}
                        <EventRightSectionRowMainDiv style={{ borderTop:"1px solid rgba(0,92,135,0.10)",paddingTop:"25px"}}>
                          <div className="imageDiv">
                            {AdminEventsCalendarIcon()}
                          </div>
                          <div className="details">
                            <div>
                              { (lang !== 'fr') ? (convertMonthFromDate(moment(convertDateInTimezone(eventDetail && eventDetail.start_date)._d).format("ddd, MMM DD, YYYY"), this.props)) : convertMonthFromDate(moment(convertDateInTimezone(eventDetail && eventDetail.start_date)._d).format("ddd, DD MMM YYYY"), this.props)}
                              &nbsp; | &nbsp;
                              {moment(
                                convertDateInTimezone(eventDetail.start_date)._d
                              ).format("hh:mm a")} 
                            </div>
                            
                            {!eventDetail.is_all_day_event ? (<div>
                              { (lang !== 'fr') ? (convertMonthFromDate(moment(convertDateInTimezone(eventDetail && eventDetail.end_date)._d).format("ddd, MMM DD, YYYY"), this.props)) : convertMonthFromDate(moment(convertDateInTimezone(eventDetail && eventDetail.end_date)._d).format("ddd, DD MMM YYYY"), this.props)}
                              &nbsp; | &nbsp;
                              {moment(
                                convertDateInTimezone(eventDetail.end_date)._d
                              ).format("hh:mm a")}{" "}
                              ({eventDetail.abbreviation})
                            </div>):null}
                          </div>
                        </EventRightSectionRowMainDiv>
 
                        <EventRightSectionRowMainDiv>
                          <div className="imageDiv">
                            {AdminEventsTimeIcon()}
                          </div>
                          <div className="details">
                            <div>
                              {eventDetail.is_all_day_event ? "1 Day" : timeConvertInHrsMins(Math.floor((moment(eventDetail.end_date)).diff(moment(eventDetail.start_date), 'minutes')))}
                            </div>
                          </div>
                        </EventRightSectionRowMainDiv>
 
 
                        {eventDetail.event_location ? (
                          <EventRightSectionRowMainDiv>
                            <div className="imageDiv">
                              {AdminEventsLocationIcon()}
                            </div>
                            <div className="details">
                              <div>{t("Onsite Event")} | {t(eventDetail.event_location)}</div>
                            </div>
                          </EventRightSectionRowMainDiv>) 
                          : 
                          ( 
                            <EventRightSectionRowMainDiv>
                              <div className="imageDiv">
                                {AdminEventsLocationIcon()}
                              </div>
                              <div className="details">
                                <div>{t("Virtual Event")}</div>
                              </div>
                            </EventRightSectionRowMainDiv>
                          ) 
                        }
 
                        {eventDetail?.event_points>0?<EventRightSectionRowMainDiv>
                          <div className="imageDiv">
                            {(AdminEventsRewardsIcon())}
                          </div>
                          <div className="details">
                            <div> +{eventDetail.event_points} {t("pts")}</div>
                          </div>
                        </EventRightSectionRowMainDiv>:null}
 
                        {attendees?.length > 0?<EventRightSectionRowMainDiv>
                          <div className="imageDiv">
                            {(AdminEventsRegistersUsersIcon())}
                          </div>
                          <div className="details">
                            <div style={{display:'flex', alignItems:"center"}}>
                              {attendees?.length > 0 ? attendees?.length + " Registered Users": "None"}
                              {attendees?.length > 0 && role!=="USER" ?<span
                                className="eye"
                                onClick={() => {this.showAttendeesModal("attendees")}} >
                                {AdminEventsEyeIcon()}
                              </span>:null}
                            </div>
                          </div>
                        </EventRightSectionRowMainDiv>:null}
 
                        {eventDetail.is_lunch_and_learn_event && !eventDetail.event_location ? null : (<EventRightSectionRowMainDiv>
                          <div className="imageDiv">
                            {(AdminEventsAttendsIcon())}
                          </div>
                          <div className="details">
                            <div className="attendesSectionMainDiv">
                              <div>
                                {eventDetail && eventDetail?.attended_count && eventDetail.attended_count > 0 ? eventDetail.attended_count + " Attendees": "None"} 
                              </div>
                              <div className="attendeeDiv">
                                {((role === "ADMIN" && eventDetail.event_status === "over") || (eventDetail.is_global_event=="0" && addOrRemoveUserPermission && eventDetail.event_status === "over")) && 
                                <span onClick={ () => {this.showAttendeesModal("removeAttendees")}} >{AdminEventsPencilIcon()}</span>}
                                {((role === "ADMIN" && eventDetail.event_status === "over") || (eventDetail.is_global_event=="0" && addOrRemoveUserPermission && eventDetail.event_status === "over")) && (
                                  <span onClick={ () => {this.showAttendeesModal("addAttendees")}}>{AdminEventsAddAttendes()}</span>)}
                              </div>
                            </div>
                          </div>
                        </EventRightSectionRowMainDiv>)}
          
                        {eventDetail?.event_flyer?<EventRightSectionRowMainDiv>
                          <div className="imageDiv">
                            {AdminEventsEventFlyers()}
                          </div>
                          <div className="details eventFlyer">
                            <span>Event Flyer</span>
                            {eventDetail.event_flyer ? (
                              <span
                                className="iconButton"
                                onClick={() => {
                                  this.downloadFLyer(eventDetail.event_flyer);
                                }}
                              >
                                {DownloadFileIcon()}
                              </span>
                            ) : (
                              <span>Not Available</span>
                            )}
                          </div>
                        </EventRightSectionRowMainDiv>:null}
                      </EventWrapperNewUI>
                    )}
                </div>
              )}
              {showCalendar && (
                <AddInCalendar
                  show={showCalendar}
                  closeModal={this.showCalendarData}
                  event={eventDetail}
                  width={"100%"}
                  margin={"0%"}
                  hideCalendar={this.hideCalendar}
                />
              )}
              {this.confirmationPopup()}
              {showInvitePopup && (
                <InviteAmigoPopUp
                  history={this.props.history}
                  showModal={showInvitePopup}
                  onHide={this.showHideInvitePopup}
                  eventId={eventDetail.id}
                  plusAmigoButton={1}
                  userId={this.props.userId}
                />
              )}
            </ContainerSecond>
          </div>
        </div>
        {eventDetail?.coach_id && (<div style={{ marginTop: "40px", marginBottom: "80px", display:"flex",justifyContent:"center" }}>
          <CoachProfile coachDetail={eventDetail?.coach_details} history={this.props.history}/>
        </div>)}
      </>
    );
  }
}

EventDetails.propTypes = {
  history: PropTypes.object.isRequired,
  fetchEventDetailsById: PropTypes.func.isRequired,
  eventDetail: PropTypes.object,
  fetchCommentsOnEvent: PropTypes.func.isRequired,
  comments: PropTypes.array,
  isLoading: PropTypes.bool,
  postCommentOnEvent: PropTypes.func.isRequired,
  postReplyOnComment: PropTypes.func.isRequired,
  replySuccess: PropTypes.string,
  profileImage: PropTypes.string,
  attendEvent: PropTypes.func.isRequired,
  getEventAttendees: PropTypes.func.isRequired,
  attendees: PropTypes.array,
  attending: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
  userId: PropTypes.number,
  location: PropTypes.object,
  SearchAttendeesForEvent: PropTypes.func,
  role: PropTypes.string,
  addOrRemoveUserPermission: PropTypes.bool,
  printEventCsvPermission: PropTypes.bool,
  EventDetailsById: PropTypes.func.isRequired,
  t: PropTypes.func,
  networkError: PropTypes.string,
  updateUserGlobalPoints: PropTypes.func,
  attendLunchAndLearnEvent: PropTypes.func,
  isAdminEvent: PropTypes.bool,
  attendEventLimitUser: PropTypes.func
};

const mapStateToProps = (state) => ({
  eventDetail: state.events.eventDetail,
  comments: state.events.comments,
  attendees: state.events.attendees,
  attending: state.events.attending,
  isLoading: state.events.isLoading,
  userId: state.profileData.userId,
});

const mapDispatchToProps = (dispatch) => ({
  SearchAttendeesForEvent: (data) => dispatch(SearchAttendeesForEvent(data)),
  EventDetailsById: (id) => dispatch(getEventDetailsById(id)),
  updateUserGlobalPoints: (actionId, activityType, callBackForUpdaingApiDetails) => dispatch(updateUserGlobalPoints(actionId, activityType, callBackForUpdaingApiDetails)),
  attendLunchAndLearnEvent: (eventId, isAttending,isAttended) => dispatch(attendLunchAndLearnEvent(eventId, isAttending, isAttended)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EventDetails))
);
